import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../providers/data/app-config.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { Part } from '../parts/part.model';
import { by } from 'protractor';

@Injectable()
export class PartsService
{

    searchText: string;
    parts: Part[];
    tags: any[];
    typeEmployee: string = '';
    typeTramInArray: any[] = [];
    routeParams: any;
    stateParams: string = '';
    baseUrl: string = '/parts';
    newRequest = false;
    requestInProcess = false;

    onTodosChanged: BehaviorSubject<any>;
    onSelectedTodosChanged: BehaviorSubject<any>;
    onCurrentTodoChanged: BehaviorSubject<any>;
    onFiltersChanged: BehaviorSubject<any>;
    onTagsChanged: BehaviorSubject<any>;
    onSearchTextChanged: BehaviorSubject<any>;
    onNewTodoClicked: Subject<any>;
    onRutaChanged: BehaviorSubject<any>;
    urlApi: string;
    currentUser: any;
    ruta: string = null;
    isSalaSuperior:boolean=false;


    private partes = new Subject();
    private expedientQuery = new Subject();


    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {Location} _location
     */
    constructor(
        private _httpClient: HttpClient,
        private _location: Location,
        private settings: AppConfigService
    )
    {  


        this.urlApi = this.settings.getUrlApi();


     }

       
     
     add(part:Part){

        const url =`${this.urlApi}parts`;
        const httpOptions = this.settings.getHeadersJson();
        const body=JSON.stringify(part.toNewRequestData());
        return this._httpClient.post(url,body,httpOptions);
        
    }

             
    get(id){

        const url =`${this.urlApi}parts/${id}`;
        const httpOptions = this.settings.getHeadersJson();
        return this._httpClient.get(url, httpOptions);
        
    }
    getByExp(expediente){
        const url =`${this.urlApi}partes-expediente?expediente=${expediente}`;
        const httpOptions = this.settings.getHeadersJson();
        return this._httpClient.get(url, httpOptions);
    }

    getByPromotionId(promotion_id){
        const url =`${this.urlApi}partes-promotion?promotion_id=${promotion_id}`;
        const httpOptions = this.settings.getHeadersJson();
        return this._httpClient.get(url, httpOptions);
    }

    delete(part:Part){

        const url =`${this.urlApi}parts/${part.persona.id}`;
        const httpOptions = this.settings.getHeadersJson();
        return this._httpClient.delete(url, httpOptions);
        
    }



    getAll(expediente_id){

        const url =`${this.urlApi}parts`;
        const httpOptions = this.settings.getHeadersJson();
        return this._httpClient.get(url, httpOptions);
        
    }



    update(part:Part){

        const url =`${this.urlApi}parts/${part.persona.id}`;
        const httpOptions = this.settings.getHeadersJson();
        const body=JSON.stringify(part.toUpdateRequestData());
        return this._httpClient.patch(url,body,httpOptions);
        
    }



    setExpedientQuery(expedient:string) {
        // it is used to publish data
         this.expedientQuery.next(expedient);
    }

    
    getExpedientQuery() {
        // it is used to publish data
        return this.expedientQuery.asObservable();
         
    }

    setParts(parts:Part[]) {
        // it is used to publish data
         this.partes.next(parts);
    }

    
    getParts() {
        // it is used to publish data
        return this.partes.asObservable();
         
    }


    
}

