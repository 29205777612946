<h1 matDialogTitle *ngIf="type !='promociones'">Tipo de notificación</h1>
<h1 matDialogTitle *ngIf="type =='promociones'">Tipo de promocion</h1>
<div  fxLayout="column" fxLayoutAlign="space-between center" >
    <!--<div mat-dialog-content>{{confirmMessage}}</div>--->
    <!--notificaciones enlinea-->
    <div mat-dialog-actions class="pt-24" *ngIf=" type == null">
        <button mat-raised-button class="mat-accent mr-16 mt-24" (click)="dialogRef.close('electronicaenlinea')">Electrónica en juicio en línea</button>
    </div>
    <div mat-dialog-actions class="pt-24" *ngIf=" type === null">
        <button mat-raised-button  class="mat-accent mr-16" (click)="dialogRef.close('boletinenlinea')">Boletín electrónico</button>
    </div>
    <!--notificaciones tradicionales-->
    <div mat-dialog-actions class="pt-24" *ngIf=" type === 'fisico'">
        <button mat-raised-button  class="mat-accent mr-16" (click)="dialogRef.close('electronicatradicional')">Electrónica en juicio tradicional</button>
    </div>
    <div mat-dialog-actions class="pt-24" *ngIf=" type === 'fisico'">
        <button mat-raised-button  class="mat-accent mr-16" (click)="dialogRef.close('fisicatradicional')">Física</button>
    </div>
    <div mat-dialog-actions class="pt-24" *ngIf=" type === 'fisico'">
        <button mat-raised-button  class="mat-accent mr-16" (click)="dialogRef.close('boletintradicional')">Boletín electrónico</button>
    </div>
    <div mat-dialog-actions class="pt-24" *ngIf=" type === 'promociones'">
        <button mat-raised-button  class="mat-accent mr-16" (click)="dialogRef.close('lesi')">Juicio de Nulidad / Lesividad</button>
    </div>
    <div mat-dialog-actions class="pt-24" *ngIf=" type === 'promociones'">
        <button mat-raised-button  class="mat-accent mr-16" (click)="dialogRef.close('p2')" style="font-size: 11px;">Juicio de Responsabilidad Administrativa</button>
    </div>
</div>

