export const TypeDoc = [
	
    {
        id    : 11,
        handle:'I', //valor en backend
        title: 'I. A las autoridades siempre por oficio...', //valor muestra en select
        name : 'I', //title muesta en lista promociones
    },
    {
        id    : 12,
        handle:'III',
        title: 'III. A los particulares personalmente...',
        name : 'III',
    }
]
export const TypeDoc2 = [
	
    {
        id    : 1,
        handle:'I', //valor en backend
        title: 'I. El auto de radicación que admita, prevenga...', //valor muestra en select
        name : 'I', //title muesta en lista promociones
    },
    {
        id    : 2,
        handle:'II',
        title: 'II.	El acuerdo de radicación que admita, prevenga...',
        name : 'I',
    },
    {
        id    : 3,
        handle:'III',
        title: 'III. Los acuerdos en que se aperciba a las partes...',
        name : 'III',
    },
    {
        id    : 4,
        handle:'IV',
        title: 'IV.	La resolución definitiva o interlocutoria',
        name : 'IV',
    }
]
export const typeinci = [
	{
        id    : 1,
        handle: 'a',
        name: 'a) Las que admitan o desechen una demanda;',
        title : 'a) Las que admitan o desechen una demanda;'
	},
	{
        id    : 2,
        handle: 'b',
        name: 'b) Las que concedan o nieguen la suspensión;',
        title : 'b) Las que concedan o nieguen la suspensión;'
	},
	{
        id    : 3,
        handle: 'c',
        name: 'c) Las que admitan o desechen la ampliación de la demanda;',
        title : 'c) Las que admitan o desechen la ampliación de la demanda;'
	},
	{
        id    : 4,
        handle: 'd',
        name: 'd) Las que tengan por contestada o no la demanda;',
        title : 'd) Las que tengan por contestada o no la demanda;'
	},
	{
        id    : 5,
        handle: 'e',
        name: 'e) Las que manden citar al tercero perjudicado;',
        title : 'e) Las que manden citar al tercero perjudicado;'
	},
	{
        id    : 6,
        handle: 'f',
        name: 'f) Las que manden citar a un Tercero Perjudicado ajeno al juicio;',
        title : 'f) Las que manden citar a un Tercero Perjudicado ajeno al juicio;'
	},
	{
        id    : 7,
        handle: 'g',
        name: 'g) Los requerimientos de un acto a la parte que deba cumplirlo;',
        title : 'g) Los requerimientos de un acto a la parte que deba cumplirlo;'
	},
	{
        id    : 8,
        handle: 'h',
        name: 'h) Las resoluciones interlocutorias;',
        title : 'h) Las resoluciones interlocutorias;'
	},
	{
        id    : 9,
        handle: 'i',
        name: 'i) Las que señalen fecha para la audiencia;',
        title : 'i) Las que señalen fecha para la audiencia;'
	},
	{
        id    : 10,
        handle: 'j',
        name: 'j) Las que decreten el sobreseimiento del juicio;',
        title : 'j) Las que decreten el sobreseimiento del juicio;'
	},
	{
        id    : 11,
        handle: 'k',
        name: 'k) Las sentencias definitivas;',
        title : 'k) Las sentencias definitivas;'
	},
	{
        id    : 12,
        handle: 'l',
        name: 'l) En cualquier caso urgente o importante si así lo considera el Tribunal.',
        title : 'l) En cualquier caso urgente o importante si así lo considera el Tribunal.'
	}
]

export const TypeFile = [
    { name: 'copia simple', uid:'simple'},
    { name: 'copia certificada', uid:'certificada'},
    { name: 'original con firma', uid:'firmada'},
    { name: 'original sin firma', uid:'original'},
]

export const TipoPromovente = [
    {  nume:'1', type:'promocion revision', name:'Actor'},
    {  nume:'2', type:'promocion revision', name:'Autoridad Demandada'},
    {  nume:'3', type:'promocion revision', name:'Tercero Perjudicado'},
    {  nume:'4', type:'promocion', name:'Recurrente'},
    {  nume:'5', type:'promocion revision', name:'Otro'},
    {  nume:'6', type:'especializada', name:'Presunto responsable'},
    {  nume:'7', type:'especializada', name:'Investigadora'},
    {  nume:'8', type:'especializada', name:'Substanciadora'},
    {  nume:'9', type:'especializada', name:'Resolutora'},
    {  nume:'10', type:'especializada', name:'Terceros'},
    {  nume:'11', type:'especializada', name:'Otra'},
]

export const TipoProcedimiento = [
    { nume: 1, name:'Juicio ordinario'},
    { nume: 2, name:'Juicio sumario'},
    { nume: 8, name:'Procedimientos de responsabilidades por falta administrativa grave'},
    { nume: 9, name:'Procedimiento de cumplimiento de sentencia'},
    { nume: 10, name:'Conflicto de competencia'},
    { nume: 11, name:'Excitativa de justicia'},
    { nume: 12, name:'Excusa'},
];

export const Responsabilidad = [
    {  nume:'0', name:'Responsabilidad Administrativa'},
    {  nume:'0', name:'Recurso de Inconformidad'},
    {  nume:'0', name:'Recurso de Reclamación'},
    {  nume:'0', name:'Recurso de Apelación'},
]

export const OrganoJurisdiccional = [
    { nume:1, type_doc:'inicial promocion emitio revision ambiente', depa:'Primera Sala Regional (Toluca)'},
    { nume:2, type_doc:'inicial promocion emitio revision', depa:'Segunda Sala Regional (Naucalpan de Juárez)'},
    { nume:3, type_doc:'inicial promocion emitio revision', depa:'Tercera Sala Regional (Tlalnepantla de Baz)'},
    { nume:4, type_doc:'inicial promocion emitio revision', depa:'Cuarta Sala Regional (Ecatepec de Morelos)'},
    { nume:5, type_doc:'inicial promocion emitio', depa:'Quinta Sala Regional (Nezahualcóyotl)'},
    { nume:6, type_doc:'inicial promocion emitio', depa:'Sexta Sala Regional (Atizapan de Zaragoza)'},
    { nume:7, type_doc:'promocion emitio', depa:'Séptima Sala Regional (Toluca)'},
    { nume:8, type_doc:'inicial promocion emitio especializada', depa:'Octava Sala Especializada en materia de Responsabilidades Administrativas'},
    { nume:9, type_doc:'promocion emitio especializada', depa:'Novena Sala Especializada en materia de Responsabilidades Administrativas'},
    { nume:10, type_doc:'promocion', depa:'Primera Sección de la Sala Superior'},
    { nume:11, type_doc:'promocion', depa:'Segunda Sección de la Sala Superior'},
    { nume:12, type_doc:'promocion', depa:'Tercera Sección de la Sala Superior'},
    { nume:13, type_doc:'promocion', depa:'Cuarta Sección de la Sala Superior'},
    { nume:14, type_doc:'', depa:'Departamento de Recursos Financieros'},
    { nume:15, type_doc:'', depa:'Departamento de Recursos Humanos'},
    { nume:16, type_doc:'', depa:'Departamento de Recursos Materiales y Servicios Generales'},
    { nume:17, type_doc:'', depa:'Dirección de Administración'},
    { nume:18, type_doc:'', depa:'Junta de Gobierno y Administración'},
    { nume:19, type_doc:'', depa:'Magistratura Jurisdiccional Consultiva'},
    { nume:20, type_doc:'', depa:'Magistratura Supernumeraria Toluca' },
    { nume:21, type_doc:'', depa:'Magistratura Supernumeraria Valle De México' },
    { nume:23, type_doc:'', depa:'Órgano Interno de Control'},
    { nume:24, type_doc:'', depa:'Presidencia'},
    { nume:25, type_doc:'', depa:'Secretaría General del Pleno'},
    { nume:26, type_doc:'', depa:'Secretaría Particular de Presidencia'},
    { nume:27, type_doc:'', depa:'Secretaría Técnica de la Junta de Gobierno y Administración'},
    { nume:28, type_doc:'', depa:'Unidad de Asesoría Comisionada' },
    { nume:29, type_doc:'', depa:'Unidad de documentación, difusión e información'},
    { nume:30, type_doc:'', depa:'Unidad de Estudios y Proyectos'},
    { nume:31, type_doc:'', depa:'Unidad de informática'},
    { nume:32, type_doc:'', depa:'Unidad de Información. Planeación. programación y Evaluación'},
    { nume:33, type_doc:'', depa:'Vicepresidencia'},
    { nume:34, type_doc:'', depa:'Instituto de Justicia Administrativa'},
    { nume:35, type_doc:'', depa:'Archivo Central'},
]

export const OrganoJurisdiccionalCorrespondencia = [
    { nume:1, type_doc:'inicial promocion emitio revision ambiente', depa:'Primera Sala Regional (Toluca)'},
    { nume:2, type_doc:'inicial promocion emitio revision', depa:'Segunda Sala Regional (Naucalpan de Juárez)'},
    { nume:3, type_doc:'inicial promocion emitio revision', depa:'Tercera Sala Regional (Tlalnepantla de Baz)'},
    { nume:4, type_doc:'inicial promocion emitio revision', depa:'Cuarta Sala Regional (Ecatepec de Morelos)'},
    { nume:5, type_doc:'inicial promocion emitio', depa:'Quinta Sala Regional (Nezahualcóyotl)'},
    { nume:6, type_doc:'inicial promocion emitio', depa:'Sexta Sala Regional (Atizapan de Zaragoza)'},
    { nume:7, type_doc:'promocion emitio', depa:'Séptima Sala Regional (Toluca)'},
    { nume:8, type_doc:'inicial promocion emitio especializada', depa:'Octava Sala Especializada en materia de Responsabilidades Administrativas'},
    { nume:9, type_doc:'promocion emitio especializada', depa:'Novena Sala Especializada en materia de Responsabilidades Administrativas'},
    { nume:10, type_doc:'promocion', depa:'Primera Sección de la Sala Superior'},
    { nume:11, type_doc:'promocion', depa:'Segunda Sección de la Sala Superior'},
    { nume:12, type_doc:'promocion', depa:'Tercera Sección de la Sala Superior'},
    { nume:13, type_doc:'promocion', depa:'Cuarta Sección de la Sala Superior'},
    { nume:19, type_doc:'', depa:'Magistratura Jurisdiccional Consultiva'},
    { nume:20, type_doc:'', depa:'Magistratura Supernumeraria Toluca' },
    { nume:21, type_doc:'', depa:'Magistratura Supernumeraria Valle De México' },
    { nume:14, type_doc:'', depa:'Departamento de Recursos Financieros'},
    { nume:15, type_doc:'', depa:'Departamento de Recursos Humanos'},
    { nume:16, type_doc:'', depa:'Departamento de Recursos Materiales y Servicios Generales'},
    { nume:17, type_doc:'', depa:'Dirección de Administración'},
    { nume:18, type_doc:'', depa:'Junta de Gobierno y Administración'},
    { nume:23, type_doc:'', depa:'Órgano Interno de Control'},
    { nume:24, type_doc:'', depa:'Presidencia'},
    { nume:25, type_doc:'', depa:'Secretaría General del Pleno'},
    { nume:26, type_doc:'', depa:'Secretaría Particular de Presidencia'},
    { nume:27, type_doc:'', depa:'Secretaría Técnica de la Junta de Gobierno y Administración'},
    { nume:28, type_doc:'', depa:'Unidad de Asesoría Comisionada' },
    { nume:29, type_doc:'', depa:'Unidad de documentación, difusión e información'},
    { nume:30, type_doc:'', depa:'Unidad de Estudios y Proyectos'},
    { nume:31, type_doc:'', depa:'Unidad de informática'},
    { nume:32, type_doc:'', depa:'Unidad de Información. Planeación. programación y Evaluación'},
    { nume:33, type_doc:'', depa:'Vicepresidencia'},
    { nume:34, type_doc:'', depa:'Instituto de Justicia Administrativa'},
    { nume:35, type_doc:'', depa:'Archivo Central'},
]


export const TipoRecurso = [
    { nume: 10, name:'Conflicto de competencia'},
    { nume: 13, name:'Cumplimiento de ejecutoria de amparo'},
    { nume: 11, name:'Excitativa de justicia'},
    { nume: 12, name:'Excusa'},
    { nume: 1, name:'Juicio ordinario'},
    { nume: 2, name:'Juicio sumario'},
    { nume: 9, name:'Procedimiento de cumplimiento de sentencia'},
    { nume: 8, name:'Procedimiento de responsabilidades administrativas'},
    { nume: 7, name:'Recurso de apelación'},
    { nume: 6, name:'Recurso de inconformidad'},
    { nume: 5, name:'Recurso de reclamación'},
    { nume: 4, name:'Recurso de revocación'},
    { nume: 3, name:'Recurso de revisión'},
 
  

];

export class FileAnexo {
    name:string;
    file: any;
    error: boolean;
    upload: boolean;
    size: string;
    type: string;
    desc: string;
    txtBtnUp: string;
    numPages: string;
    txtError: string;
    sign: boolean;
    possign: any;
    constructor(name: string){
        { 
            this.name = name; 
            this.file = undefined;
            this.error = false; 
            this.txtError = '';
            this.upload = false;
            this.size = '';
            this.type = 'no';
            this.desc = ''; 
            this.txtBtnUp = 'Agregar archivo';
            this.numPages = '0';
            this.sign = false;
            this.possign = null;
        }
    }
} 


export const TipoJuicios = [
    { nume: 1,  name: 'Juicio Administrativo'},
    { nume: 2,  name: 'Juicio Fiscal'},
    { nume: 3,  name: 'Juicio Sumario'},
    { nume: 4,  name: 'Recurso de Revisión'},
    { nume: 5,  name: 'Cumplimiento de Sentencia en Sala Superior'},
    { nume: 6,  name: 'Cuadernillo de Amparo en Sala Superior'},
    { nume: 7,  name: 'Excitativas de Justicia'},
    { nume: 8,  name: 'Conflicto de Competencia entre Salas Regionales'},
    { nume: 9,  name: 'Procedimiento de Responsabilidad Administrativa'},
    { nume: 10, name:  'Recurso de Reclamación'},
    { nume: 11, name:  'Recurso de Apelación'},
    { nume: 12, name:  'Procedimiento de responsabilidades administrativas'},
    { nume: 13, name:  'Recurso de inconformidad'},
    { nume: 14, name:  'Incidentes'},
    { nume: 15, name:  'Investigación'},
    { nume: 16, name: 'Expediente de varios'},
    { nume: 17, name: 'Cuaderno auxiliar'}
];

export const ListadoAcuerdos = [
    {nume: 46,  name:'Otro'},
    {nume: 1,  name:'Admisión'},
    {nume: 2,  name:'Desechamiento'},
    {nume: 3,  name:'Ampliación'},
    {nume: 4,  name:'Sentencia'},
    {nume: 5,  name:'Contestación de Demanda'},
    {nume: 6,  name:'Audiencia'},
    {nume: 7,  name:'Nulidad de Notificación'},
    {nume: 8,  name:'Acuerdo de recepción de expediente'},
    {nume: 9,  name:'Acuerdo de reclasificación'},
    {nume: 10, name: 'Acuerdo de devolución'},
    {nume: 11, name: 'Acuerdo de envío'},
    {nume: 12, name: 'Cierre de instrucción'},
    {nume: 13, name: 'Audiencia diferida'},
    {nume: 14, name: 'Contestación a la ampliación'},
    {nume: 15, name: 'Ampliación de demanda'},
    {nume: 16, name: 'Manifestaciones'},
    {nume: 17, name: 'Exhibe expediente'},
    {nume: 18, name: 'Ofrecimiento de pruebas'},
    {nume: 19, name: 'Aclaración sentencia'},
    {nume: 20, name: 'Desahogo'},
    {nume: 21, name: 'Reposición '},
    {nume: 22, name: 'Copias'},
    {nume: 23, name: 'Alegatos'},
    {nume: 24, name: 'Cumplimiento'},
    {nume: 25, name: 'Conciliación'},
    {nume: 26, name: 'Ejecutoria'},
    {nume: 27, name: 'Archivo'},
    {nume: 28, name: 'Incompetencia'},
    {nume: 29, name: 'Túrnese a proyectos'},
    {nume: 30, name: 'Acuse de recibo'},
    {nume: 31, name: 'Admisión de pruebas'},
    {nume: 32, name: 'Requerimiento'},
    {nume: 33, name: 'Desahogo de requerimiento'},
    {nume: 34, name: 'Presentación de alegatos'},
    {nume: 35, name: 'Remisión a sección'},
    {nume: 36, name: 'Acuerdo donde señala domicilio el presunto, la investigadora o substanciadora'},
    {nume: 37, name: 'Regularización'},
    {nume: 38, name: 'Reserva'},
    {nume: 39, name: 'Admisión de queja'},
    {nume: 40, name: 'Recepción de documentos'},
    {nume: 41, name: 'Acumulación'},
    {nume: 42, name: 'Conclusión'},
    {nume: 43, name: 'Remisión'},
    {nume: 44, name: 'Sala a la que se remite'},
    {nume: 45, name: 'Exhorto'}
];

export const TipoParte = [
    { nume: 1, name: 'Actor'},
    { nume: 2, name: 'Demandado'},
    { nume: 3, name: 'Tercer interesado'},
    { nume: 4, name: 'Recurrente'},
    { nume: 5, name: 'Autoridad vinculada'},
    { nume: 6, name: 'Delegado fiscal'},
    { nume: 7, name: 'Juzgado de distrito'},
    { nume: 8, name: 'Entidad exhortante'},
    { nume: 9, name: 'Autoridad investigadora'},
    { nume: 10, name: 'Autoridad substanciadora'},
    { nume: 11, name: 'Presunto responsable'},
    { nume: 12, name: 'Denunciante'},
    { nume: 13, name: 'Notifíquese'},
    { nume: 14, name: 'Actor - Recurrente'},
    { nume: 15, name: 'Actor - Tercer interesado'},
    { nume: 16, name: 'Actor - Autoridad vinculada'},
    { nume: 17, name: 'Actor - Delegado fiscal'},
    { nume: 18, name: 'Actor - Juzgado de distrito'},
    { nume: 19, name: 'Actor - Entidad exhortante'},
    { nume: 20, name: 'Actor - Autoridad investigadora'},
    { nume: 21, name: 'Actor - Autoridad substanciadora'},
    { nume: 22, name: 'Actor - Presunto responsable'},
    { nume: 23, name: 'Actor - Denunciante'},
    { nume: 24, name: 'Actor - Notifíquese'},
    { nume: 25, name: 'Actor - Demandado'},
    { nume: 26, name: 'Actor - Demandado - Recurrente'},
    { nume: 27, name: 'Actor - Demandado - Tercer interesado'},
    { nume: 28, name: 'Actor - Demandado - Autoridad vinculada'},
    { nume: 29, name: 'Actor - Demandado - Delegado fiscal'},
    { nume: 30, name: 'Actor - Demandado - Juzgado de distrito'},
    { nume: 31, name: 'Actor - Demandado - Entidad exhortante'},
    { nume: 32, name: 'Actor - Demandado - Autoridad investigadora'},
    { nume: 33, name: 'Actor - Demandado - Autoridad substanciadora'},
    { nume: 34, name: 'Actor - Demandado - Presunto responsable'},
    { nume: 35, name: 'Actor - Demandado - Denunciante'},
    { nume: 36, name: 'Actor - Demandado - Notifíquese'},
    { nume: 37, name: 'Autoridad investigadora - Autoridad substanciadora - Presunto responsable - Denunciante'},
    { nume: 38, name: 'Autoridad investigadora - Autoridad substanciadora - Presunto responsable'},
    { nume: 39, name: 'Autoridad investigadora - Autoridad substanciadora'},
    { nume: 40, name: 'Cúmplase'},
    { nume: 41, name: 'Tribunal Colegiado de Circuito'},
    { nume: 42, name: 'Órgano Superior de Fiscalización'},
    { nume: 43, name: 'Interesados'},
    { nume: 44, name: 'Tribunal de Conciliación y Arbitraje'},
    { nume: 45, name: 'Tribunal Federal de Justicia Administrativa'},
    { nume: 46, name: 'Tribunal Electoral del Estado de México'},
];

export const ListadoAcuerdosSalas = [
    {nume: 1, name: 'Primera Sala Regional Toluca'},
    {nume: 2, name: 'Segunda Sala Regional Naucalpan de Juárez'},
    {nume: 3, name: 'Tercera Sala Regional Tlalnepantla de Baz'},
    {nume: 4, name: 'Cuarta Sala Regional Ecatepec de Morelos'},
    {nume: 5, name: 'Quinta Sala Regional Nezahualcóyotl'},
    {nume: 6, name: 'Sexta Sala Regional Atizapán de Zaragoza'},
    {nume: 7, name: 'Séptima Sala Regional Toluca'},
    {nume: 8, name: 'Octava Sala Regional Especializada en Materia de Responsabilidades Administrativas Valle de Toluca'},
    {nume: 9, name: 'Novena Sala Regional Especializada en Materia de Responsabilidades Administrativas Valle de México'},
    {nume: 10,name:  'Primera Sección de la Sala Superior Toluca'},
    {nume: 11,name:  'Segunda Sección de la Sala Superior Tlalnepantla de Baz'},
    {nume: 12,name:  'Tercera Sección de la Sala Superior Ecatepec de Morelos'},
    {nume: 13,name:  'Cuarta Sección de la Sala Superior en Materia de Responsabilidades Administrativas Toluca'},
];

export const TipoSentido = [
    {nume: 1,  name:'Incidente de tacha de testigos'},
    {nume: 2,  name:'Resolución de tacha de testigos'},
    {nume: 3,  name:'Apertura de periodos de alegatos '},
    {nume: 4,  name:'Turno de sentencia'},
    {nume: 5,  name:'Admisión y/o presentación'},
    {nume: 6,  name:'Desahogo de vista'},
    {nume: 7,  name:'Certificación secretarial'},
    {nume: 10,  name:'Se recibe expediente'},
    {nume: 11,  name:'Acuerdo de regularización'},
    {nume: 12,  name:'Autorización de copias'},
    {nume: 13,  name:'Túrnese a proyecto'},
    {nume: 14,  name:'Acuerdo de arreglos conciliatorios'},
    {nume: 15,  name:'No se tiene por cumplida la sentencia y se requiere'},
    {nume: 16,  name:'Admisión de prueba superveniente'},
    {nume: 17,  name:'Sentencia'},
    {nume: 18,  name:'Cause ejecutoria'},
    {nume: 19,  name:'Archivo'},
    {nume: 20,  name:'Vistas actor'},
    {nume: 21,  name:'Vistas particular'},
    {nume: 22,  name:'Desahogo requerimiento autoridad'},
    {nume: 23,  name:'Desahogo requerimiento actor'},
    {nume: 24,  name:'Desahogo requerimiento tercero'},
    {nume: 25,  name:'Desahogo de expediente formado'},
    {nume: 27,  name:'Difiere audiencia'},
    {nume: 28,  name:'Se nombra autorizados'},
    {nume: 29,  name:'Se autoriza la expedición de copias simples'},
    {nume: 30,  name:'Se autoriza la expedición de copias certificadas'},
    {nume: 31,  name:'Vincula autoridad cumplimiento'},
    {nume: 32,  name:'Llamar nueva autoridad demandada'},
    {nume: 34,  name:'Cuantificación liquidación'},
    {nume: 35,  name:'Sentencia no cumplida'},
    {nume: 36,  name:'No ha lugar a acordar de conformidad por no ser parte'},
    {nume: 38,  name:'Requerimiento al actor'},
    {nume: 39,  name:'Requerimiento a la autoridad '},
    {nume: 40,  name:'Agréguese a los autos para los efectos legales a que haya lugar'},
    {nume: 41,  name:'Vías de cumplimiento'},
    {nume: 42,  name:'Se concede prórroga cumplimiento'},
    {nume: 43,  name:'Se concede prórroga  para desahogo requerimiento'},
    {nume: 44,  name:'Requerimiento no cumplido'},
    {nume: 45,  name:'Búsqueda exhaustiva'},
    {nume: 46,  name:'Estése a lo acordado '},
    {nume: 48,  name:'Autoridades confesas'},
    {nume: 49,  name:'Autoridad se allana'},
    {nume: 50,  name:'Requerimiento de Sala Superior'},
    {nume: 51,  name:'Tercero Perjudicado se apersona'},
    {nume: 52,  name:'Juzgado de distrito solicita informe previo'},
    {nume: 53,  name:'Juzgado de distrito solicita informe justificado'},
    {nume: 54,  name:'Juzgado de distrito concede suspensión'},
    {nume: 55,  name:'Juzgado de distrito sin efectos suspensión'},
    {nume: 56,  name:'Juzgado de distrito notifica sentencia'},
    {nume: 57,  name:'Juzgado de distrito tiene por cumplida sentencia'},
    {nume: 58,  name:'Juzgado de distrito tiene por no cumplida sentencia'},
    {nume: 59,  name:'Requiere y sanciona con multa autoridad'},
    {nume: 60,  name:'Solicitud de datos personales para imposición de sanción autoridad'},
    {nume: 61,  name:'Remisión de datos para ejecutar sanción a autoridad'},
    {nume: 62,  name:'Cumplimiento parcial de sentencia y requiere cumplimiento'},
    {nume: 63,  name:'Remite juicio de diversa autoridad'},
    {nume: 64,  name:'Se admite el recurso interpuesto'},
    {nume: 65,  name:'Se desecha el recurso interpuesto'},
    {nume: 66,  name:'Se apercibe para señalar domicilio dentro de la sede del órgano jurisdiccional'},
    {nume: 67,  name:'Se hace efectivo el apercibimiento decretado de autos'},
    {nume: 68,  name:'Se tiene por señalado el domicilio para oír y recibir notificaciones y por autorizados a los profesionistas'},
    {nume: 69,  name:'Se ordena dar vista y se señala plazo para desahogo'},
    {nume: 70,  name:'Se tiene por desahogada la vista y por hechas las manifestaciones'},
    {nume: 71,  name:'Se tiene por autorizados a los profesionistas'},
    {nume: 72,  name:'No ha lugar a acordar de conformidad lo solicitado'},
    {nume: 74,  name:'Se turna a ponencia y se designa magistrado ponente para la formulación del proyecto de resolución'},
    {nume: 75,  name:'Se ordena devolver los autos a la Sala Regional de origen'},
    {nume: 76,  name:'Se previene'},
    {nume: 78,  name:'Se apercibe'},
    {nume: 79,  name:'Se declara preclusión'},
    {nume: 80,  name:'Se tiene por presentado y se ordena glosar a los autos'},
    {nume: 81,  name:'Se remite acuse de recibo correspondiente'},
    {nume: 83,  name:'Se ordena girar oficio'},
    {nume: 84,  name:'Se recibe expedientes de Sala Superior'},
    {nume: 85,  name:'Se presenta demanda de Amparo Directo'},
    {nume: 86,  name:'Se remite expediente al Tribunal Colegiado'},
    {nume: 87,  name:'No ha lugar de admitir prueba superveniente'},
    {nume: 88,  name:'Se recibe expediente de Tribunal Colegiado'},
    {nume: 89,  name:'Validez'},
    {nume: 90,  name:'Invalidez'},
    {nume: 91,  name:'Sobresee'},
    {nume: 92,  name:'Confirma sentencia'},
    {nume: 93,  name:'Modifica sentencia'},
    {nume: 94,  name:'Revoca sentencia'},
    {nume: 95,  name:'Se concede suspensión en auxilio de autoridad de amparo'},
    {nume: 96,  name:'Otro'},
    {nume: 97,  name:'Desechamiento'},
    {nume: 98,  name:'Audiencia'},
    {nume: 99,  name:'Reclasificación'},
    {nume: 100, name: 'Devolución'},
    {nume: 101, name: 'Envío'},
    {nume: 102, name: 'Cierre de instrucción'},
    {nume: 103, name: 'Incompetencia'},
    {nume: 104, name: 'Admisión de pruebas'},
    {nume: 105, name: 'Presentación de alegatos'},
    {nume: 106, name: 'Señala domicilio el presunto'},
    {nume: 107, name: 'Reserva'},
    {nume: 108, name: 'Acumulación'},
    {nume: 109, name: 'Remisión'},
    {nume: 110, name: 'Prevención'},
    {nume: 111, name: 'Suspensión'},
    {nume: 112, name: 'Desahogo de requerimientos con motivo de amparo'}
];