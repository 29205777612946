import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { Error404Module } from 'app/main/pages/errors/404/error-404.module';

import { MenuService } from "./providers/data/menu/menu.service";
import { LoginService } from "./providers/login.provider";
import { UsersProvider } from './providers/users.provider';
import { SampleComponent } from './main/sample/sample.component';
import { AuthGuard } from './providers/guards/auth-guard.service';
import { InitScreenComponent } from './main/init-screen/init-screen.component';
import { InitScreenModule } from './main/init-screen/init-screen.module';
import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';
import { AppConfigService } from './providers/data/app-config.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { type } from 'os';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import{ MAT_DATE_FORMATS} from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeMx from '@angular/common/locales/es-MX';

registerLocaleData(localeMx);


export const MY_DATE_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
};
const appRoutes: Routes = [   

    // Not found
    { path: '**', redirectTo: 'pages/error-404' },
    {
        path     : '',
        component: InitScreenComponent
    },
    {
        path     : 'app',
        loadChildren: () => import('./main/sample/sample.module').then(m => m.SampleModule)
    },
    {
        path        : 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path        : 'users',
        loadChildren: () => import('./main/users/users.module').then(m => m.UsersModule)
    },
    {
        path        : 'notifications',
        loadChildren: () => import('./main/notifications/notifications.module').then(m => m.NotificationsModule),
        runGuardsAndResolvers: 'always',
    },
    {
        path        : 'management', 
        loadChildren: () => import('./main/expedient/expedient.module').then(m => m.ExpedientModule)
    },
    {
        path        : 'administrator', 
        loadChildren: () => import('./main/adviser-citizen-list/adviser-citizen.module').then(m => m.AdviserCitizenModule)
    },
    {
        path        : 'promotion',
        loadChildren: () => import('./main/promotions/promotions.module').then(m => m.PromotionsModule),
        runGuardsAndResolvers: 'always',
    },
    {
        path        : 'agreements',
        loadChildren: () => import('./main/agreements/agreements.module').then(m => m.AgreementsModule)
    },
    {
        path        : 'sentences',
        loadChildren: () => import('./main/sentences/todo.module').then(m => m.SentencesModule)
    },
    {
        path        : 'sentences/proyect',
        loadChildren: () => import('./main/sentences-proyect/todo.module').then(m => m.SentencesProyectModule)
    },
    {
        path        : 'pleno',
        loadChildren: () => import('./main/pleno/todo.module').then(m => m.PlenoModule)
    },
    {
        path        : 'amparos',
        loadChildren: () => import('./main/amparos/todo.module').then(m => m.AmparosModule)
    },
    {
        path        : 'statistics',
        loadChildren: () => import('./main/project/project.module').then(m => m.ProjectDashboardModule)
        //        loadChildren: () => import('./main/analytics/analytics.module').then(m => m.AnalyticsDashboardModule)

    },
    {
        path: 'topics',
        loadChildren: () => import ('./main/topics/topics.module').then(m => m.TopicsModule)
    },
    {
        path        : 'electronic-expedient',
        loadChildren: () => import('./main/expediente-electronico/electronic-expedient.module').then(m => m.ElectronicExpedientModule)
    },
    {
        path        : 'electronic-expedient-request',
        loadChildren: () => import('./main/expediente-electronico-request/electronic-expedient-request.module').then(m => m.ExpedientRequestModule)
    },
    {
        path: 'estadistic',
        loadChildren: () => import('./main/estadistic/visor.module').then(m=>m.EstadisticModule)
    },
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        GuidedTourModule,

        // App modules
        LayoutModule,
        SampleModule,
        InitScreenModule,
        Error404Module,
        MatDialogModule,
        MatMenuModule,
        MatStepperModule,
        MatAutocompleteModule,
        PdfJsViewerModule 
    ],
    bootstrap   : [
        AppComponent
    ],
    providers :[ GuidedTourService, MatStepperModule ,  { provide: MAT_DATE_LOCALE, useValue: 'es-MX'}, { provide: LOCALE_ID, useValue: "es-MX" },] 
})
export class AppModule
{
    constructor(private _router:Router, 
                private _settings: AppConfigService,
                private _route: ActivatedRoute,
                private _menuService: MenuService,
                private _userService: UsersProvider,
                private _loginservice: LoginService) {

        const tokenSession = sessionStorage.getItem('tk');
        if (tokenSession !== null && tokenSession !== undefined) {
            console.log('to home')
            const typeUser = this._settings.getNameTypeUser();
            const currentDepa = this._settings.getDepaEmployee();
            const isSalaSuperior=currentDepa == '1';
          
            console.log(typeUser,this._settings);
            this._menuService.setMenu(typeUser,isSalaSuperior);
           // const info = this.auth.getDecodedAccessToken(tokenSession).data;
           /* this._loginservice.getUser().subscribe(
                (responseUser) => {
                    const typeUser = responseUser['data'].tipo;
                    if(typeUser === 'empleado'){
                        this._userService.getEmployeeData(responseUser['data'].empe).subscribe(
                            (response) => {
                                const typeEmployee = response['data'].trabdata.data.pues;
                                sessionStorage.setItem('em', JSON.stringify(response['data'].depadata.data));
                                sessionStorage.setItem('tyem', typeEmployee);
                                if( typeEmployee === 'Actuario'){
                                    this._menuService.setMenu(typeEmployee);
                                }else if( typeEmployee === 'Secretario de acuerdos sala regional'){
                                    this._menuService.setMenu(typeEmployee);
                                }else {
                                    this._router.navigate(['/pages/auth/login']);
                                }
                            },
                            (error) => console.error(error)
                        );
                    }else {
                        this._menuService.setMenu(typeUser);
                    }
                },
                (error) => {
                    console.error(error.error.error)
                    this._router.navigate(['/pages/auth/login']);
                }
            );*/
        }else {
            //cath path url login
            if(this._router.url === '/' || this._router.url === '/login') {
                //this._router.navigate(['/inicio']);
            }else {
               // this._router.navigate(['/pages/auth/register']);
            }
        }

    }   
}
