interface AgreementFormOptions{
    AditionalOptions:any[],
    TipoSentido:any[],
    AcuerdoSentidoOptions:any[],
    SubsentidoOptions:any[],
}

export class SecretarioAmparoOptions implements AgreementFormOptions{

    AditionalOptionsId={
        RECURSO:0,
        INCIDENTE:0,
        AMPARO_INDIRECTO:17,
        AMPARO_DIRECTO:19,
        ACUERDOS_FRECUENTES:0,
    }

AditionalOptions=[
    
    //Recurso Options
    { nume: 1,  acuerdo_id: 10, type:"nulidad", name: "Revisión" },
    { nume: 2,  acuerdo_id: 10, type:"nulidad grave", name: "Reclamación" },
    { nume: 3,  acuerdo_id: 10, type:"nulidad grave", name: "Queja" },
    { nume: 4,  acuerdo_id: 10, type:"grave", name: "Apelación" },
    { nume: 5,  acuerdo_id: 10, type:"grave", name: "Reclamación (R.Grave)" },
    { nume: 6,  acuerdo_id: 10, type:"nulidad grave", name: "Inconformidad" },
      //Incidente Options
    { nume: 7,  acuerdo_id: 11,type:"nulidad grave",  name:"Acumulación de autos"},
    { nume: 8,  acuerdo_id: 11,type:"nulidad grave",  name:"Nulidad de notificaciones"},
    { nume: 9,  acuerdo_id: 11,type:"nulidad grave",  name:"Interrupción del procedimiento por muerte o por disolución en el caso de las personas morales"},
    { nume: 10, acuerdo_id: 11,type:"nulidad grave",  name:"Incompetencia por razón de territorio"},
    { nume: 11, acuerdo_id: 11,type:"nulidad grave",  name:"Aclaración de sentencia"},
    { nume: 12, acuerdo_id: 11,type:"nulidad grave",  name:"Liquidación"},
    { nume: 13, acuerdo_id: 11,type:"nulidad grave",  name:"Cumplimiento sustituto"},
    { nume: 14, acuerdo_id: 11,type:"nulidad grave",  name:"Falsedad de documento"},
    { nume: 15, acuerdo_id: 11,type:"nulidad grave",  name:"Medidas cautelares"},
    //AMPARO INDIRECTO
    {nume: 16, acuerdo_id: 17,type:"nulidad grave nograbe", name:"Se rinde informe"}, 
    {nume: 17, acuerdo_id: 17,type:"nulidad grave nograbe", name:"Trámite de amparo"},
    {nume: 18, acuerdo_id: 17,type:"nulidad grave nograbe", name:"Sentido de ejecutoria"},
    {nume: 19, acuerdo_id: 17,type:"nulidad grave nograbe", name:"Cumplimiento de ejecutoria"},

    //AMPARO DIRECTO
    {nume: 23, acuerdo_id: 19,type:"nulidad grave nograbe", name:"Recibe demanda"}, 
    {nume: 24, acuerdo_id: 19,type:"nulidad grave nograbe", name:"Remite demanda"},
    {nume: 25, acuerdo_id: 19,type:"nulidad grave nograbe", name:"Se recibe de colegiado"},
    {nume: 26, acuerdo_id: 19,type:"nulidad grave nograbe", name:"Sentido de ejecutoria"},
    //Acuerdos frecuentes 
    {nume: 20, acuerdo_id: 18,type:"nulidad grave", name:"Domicilio procesal y autorizados"}, 
    {nume: 21, acuerdo_id: 18,type:"nulidad grave", name:"Representante común"},
    {nume: 22, acuerdo_id: 18,type:"nulidad grave", name:"Otros"},

   


  ];


TipoSentido = [
    
    {nume: 19, type: 'nulidad grave nograbe', name:'Amparo directo'},
    {nume: 17, type: 'nulidad grave nograbe', name:'Amparo indirecto'},
    {nume: 18, type: 'nulidad grave nograbe', name:'Acuerdos frecuentes'},

];


AcuerdoSentidoOptions=[
//Amparo directoSE TIENE POR RECIBIDA LA DEMANDA DE AMPARO DIRECTO, SE ORDENA EMPLAZAR A LOS TERCEROS INTERESADOS
//Recibe demanda
{nume: 251, option_id:23, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por recibida la demanda de amparo directo, se ordena emplazar a los terceros interesados"},
{nume: 252, option_id:23, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se previene al quejoso para que exhiba copias suficientes para correr traslado a los terceros interesados"},
{nume: 253, option_id:23, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por desahogada la prevención y se ordena correr traslado a los terceros interesados"},
//Remite demanda
{nume: 254, option_id:24, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por no desahogada la prevención y se remite la demanda al Tribunal Colegiado de Circuito"},
{nume: 255, option_id:24, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se remite expediente, la demanda de amparo directo, las constancias de notificación y el informe con justificación al Tribunal Colegiado de Circuito"},
//Se recibe de colegiado
{nume: 256, option_id:25, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se reciben los autos y acuerdo que tiene por no presentada la demanda de amparo directo, se ordena remitir los autos a la sala de origen, se archivan el toca y el expediente auxiliar de amparo"},
{nume: 257, option_id:25, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por recibido el acuerdo que admite la demanda de amparo, se ordena agregar Tercero Interesado auxiliar de amparo"},
//Sentido de ejecutoria
{nume: 258, option_id:26, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria que ampara y protege al quejoso y se turna a ponencia para elaborar proyecto"},
{nume: 259, option_id:26, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria que no ampara y protege al quejoso, se ordena remitir los autos a la Sala Regional, se archivan el toca y el expediente auxiliar de amparo"},
{nume: 260, option_id:26, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria que sobresee el juicio, se ordena remitir los autos a la Sala Regional, se archivan el toca y el expediente auxiliar de amparo"},
{nume: 261, option_id:26, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria que desecha la demanda de amparo, se ordena remitir los autos a la Sala Regional, se archivan el toca y el expediente auxiliar de amparo"},
{nume: 262, option_id:26, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por cumplida la ejecutoria, se ordena remitir los autos a la sala de origen, se archivan el toca y el expediente auxiliar de amparo"},
//Amparo indirecto
//Se rinde informe
{nume: 263, option_id:16, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibido el oficio con la demanda de amparo indirecto y se rinde el informe previo"},
{nume: 264, option_id:16, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibido el oficio con la demanda de amparo indirecto y se rinde el informe justificado"},
//Trámite de amparo"},
{nume: 265, option_id:17, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibido el oficio que difiere la audiencia constitucional"},
{nume: 266, option_id:17, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibido el oficio que concede la suspensión del acto reclamado"},
{nume: 267, option_id:17, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibido el oficio que niega la suspensión del acto reclamado"},
//Sentido de ejecutoria"},
{nume: 268, option_id:18, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria de amparo indirecto que ampara y protege al quejoso"},
{nume: 269, option_id:18, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria de amparo indirecto que no ampara y protege al quejoso"},
{nume: 270, option_id:18, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria de amparo indirecto que sobresee el juicio"},
{nume: 271, option_id:18, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria de amparo indirecto que desecha la demanda de amparo"},
//Cumplimiento de ejecutoria"},
{nume: 272, option_id:19, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibido el requerimiento de cumplimiento de ejecutoria de amparo indirecto y se provee lo conducente"},
{nume: 273, option_id:19, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por cumplida la ejecutoria de amparo indirecto se archiva el expediente auxiliar"},
{nume: 274, option_id:19, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por no cumplida la ejecutoria y se provee lo conducente"},

//ACUERDOS FRECUENTES  
//DOMICILIO PROCESAL Y AUTORIZADOS
/* {nume: 275,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se revoca correo electrónico y se señala nuevo domicilio electrónico y/o autorizados"},*/
{nume: 276,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se tiene por recibida la solicitud de excusa y se hace del conocimiento al Pleno"},
{nume: 277,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se returna el expediente para nuevo proyecto"},
{nume: 278,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"Agréguese a los autos"},
{nume: 279,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"No ha lugar a acordar de conformidad lo solicitado"},
{nume: 280,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"Téngase por hechas sus manifestaciones"},
{nume: 281,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se acordará lo conducente en el momento procesal oportuno"},
{nume: 282,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"Estése a lo acordado"},
{nume: 283,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se previene"},
{nume: 284,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se tiene por desahogada la prevención"},
{nume: 285,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se autoriza la expedición de copias"},
{nume: 286,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"Comparecencia"},
{nume: 287,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se recibe despacho diligenciado"},
{nume: 288,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se recibe despacho no diligenciado y se provee lo conducente"},
{nume: 289,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se recibe expediente de Sala Regional para su resguardo virtual y físico en el Archivo General"},
{nume: 290,  acuerdo_id: 18,type:"nulidad grave nograbe", name:"Otro"}

];

SubsentidoOptions=[];

}