import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitScreenComponent } from './init-screen.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';

const routes = [
  {
      path     : 'initial',
      component: InitScreenComponent,
  }
];

@NgModule({
  declarations: [InitScreenComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FuseSharedModule,
    MatTooltipModule
  ]
})
export class InitScreenModule { }
