<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo">
        <!--img class="logo-icon" src="assets/images/logos/logotri_font_blank.png"-->
        <span class="logo-text secondary-text">Menú Principal</span>
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

        <div class="h1 username"><span class="name_title">e</span>.Justicia</div>
        <div class="h3 email mt-8">Administrativa Guerrero</div>
        <!--div class="h3 username">{{ currentUser.nomb }} {{ currentUser.pate }} {{ currentUser.mate }}</div>
        <div class="h5 email hint-text mt-8">{{ currentUser.mail }}</div-->
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <!--img class="avatar" src="{{currentUser.foto}}"><!--src="assets/images/avatars/Velazquez.jpg"-->
            <img class="logo-icon" src="assets/images/logos/logogro.png">
        </div>

    </div>

    <div class="navbar-content mt-24">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>
