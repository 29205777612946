interface AgreementFormOptions{
  AditionalOptionsId:any,
    AditionalOptions:any[],
    TipoSentido:any[],
    AcuerdoSentidoOptions:any[],
    SubsentidoOptions:any[],
}

export class SecretarioRecursoOptions implements AgreementFormOptions{

   AditionalOptionsId={
    AMPARO_DIRECTO:0,
    RECURSO:10,
    INCIDENTE:11,
    AMPARO_INDIRECTO:17,
    ACUERDOS_FRECUENTES:18,  
}

AditionalOptions=[
    
    //Recurso Options
    { nume: 1,  acuerdo_id: 10, type:"nulidad", name: "Revisión" },
    { nume: 2,  acuerdo_id: 10, type:"nulidad grave", name: "Reclamación" },
    { nume: 3,  acuerdo_id: 10, type:"nulidad grave", name: "Queja" },
    { nume: 4,  acuerdo_id: 10, type:"grave", name: "Apelación" },
    { nume: 5,  acuerdo_id: 10, type:"grave", name: "Reclamación (R.Grave)" },
    { nume: 6,  acuerdo_id: 10, type:"nograbe", name: "Inconformidad" },
      //Incidente Options
    { nume: 7,  acuerdo_id: 11,type:"nulidad grave nograbe",  name:"Acumulación de autos"},
    { nume: 8,  acuerdo_id: 11,type:"nulidad grave nograbe",  name:"Nulidad de notificaciones"},
    { nume: 9,  acuerdo_id: 11,type:"nulidad grave",  name:"Interrupción del procedimiento por muerte o por disolución en el caso de las personas morales"},
    { nume: 10, acuerdo_id: 11,type:"nulidad grave",  name:"Incompetencia por razón de territorio"},
    { nume: 11, acuerdo_id: 11,type:"nulidad grave nograbe",  name:"Aclaración de sentencia"},
    { nume: 12, acuerdo_id: 11,type:"nulidad grave",  name:"Liquidación"},
    { nume: 13, acuerdo_id: 11,type:"nulidad",  name:"Cumplimiento sustituto"},
    { nume: 14, acuerdo_id: 11,type:"nulidad grave nograbe",  name:"Falsedad de documento"},
    { nume: 15, acuerdo_id: 11,type:"grave",  name:"Medidas cautelares"},
    //AMPARO INDIRECTO
    {nume: 16, acuerdo_id: 17,type:"nulidad grave", name:"Se rinde informe"}, 
    {nume: 17, acuerdo_id: 17,type:"nulidad grave", name:"Trámite de amparo"},
    {nume: 18, acuerdo_id: 17,type:"nulidad grave", name:"Sentido de ejecutoria"},
    {nume: 19, acuerdo_id: 17,type:"nulidad grave", name:"Cumplimiento de ejecutoria"},
    //Acuerdos frecuentes 
    {nume: 20, acuerdo_id: 18,type:"nulidad grave nograbe", name:"Domicilio procesal y autorizados"}, 
    {nume: 21, acuerdo_id: 18,type:"nulidad grave nograbe", name:"Representante común"},
    {nume: 22, acuerdo_id: 18,type:"nulidad grave nograbe", name:"Otros"},
  ];


TipoSentido = [
    
    {nume: 10, type: 'nulidad grave nograbe', name:'Recursos'},
    {nume: 11, type: 'nulidad grave nograbe', name:'Incidentes'},
    {nume: 18, type: 'nulidad grave nograbe', name:'Acuerdos frecuentes'},

];


AcuerdoSentidoOptions=[
//REVISIÓN
 {nume: 345, option_id:1, acuerdo_id:10, type:"nulidad", name:"Se admite el recurso de revisión y se turna a ponencia"},
 {nume: 346, option_id:1, acuerdo_id:10, type:"nulidad", name:"Se devuelve a la Sala Regional para su debida integración"},
 {nume: 347, option_id:1, acuerdo_id:10, type:"nulidad", name:"Se requiere a la Sala Regional proveer lo conducente"},
 {nume: 348, option_id:1, acuerdo_id:10, type:"nulidad", name:"Se tiene por interpuesto el recurso extemporáneo y se turna a ponencia"},
 {nume: 349, option_id:1, acuerdo_id:10, type:"nulidad", name:"Se desecha recurso de revisión en el supuesto en el que la autoridad no contestó la demanda"},
//APELACIÓN
{nume: 351, option_id:4, acuerdo_id:10, type:"grave", name:"Se requiere a la Sala Regional proveer lo conducente"},
 {nume: 350, option_id:4, acuerdo_id:10, type:"grave", name:"Se devuelve a la Sala Regional para su debida integración"},
 {nume: 352, option_id:4, acuerdo_id:10, type:"grave", name:"Se califica, se admite el recurso de apelación y se da vista a las partes procesales"},
 {nume: 353, option_id:4, acuerdo_id:10, type:"grave", name:"Se califica, se desecha el recurso de apelación"},
 {nume: 354, option_id:4, acuerdo_id:10, type:"grave", name:"Se desahoga vista y se turna a ponencia"},
 {nume: 355, option_id:4, acuerdo_id:10, type:"grave", name:"No desahoga vista y se turna a ponencia"},
 
//QUEJA
 {nume: 356, option_id:3, acuerdo_id:10, type:"nulidad grave", name:"Se admite recurso de queja y se requiere a la autoridad para que rinda un informe con justificación"},
 {nume: 357, option_id:3, acuerdo_id:10, type:"nulidad grave", name:"Se tiene por rendido el informe con justificación y se turna a ponencia"},
 {nume: 358, option_id:3, acuerdo_id:10, type:"nulidad grave", name:"Se tiene por no rendido el informe con justificación y se turna a ponencia"},
 {nume: 359, option_id:3, acuerdo_id:10, type:"nulidad grave", name:"Se tiene por interpuesto el recurso extemporáneo y se turna a ponencia"},

// RECLAMACIÓN (R.GRAVE)
 {nume: 360, option_id:5, acuerdo_id:10,type:"grave", name:"Se devuelve a la autoridad substanciadora o resolutora para su debida integración"},
 {nume: 361, option_id:5, acuerdo_id:10,type:"grave", name:"Se recepciona el recurso y se turna a ponencia"},
 {nume: 362, option_id:5, acuerdo_id:10,type:"grave", name:"Ha causado ejecutoria la sentencia y se devuelve a la autoridad substanciadora o resolutora"},
//RECLAMACIÓN
 {nume: 363, option_id:2, acuerdo_id:10, type:"nulidad grave", name:"Se tiene por interpuesto el recurso extemporáneo y se turna a ponencia"},
 {nume: 364, option_id:2, acuerdo_id:10, type:"nulidad grave", name:"Se admite el recurso de reclamación y se da vista a las partes"},
 {nume: 365, option_id:2, acuerdo_id:10, type:"nulidad grave", name:"Se tienen por contestados los agravios y se turna a ponencia"},
 {nume: 366, option_id:2, acuerdo_id:10, type:"nulidad grave", name:"Se tiene por no contestados los agravios y se turna a ponencia"},


//INCONFORMIDAD
 {nume: 367, option_id:6, acuerdo_id:10, type:"nograbe", name:"Se admite el recurso de inconformidad y se da vista"},
 {nume: 368, option_id:6, acuerdo_id:10, type:"nograbe", name:"Se requiere respecto del recurso de inconformidad"},
 {nume: 369, option_id:6, acuerdo_id:10, type:"nograbe", name:"Se desahoga requerimiento se admite recurso y se da vista"},
 {nume: 370, option_id:6, acuerdo_id:10, type:"nograbe", name:"No se desahoga el requerimiento y se tiene por no presentado"},
 {nume: 3701, option_id:6, acuerdo_id:10, type:"nograbe", name:"Se desahoga vista y se turna a ponencia"},
 {nume: 3702, option_id:6, acuerdo_id:10, type:"nograbe", name:"No desahoga vista y se turna a ponencia"},

//////INCIDENTES
//ACUMULACIÓN DE AUTOS
{nume: 371, option_id:7, acuerdo_id:11, type:"nulidad grave nograbe", name:"De oficio se ordena la acumulación de tocas y se da vista a las partes"},
{nume: 372, option_id:7, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se admite el incidente de acumulación y se da vista a las partes"},
{nume: 373, option_id:7, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se desahoga la vista y se turna a ponencia"},
{nume: 374, option_id:7, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se tiene por no desahogada la vista y se turna a ponencia"},
{nume: 375, option_id:7, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se desecha incidente por notoriamente improcedente"},
//NULIDAD DE NOTIFICACIONES
{nume: 376, option_id:8, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se admite el incidente de nulidad de notificaciones y se da vista a las partes"},
{nume: 377, option_id:8, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se desahoga la vista y se turna a ponencia"},
{nume: 378, option_id:8, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se tiene por no desahogada la vista y se turna a ponencia"},
{nume: 379, option_id:8, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se desecha incidente por notoriamente improcedente"},
//INTERRUPCIÓN DEL PROCEDIMIENTO POR MUERTE O POR DISOLUCIÓN EN EL CASO DE LAS PERSONAS MORALES
{nume: 380, option_id:9, acuerdo_id:11, type:"nulidad grave", name:"De oficio se ordena la interrupción del procedimiento por muerte o por disolución de personas morales y se da vista a las partes"},
{nume: 381, option_id:9, acuerdo_id:11, type:"nulidad grave", name:"Se admite el incidente de interrupción del procedimiento por muerte o por disolución de personas morales y se da vista a las partes"},
{nume: 382, option_id:9, acuerdo_id:11, type:"nulidad grave", name:"Se desahoga la vista y se turna a ponencia"},
{nume: 383, option_id:9, acuerdo_id:11, type:"nulidad grave", name:"Se tiene por no se desahoga la vista y se turna a ponencia"},
{nume: 3840, option_id:9, acuerdo_id:11, type:"nulidad grave", name:"Se desecha incidente por notoriamente improcedente"},
//INCOMPETENCIA POR RAZÓN DE TERRITORIO
{nume: 384, option_id:10, acuerdo_id:11, type:"nulidad grave", name:"Se admite el incidente de incompetencia y se turna a ponencia"},
{nume: 385, option_id:10, acuerdo_id:11, type:"nulidad grave", name:"Ha causado ejecutoria la sentencia y se remite expediente a la sala competente"},
{nume: 386, option_id:10, acuerdo_id:11, type:"nulidad grave", name:"Se desecha incidente por notoriamente improcedente"},
//ACLARACIÓN DE SENTENCIA
{nume: 387, option_id:11, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se admite el incidente de aclaración de sentencia y se turna a ponencia"},
{nume: 388, option_id:11, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se desecha incidente por notoriamente improcedente"},
//LIQUIDACIÓN
{nume: 389, option_id:12, acuerdo_id:11, type:"nulidad grave", name:"De oficio se inicia el trámite del incidente de liquidación y se da vista a las partes"},
{nume: 390, option_id:12, acuerdo_id:11, type:"nulidad", name:"Se previene"},
{nume: 393, option_id:12, acuerdo_id:11, type:"grave", name:"Se admite el incidente de liquidación y se da vista a las partes"},
{nume: 390, option_id:12, acuerdo_id:11, type:"grave", name:"Se previene"},
{nume: 391, option_id:12, acuerdo_id:11, type:"nulidad grave", name:"Se desahoga la prevención, se admite el incidente y se da vista a las partes"},
{nume: 392, option_id:12, acuerdo_id:11, type:"nulidad grave", name:"No desahoga la vista y se hace efectivo el apercibimiento"},
{nume: 393, option_id:12, acuerdo_id:11, type:"nulidad", name:"Se admite el incidente de liquidación y se da vista a las partes"},
{nume: 394, option_id:12, acuerdo_id:11, type:"nulidad grave", name:"Se desahoga la vista y se turna a ponencia"},
{nume: 395, option_id:12, acuerdo_id:11, type:"nulidad grave", name:"Se tiene por no desahogada la vista y se turna a ponencia"},
{nume: 396, option_id:12, acuerdo_id:11, type:"nulidad grave", name:"Se desecha incidente por notoriamente improcedente"},
/* {nume: 397, option_id:12, acuerdo_id:11, type:"nulidad grave", name:"Liquidación"},
{nume: 398, option_id:12, acuerdo_id:11, type:"nulidad grave", name:"Se previene"}, */
//CUMPLIMIENTO SUSTITUTO
{nume: 399, option_id:13, acuerdo_id:11, type:"nulidad", name:"De oficio se inicia el trámite del incidente de cumplimiento sustituto y se da vista a las partes"},
{nume: 400, option_id:13, acuerdo_id:11, type:"nulidad", name:"Se previene"},
{nume: 401, option_id:13, acuerdo_id:11, type:"nulidad", name:"Se desahoga la prevención se admite el incidente y se da vista a las partes"},
{nume: 402, option_id:13, acuerdo_id:11, type:"nulidad", name:"No desahoga la vista y se hace efectivo el apercibimiento"},
{nume: 403, option_id:13, acuerdo_id:11, type:"nulidad", name:"Se admite el incidente de cumplimiento sustituto y se da vista a las partes"},
{nume: 404, option_id:13, acuerdo_id:11, type:"nulidad", name:"Se desahoga la vista y se turna a ponencia"},
{nume: 405, option_id:13, acuerdo_id:11, type:"nulidad", name:"Se tiene por no desahogada la vista y se turna a ponencia"},
{nume: 406, option_id:13, acuerdo_id:11, type:"nulidad", name:"Se desecha incidente por notoriamente improcedente"},
//FALSEDAD DE DOCUMENTO
{nume: 407, option_id:14, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se previene"},
{nume: 408, option_id:14, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se desahoga la prevención se admite el incidente y se da vista a las partes"},
{nume: 409, option_id:14, acuerdo_id:11, type:"nulidad grave nograbe", name:"No desahoga la vista y se hace efectivo el apercibimiento"},
{nume: 410, option_id:14, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se admite el incidente de falsedad del documento y se da vista a las partes"},
{nume: 411, option_id:14, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se admite el incidente de falsedad del documento, se da vista a las partes y se da trámite a la prueba pericial"},
{nume: 516, option_id:14, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se desahoga la vista y se turna a ponencia"},
{nume: 517, option_id:14, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se tiene por no desahogada la vista y se turna a ponencia"},
{nume: 518, option_id:14, acuerdo_id:11, type:"nulidad grave nograbe", name:"Se desecha incidente por notoriamente improcedente"},
//MEDIDAS CAUTELARES
{nume: 413, option_id:15, acuerdo_id:11, type:"grave", name:"Se admite el incidente de medidas cautelares y se da vista a las partes"},
{nume: 412, option_id:15, acuerdo_id:11, type:"grave", name:"Se admite el incidente de medidas cautelares, se concede u ordena la medida cautelar provisional y se da vista a las partes"},
{nume: 414, option_id:15, acuerdo_id:11, type:"grave", name:"Se previene"},
{nume: 415, option_id:15, acuerdo_id:11, type:"grave", name:"Se desahoga la prevención, se admite el incidente y se da vista a las partes"},
{nume: 416, option_id:15, acuerdo_id:11, type:"grave", name:"Se desahoga la prevención, se admite el incidente, se concede u ordena la medida cautelar provisional y se da vista a las partes"},
{nume: 417, option_id:15, acuerdo_id:11, type:"grave", name:"Se desahoga la vista y se turna a ponencia"},
{nume: 418, option_id:15, acuerdo_id:11, type:"grave", name:"Se tiene por no desahogada la vista y se turna a ponencia"},
{nume: 419, option_id:15, acuerdo_id:11, type:"grave", name:"Se levanta la medida cautelar por no presentar el pliego de presunta responsabilidad administrativa"},
{nume: 420, option_id:15, acuerdo_id:11, type:"grave", name:"Se admite el incidente de suspensión de medidas cautelares y se da vista a las partes"},
{nume: 421, option_id:15, acuerdo_id:11, type:"grave", name:"Se desahoga la vista y se turna a ponencia"},
{nume: 422, option_id:15, acuerdo_id:11, type:"grave", name:"Se tiene por no desahogada la vista y se turna a ponencia"},
{nume: 423, option_id:15, acuerdo_id:11, type:"grave", name:"Se desecha incidente por notoriamente improcedente"},
{nume: 424, option_id:15, acuerdo_id:11, type:"grave", name:"Se solicita a SEFINA el embargo precautorio de los bienes del servidor público o particular presunto responsable de la falta administrativa grave"},
////// FIN INCIDENTES

//ACUERDOS FRECUENTES  
//DOMICILIO PROCESAL Y AUTORIZADOS
{nume: 426, option_id:20, acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se revoca correo electrónico y se señala nuevo domicilio electrónico y/o autorizados"},
{nume: 425, option_id:20, acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se revoca domicilio procesal y se autoriza el nuevo domicilio procesal y/o autorizados"},
{nume: 427, option_id:20, acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se previene"},
{nume: 428, option_id:20, acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se desahoga prevención y se autoriza el nuevo domicilio electrónico y/o autorizados"},
{nume: 429, option_id:20, acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se desahoga prevención y se autoriza el nuevo domicilio procesal y/o autorizados"},
//REPRESENTANTE COMÚN
{nume: 430, option_id:21, acuerdo_id: 18,type:"nulidad grave nograbe", name:"Se revoca representante común y se designa nuevo"},

//ACUERDOS FRECUENTES OTROS
{nume: 431, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Se tiene por recibida la solicitud de excusa y se hace del conocimiento al Pleno"},
{nume: 432, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Se returna el expediente para nuevo proyecto"},
{nume: 433, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Se tiene por realizada la objeción de documentos"},
{nume: 434, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Se desiste del recurso o incidente y se señala fecha para ratificación"},
{nume: 435, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Comparecencia de ratificación de desistimiento y se turna a ponencia"},
{nume: 436, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"No comparece para desistimiento se ordena continuar con el procedimiento"},
{nume: 437, option_id: 22, acuerdo_id: 18, type:"nograbe", name:"Ha causado ejecutoria la sentencia y se devuelve a la autoridad investigadora o substanciadora"},
{nume: 438, option_id: 22, acuerdo_id: 18, type:"nulidad grave", name:"Ha causado ejecutoria la sentencia y se devuelve a la sala de origen para su cumplimiento"},
{nume: 439, option_id: 22, acuerdo_id: 18, type:"nulidad grave", name:"Ha causado ejecutoria la sentencia y se devuelve a la sala de origen para dictar una nueva sentencia"},
{nume: 440, option_id: 22, acuerdo_id: 18, type:"nulidad grave", name:"Ha causado ejecutoria la sentencia y se devuelve a la sala de origen para la reposición del procedimiento"},
{nume: 441, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Agréguese a los autos"},
{nume: 442, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"No ha lugar a acordar de conformidad lo solicitado"},
{nume: 443, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Téngase por hechas sus manifestaciones"},
{nume: 444, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Se acordará lo conducente en el momento procesal oportuno"},
{nume: 445, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Estése a lo acordado"},
{nume: 446, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Se previene"},
{nume: 447, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Se tiene por desahogada la prevención"},
{nume: 448, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Se autoriza la expedición de copias"},
{nume: 449, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Comparecencia"},
{nume: 450, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Se recibe expediente de Sala Regional para su resguardo virtual y físico en el Archivo General"},
{nume: 451, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Se recibe despacho diligenciado"},
{nume: 452, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Se recibe despacho no diligenciado y se provee lo conducente"},
{nume: 453, option_id: 22, acuerdo_id: 18, type:"nulidad grave nograbe", name:"Otro"},
];

SubsentidoOptions=[
//Se admite el incidente de falsedad del documento se da vista a las partes y se da trámite a la prueba pericial 
{nume: 1,  sentido_id: 411,type:"nulidad grave nograbe", name:"Se tiene por designado al perito y se acuerda su preparación"},
{nume: 2,  sentido_id: 411,type:"nulidad grave nograbe", name:"Comparecencia de perito para protesta y aceptación de cargo"},
{nume: 3,  sentido_id: 411,type:"nulidad grave nograbe", name:"Comparecencia de toma de muestras de firma y huellas dactilares"},
{nume: 4,  sentido_id: 411,type:"nulidad grave nograbe", name:"No se tiene por rendido el dictamen y se hace efectivo el apercibimiento"},
{nume: 5,  sentido_id: 411,type:"nulidad grave nograbe", name:"Se tiene por rendido el dictamen"},
{nume: 6,  sentido_id: 411,type:"nulidad grave nograbe", name:"Comparecencia de ratificación de dictamen"},
{nume: 7,  sentido_id: 411,type:"nulidad grave nograbe", name:"Se tiene al perito por ratificado el dictamen"},
{nume: 8,  sentido_id: 411,type:"nulidad grave nograbe", name:"No se tiene al perito por ratificado el dictamen y se declara desierta la prueba"},
{nume: 9,  sentido_id: 411,type:"nulidad grave nograbe", name:"Se sustituye perito"},
{nume: 10, sentido_id: 411,type:"nulidad grave nograbe", name:"Se acepta la excusa del perito y se designa nuevo"},
{nume: 11, sentido_id: 411,type:"nulidad grave nograbe", name:"No se acepta la excusa del perito"},
{nume: 12, sentido_id: 411,type:"nulidad grave nograbe", name:"Se impone multa a perito por incumplimiento"},
{nume: 13, sentido_id: 411,type:"nulidad grave nograbe", name:"Se precluye el derecho para rendir el dictamen"},
{nume: 14, sentido_id: 411,type:"nulidad grave nograbe", name:"Desahoga requerimiento adiciona cuestionario y designa perito"},
{nume: 15, sentido_id: 411,type:"nulidad grave nograbe", name:"Se designa perito tercero en discordia"},
/* {nume: 16, sentido_id: 411,type:"nulidad grave nograbe", name:"Se desahoga la vista y se turna a ponencia"},
{nume: 17, sentido_id: 411,type:"nulidad grave nograbe", name:"Se tiene por no desahogada la vista y se turna a ponencia"},
{nume: 18, sentido_id: 411,type:"nulidad grave nograbe", name:"Se desecha incidente por notoriamente improcedente"}, */
];
}