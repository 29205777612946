<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ txtTitleModal }}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">

        <div class="vertical-stepper-wrapper" fusePerfectScrollbar>
        <mat-vertical-stepper  linear="false" #stepper id="stepper" name="stepper" *ngIf="!isNotificationSala">
            <mat-step [stepControl]="expedientForm">
                <form fxLayout="column" [formGroup]="expedientForm" >
                    <ng-template matStepLabel>EXPEDIENTE</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">
                        <!--FORMULARIO NOTIFICACION ELECTRONICA EN JUICIO EN LINEA-->
                        <!--numero de expediente automatico-->
                        <mat-form-field appearance="outline" fxFlex="50" *ngIf="isAcuerdoNotification || isFisicNotificationAcue">
                            <mat-label>Número de Expediente</mat-label>
                            <input matInput formControlName="acue" (blur)="_getAgreementData($event)" [disabled]="true" required>
                            <mat-error>Este campo es obligatorio</mat-error>
                        </mat-form-field>
                        <mat-error *ngIf="!agreementDataLoaded">No existen registros con el número de expediente proporcionado</mat-error>

                        <mat-form-field appearance="outline" fxFlex="50" *ngIf="!isAcuerdoNotification && !isFisicNotificationAcue">
                            <mat-label>Número de Expediente</mat-label>
                            <input matInput formControlName="une" (blur)="_getUsers($event)"  required>
                            <mat-error *ngIf="expedientForm.get('une').hasError('required')">
                                Este campo es obligatorio</mat-error>
                            <mat-error *ngIf="!expedientForm.get('une').hasError('required') &&
                                            expedientForm.get('une').hasError('pattern')">
                                Debes ingresar un formato de número de expediente correcto y sin espacios,ejemplos: 2012-123 ó 123/2012
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" *ngIf="isAcuerdoNotification && _data.typeNotification === 'electronicaenlinea'">
                            <mat-label>Número único</mat-label>
                            <input matInput required formControlName="expe_uni">
                            <mat-error>
                                El número de expediente unico es obligatorio
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field  appearance="outline" fxFlex="50">
                            <mat-select formControlName="depa" required (selectionChange)="_getUsers($event)">
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let dep of departments" value="{{ dep.nume }}">{{ dep.depa }}</mat-option>
                            </mat-select>
                            <mat-label>
                                Órgano jurisdiccional
                            </mat-label>
                            <mat-error>Debes seleccionar un sala regional o sala superior</mat-error>
                        </mat-form-field>
                        <!--END FORMULARIO NOTIFICACION ELECTRONICA EN JUICIO EN LINEA-->
                        <h3>Partes Procesales</h3>
                        <mat-form-field appearance="outline" fxFlex="50" *ngIf="users.length==0">
                            <mat-label>Actor</mat-label>
                            <input matInput formControlName="part"  required>
                            <mat-error>Debes ingresar un nombre de actor</mat-error>
                        </mat-form-field>
                        <!-- <mat-form-field appearance="outline" fxFlex="50" *ngIf="users.length>0">
                            <mat-label>
                                Actor
                            </mat-label>
                            <mat-select multiple formControlName="part" required>
                                <mat-option *ngFor="let item of users" value="{{item.nomb}}">{{item.nomb}}</mat-option>
                            </mat-select>
                            <mat-error>
                                Selecciona una opción
                            </mat-error>
                        </mat-form-field> -->
                        

                         <mat-form-field appearance="outline" fxFlex="50" *ngIf="users.length>0">
                            <mat-label>Actor</mat-label>
                            <input type="text"
                                   placeholder="Pick one"
                                   aria-label="Actor"
                                   matInput
                                   formControlName="part"
                                   [matAutocomplete]="part">
                            <mat-autocomplete #part="matAutocomplete">
                              <mat-option *ngFor="let option of filteredOptionsActor | async" [value]="option.nomb">
                                {{option.nomb}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" *ngIf="demandado.length==0">
                            <mat-label>Demandada</mat-label>
                            <input matInput formControlName="dema"  required>
                            <mat-error>Debes ingresar un nombre del demandado</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" *ngIf="tercero.length==0">
                            <mat-label>Tercero Perjudicado</mat-label>
                            <input matInput formControlName="terc">
                            <mat-error>Debes ingresar un nombre de Tercero Perjudicado</mat-error>
                        </mat-form-field>

                        <!-- <mat-form-field appearance="outline" fxFlex="50" *ngIf="demandado.length>0">
                            <mat-label>
                                Demandada
                            </mat-label>
                            <mat-select multiple formControlName="dema" required>
                                <mat-option *ngFor="let item of demandado" value="{{item.nomb}}">{{item.nomb}}</mat-option>
                            </mat-select>
                            <mat-error>
                                Selecciona una opción
                            </mat-error>
                        </mat-form-field> -->

                        <mat-form-field appearance="outline" fxFlex="50" *ngIf="demandado.length>0">
                            <mat-label>Demandado</mat-label>
                            <input type="text"
                                   placeholder="Selecciona un demandado"
                                   aria-label="Demandado"
                                   matInput
                                   formControlName="dema"
                                   [matAutocomplete]="dema">
                            <mat-autocomplete #dema="matAutocomplete">
                              <mat-option *ngFor="let option of filteredOptionsDemandado | async" [value]="option.nomb">
                                {{option.nomb}}
                              </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <!-- <mat-form-field appearance="outline" fxFlex="50"  *ngIf="tercero.length>0">
                            <mat-label>
                                Tercero Perjudicado
                            </mat-label>
                            <mat-select multiple formControlName="terc" required>
                                <mat-option *ngFor="let item of tercero" value="{{itemnomb}}">{{item.nomb}}</mat-option>
                            </mat-select>
                            <mat-error>
                                Selecciona una opción
                            </mat-error>
                        </mat-form-field> -->


                        <mat-form-field appearance="outline" fxFlex="50" *ngIf="tercero.length>0">
                            <mat-label>Tercero Perjudicado</mat-label>
                            <input type="text"
                                   placeholder="Selecciona un tercero"
                                   aria-label="Tercero"
                                   matInput
                                   formControlName="terc"
                                   [matAutocomplete]="terc">
                            <mat-autocomplete #terc="matAutocomplete">
                              <mat-option *ngFor="let option of filteredOptionsTercero | async" [value]="option.nomb">
                                {{option.nomb}}
                              </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button matStepperNext type="button" color="accent" [disabled]="!expedientForm.valid">
                            Continuar
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <h3  *ngIf="_data.typeNotification === 'electronicatradicional' || _data.typeNotification === 'electronicaenlinea'">Partes Procesales</h3>
                <mat-tab-group [color]="colorToggle" [backgroundColor]="backgroundColorToggle" *ngIf="_data.typeNotification === 'electronicaenlinea' || _data.typeNotification === 'electronicatradicional'">
                  <mat-tab label="Actor(es)">
                    <div appearance="outline" class="pr-4" fxFlex *ngIf="users.length > 0">
                        <section *ngFor="let user of users;let in=index;">
                            <mat-checkbox [(ngModel)]="user.checked" ngInit="user.checked=true" style="margin-right: 15px;" (change)="setUser(user, $event,in,'actor')" >
                                {{user.nomb}} {{user.pate}} {{user.mate}} <span class="labels-gray" *ngIf="!isAcuerdoNotification">({{user.type}})</span>  
                                <br>
                                                             
                                <span class="labels-gray"> <mat-icon color="accent">email</mat-icon>   ({{user.mail}})</span>
                            </mat-checkbox>
                            <div *ngIf="user.checked"> 
                                <mat-accordion class="example-headers-align">
                                    <mat-expansion-panel color="accent" [expanded]="step === 0"  hideToggle>
                                      <mat-expansion-panel-header style="background: #30bb87;">
                                        <mat-panel-title style="color: white;">
                                          Datos del Actor
                                        </mat-panel-title>
                                        <mat-panel-description style="color: white;">
                                          <mat-icon>account_circle</mat-icon>
                                        </mat-panel-description>
                                      </mat-expansion-panel-header>
    
                                      <form [formGroup]="formGroupactor" fxLayout="column">
                                        <div formArrayName="arr">
                                          <ng-container>
                                            <div [formGroupName]="in" style="margin-bottom: 10px;">
                                              <!-- <label for="name">Name:</label>
                                              <input type="text" name="name" formControlName="name" />
                                              <button type="button" (click)="removeItem(in)">Remove</button> -->
    
    
                                              <div fxFlex="1 0 auto" fxLayout="column">
                                                        
                                                <mat-form-field appearance="outline" fxFlex>
                                                    <mat-label>
                                                        Determinación {{ txtDeterminacion.toLowerCase() }}
                                                    </mat-label>
                                                    <input matInput formControlName="tipo" required>
                                                    <mat-error>Debes seleccionar una determinación {{ txtDeterminacion.toLowerCase() }}</mat-error>
                                                </mat-form-field>
                                                <mat-form-field  appearance="outline" fxFlex="50">
                                                    <mat-label>
                                                       Articulo
                                                    </mat-label>
                                                    <mat-select formControlName="articu" (selectionChange)="onSelectedChangeactor($event.value,in)" required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option value="30">30.-Nulidad/Lesividad</mat-option>
                                                        <mat-option value="31">31.-Responsabilidad Administrativa</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar un Articulo</mat-error>
                                                </mat-form-field>
                                                <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGroupactor.value.arr[in]['articu']=='30'">
                                                    <mat-label>
                                                       Fracción
                                                    </mat-label>
                                                    <mat-select formControlName="fracc" (selectionChange)="onSelectedChangeactor($event.value,in)" required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option *ngFor="let dep of doctypes" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar una fracción</mat-error>
                                                </mat-form-field>
                                                <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGroupactor.value.arr[in]['articu']=='31'">
                                                    <mat-label>
                                                       Fracción
                                                    </mat-label>
                                                    <mat-select formControlName="fracc" required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option *ngFor="let dep of doctypes2" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar una fracción</mat-error>
                                                </mat-form-field>
                                                <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGroupactor.value.arr[in]['fracc']=='III'  && formGroupactor.value.arr[in]['articu']=='30'">
                                                    <mat-label>
                                                       Inciso
                                                    </mat-label>
                                                    <mat-select formControlName="inci" required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option *ngFor="let dep of incisos" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar un Inciso</mat-error>
                                                </mat-form-field>
                        
                                                <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                                                    <mat-label>Fecha en que fue dictado</mat-label>
                                                    <input matInput [matDatepicker]="startDatePicker" formControlName="fecd" (focus)="startDatePicker.open()" required (dateChange)="setMinDateNoti()">
                                                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                                    <mat-datepicker #startDatePicker></mat-datepicker>
                                                    <mat-error>Debes ingresar una fecha</mat-error>
                                                </mat-form-field>
                                                <div *ngIf="(isAcuerdoNotification || isFisicNotificationAcue) && agreementData">
                                                    <a mat-raised-button color="accent" href="{{docu}}" target="_blank">
                                                        Ver documento
                                                    </a>
                                                    
                                                </div>
                                            </div>
                                            </div>
                                          </ng-container>
                                        </div>
                                        <br />
                                        <!-- <button type="button" (click)="addItem(user.nume)">Add</button> -->
                                      </form>
                                <mat-action-row>
                                  </mat-action-row>
                                </mat-expansion-panel>
                              
                                </mat-accordion>
                            </div>
                        </section>
                    </div>
                  </mat-tab>
                    <mat-tab label="Demandado(s)">  
                  <div appearance="outline" class="pr-4" fxFlex *ngIf="demandado.length > 0">
                      <section *ngFor="let user of demandado;let in=index;">
                          <mat-checkbox  [(ngModel)]="user.checked" ngInit="user.checked=true" style="margin-right: 15px;" (change)="setUser(user, $event,in,'demandado')" >
                              {{user.nomb}} {{user.pate}} {{user.mate}} <span class="labels-gray">({{user.tipo}})</span>  
                              <br>
                                                           
                              <span class="labels-gray"> <mat-icon color="accent">email</mat-icon>   ({{user.mail}})</span>
                          </mat-checkbox>
                          <div class="py-24" *ngIf="demandado.length ==null">
                            <mat-hint>
                                No se encontraron demandados suscritos a este expediente, verifique los datos
                            </mat-hint>
                        </div>

                        <div *ngIf="user.checked"> 
                            <mat-accordion class="example-headers-align">
                                <mat-expansion-panel color="accent" [expanded]="step === 0"  hideToggle>
                                  <mat-expansion-panel-header style="background: #30bb87;">
                                    <mat-panel-title style="color: white;">
                                      Datos del Demandado
                                    </mat-panel-title>
                                    <mat-panel-description style="color: white;">
                                      <mat-icon>account_circle</mat-icon>
                                    </mat-panel-description>
                                  </mat-expansion-panel-header>

                                  <form [formGroup]="formGroup" fxLayout="column">
                                    <div formArrayName="arr">
                                      <ng-container>
                                        <div [formGroupName]="in" style="margin-bottom: 10px;">
                                          <!-- <label for="name">Name:</label>
                                          <input type="text" name="name" formControlName="name" />
                                          <button type="button" (click)="removeItem(in)">Remove</button> -->


                                          <div fxFlex="1 0 auto" fxLayout="column">
                                                    
                                            <mat-form-field appearance="outline" fxFlex>
                                                <mat-label>
                                                    Determinación {{ txtDeterminacion.toLowerCase() }}
                                                </mat-label>
                                                <input matInput formControlName="tipo" required>
                                                <mat-error>Debes seleccionar una determinación {{ txtDeterminacion.toLowerCase() }}</mat-error>
                                            </mat-form-field>
                                            <mat-form-field  appearance="outline" fxFlex="50">
                                                <mat-label>
                                                   Articulo
                                                </mat-label>
                                                <mat-select formControlName="articu" (selectionChange)="onSelectedChangedemandado($event.value,in)" required>
                                                    <mat-option>-- Selecciona uno --</mat-option>
                                                    <mat-option value="30">30.-Nulidad/Lesividad</mat-option>
                                                    <mat-option value="31">31.-Responsabilidad Administrativa</mat-option>
                                                </mat-select>
                                                <mat-error>Debes seleccionar un Articulo</mat-error>
                                            </mat-form-field>
                                            <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGroup.value.arr[in]['articu']=='30'">
                                                <mat-label>
                                                   Fracción
                                                </mat-label>
                                                <mat-select formControlName="fracc" (selectionChange)="onSelectedChangedemandado($event.value,in)" required>
                                                    <mat-option>-- Selecciona uno --</mat-option>
                                                    <mat-option *ngFor="let dep of doctypes" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                </mat-select>
                                                <mat-error>Debes seleccionar una fracción</mat-error>
                                            </mat-form-field>
                                            <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGroup.value.arr[in]['articu']=='31'">
                                                <mat-label>
                                                   Fracción
                                                </mat-label>
                                                <mat-select formControlName="fracc" required>
                                                    <mat-option>-- Selecciona uno --</mat-option>
                                                    <mat-option *ngFor="let dep of doctypes2" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                </mat-select>
                                                <mat-error>Debes seleccionar una fracción</mat-error>
                                            </mat-form-field>
                                            <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGroup.value.arr[in]['fracc']=='III' && formGroup.value.arr[in]['articu']=='30'">
                                                <mat-label>
                                                   Inciso
                                                </mat-label>
                                                <mat-select formControlName="inci" required>
                                                    <mat-option>-- Selecciona uno --</mat-option>
                                                    <mat-option *ngFor="let dep of incisos" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                </mat-select>
                                                <mat-error>Debes seleccionar un Inciso</mat-error>
                                            </mat-form-field>
                    
                                            <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                                                <mat-label>Fecha en que fue dictado</mat-label>
                                                <input matInput [matDatepicker]="startDatePicker" formControlName="fecd" (focus)="startDatePicker.open()" required (dateChange)="setMinDateNoti()">
                                                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                                <mat-datepicker #startDatePicker></mat-datepicker>
                                                <mat-error>Debes ingresar una fecha</mat-error>
                                            </mat-form-field>
                                            <div *ngIf="(isAcuerdoNotification || isFisicNotificationAcue) && agreementData">
                                                <a mat-raised-button color="accent" href="{{docu}}" target="_blank">
                                                    Ver documento
                                                </a>
                                                
                                            </div>
                                        </div>
                                        </div>
                                      </ng-container>
                                    </div>
                                    <br />
                                    <!-- <button type="button" (click)="addItem(user.nume)">Add</button> -->
                                  </form>
                            <mat-action-row>
                              </mat-action-row>
                            </mat-expansion-panel>
                          
                            </mat-accordion>
                        </div>
                      </section>
                  </div> 
                  <div appearance="outline" class="pr-4" fxFlex *ngIf="demandado.length == 0">
                    <section>
                        <div class="py-24">
                          <mat-hint>
                              No se encontraron demandados suscritos a este expediente, verifique los datos
                          </mat-hint>
                      </div>
                    </section>
                  </div>
                  </mat-tab>
                  <mat-tab label="Tercero(s)"> 
                      <div appearance="outline" class="pr-4" fxFlex *ngIf="tercero.length > 0">
                          <section *ngFor="let user of tercero;let in=index;" >
                              <mat-checkbox [(ngModel)]="user.checked" ngInit="user.checked=true" style="margin-right: 15px;" (change)="setUser(user, $event,in,'tercero')" >
                                  {{user.nomb}} {{user.pate}} {{user.mate}} <span class="labels-gray" *ngIf="!isAcuerdoNotification">({{user.type}})</span>  
                                  <br>
                                                               
                                  <span class="labels-gray"> <mat-icon color="accent">email</mat-icon>   ({{user.mail}})</span>
                              </mat-checkbox>
                              <div class="py-24" *ngIf="tercero==null">
                                <mat-hint>
                                    No se encontraron terceros suscritos a este expediente, verifique los datos
                                </mat-hint>
                            </div>
                            <div *ngIf="user.checked"> 
                                <mat-accordion class="example-headers-align">
                                    <mat-expansion-panel color="accent" [expanded]="step === 0"  hideToggle>
                                      <mat-expansion-panel-header style="background: #30bb87;">
                                        <mat-panel-title style="color: white;">
                                          Datos del Tercero
                                        </mat-panel-title>
                                        <mat-panel-description style="color: white;">
                                          <mat-icon>account_circle</mat-icon>
                                        </mat-panel-description>
                                      </mat-expansion-panel-header>
    
                                      <form [formGroup]="formGrouptercero" fxLayout="column">
                                        <div formArrayName="arr">
                                          <ng-container>
                                            <div [formGroupName]="in" style="margin-bottom: 10px;">
                                              <!-- <label for="name">Name:</label>
                                              <input type="text" name="name" formControlName="name" />
                                              <button type="button" (click)="removeItem(in)">Remove</button> -->
    
    
                                              <div fxFlex="1 0 auto" fxLayout="column">
                                                        
                                                <mat-form-field appearance="outline" fxFlex>
                                                    <mat-label>
                                                        Determinación {{ txtDeterminacion.toLowerCase() }}
                                                    </mat-label>
                                                    <input matInput formControlName="tipo" required>
                                                    <mat-error>Debes seleccionar una determinación {{ txtDeterminacion.toLowerCase() }}</mat-error>
                                                </mat-form-field>
                                                <mat-form-field  appearance="outline" fxFlex="50">
                                                    <mat-label>
                                                       Articulo
                                                    </mat-label>
                                                    <mat-select formControlName="articu" (selectionChange)="onSelectedChangetercero($event.value,in)" required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option value="30">30.-Nulidad/Lesividad</mat-option>
                                                        <mat-option value="31">31.-Responsabilidad Administrativa</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar un Articulo</mat-error>
                                                </mat-form-field>
                                                <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGrouptercero.value.arr[in]['articu']=='30'">
                                                    <mat-label>
                                                       Fracción
                                                    </mat-label>
                                                    <mat-select formControlName="fracc" (selectionChange)="onSelectedChangetercero($event.value,in)" required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option *ngFor="let dep of doctypes" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar una fracción</mat-error>
                                                </mat-form-field>
                                                <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGrouptercero.value.arr[in]['articu']=='31'">
                                                    <mat-label>
                                                       Fracción
                                                    </mat-label>
                                                    <mat-select formControlName="fracc" required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option *ngFor="let dep of doctypes2" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar una fracción</mat-error>
                                                </mat-form-field>
                                                <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGrouptercero.value.arr[in]['fracc']=='III' && formGrouptercero.value.arr[in]['articu']=='30' ">
                                                    <mat-label>
                                                       Inciso
                                                    </mat-label>
                                                    <mat-select formControlName="inci" required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option *ngFor="let dep of incisos" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar un Inciso</mat-error>
                                                </mat-form-field>
                        
                                                <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                                                    <mat-label>Fecha en que fue dictado</mat-label>
                                                    <input matInput [matDatepicker]="startDatePicker" formControlName="fecd" (focus)="startDatePicker.open()" required (dateChange)="setMinDateNoti()">
                                                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                                    <mat-datepicker #startDatePicker></mat-datepicker>
                                                    <mat-error>Debes ingresar una fecha</mat-error>
                                                </mat-form-field>
                                                <div *ngIf="(isAcuerdoNotification || isFisicNotificationAcue) && agreementData">
                                                    <a mat-raised-button color="accent" href="{{docu}}" target="_blank">
                                                        Ver documento
                                                    </a>
                                                    
                                                </div>
                                            </div>
                                            </div>
                                          </ng-container>
                                        </div>
                                        <br />
                                        <!-- <button type="button" (click)="addItem(user.nume)">Add</button> -->
                                      </form>
                                <mat-action-row>
                                  </mat-action-row>
                                </mat-expansion-panel>
                              
                                </mat-accordion>
                            </div>
                          </section>
                      </div>    
                      <div appearance="outline" class="pr-4" fxFlex *ngIf="tercero.length == 0">
                        <section>
                            <div class="py-24">
                              <mat-hint>
                                  No se encontraron Terceros suscritos a este expediente, verifique los datos
                              </mat-hint>
                          </div>
                        </section>
                      </div>
                  </mat-tab>
                  <mat-tab label="Representante(s) Autorizado(s)"> 
                    <div appearance="outline" class="pr-4" fxFlex *ngIf="ra.length > 0">
                        <section  *ngFor="let user of ra;let in=index;">
                            <mat-checkbox [(ngModel)]="user.checked" ngInit="user.checked=true" style="margin-right: 15px;" (change)="setUser(user, $event,in,'ra')" >
                                {{user.nomb}} {{user.pate}} {{user.mate}} <span class="labels-gray" *ngIf="!isAcuerdoNotification">({{user.type}})</span>  
                                <br>
                                                             
                                <span class="labels-gray"> <mat-icon color="accent">email</mat-icon>   ({{user.mail}})</span>
                            </mat-checkbox>
                            <div class="py-24" *ngIf="ra.length ==null">
                              <mat-hint>
                                  No se encontraron representates autorizados suscritos a este expediente, verifique los datos
                              </mat-hint>
                          </div>
                          <div *ngIf="user.checked"> 
                              <mat-accordion class="example-headers-align">
                                  <mat-expansion-panel color="accent" [expanded]="step === 0"  hideToggle>
                                    <mat-expansion-panel-header style="background: #30bb87;">
                                      <mat-panel-title style="color: white;">
                                        Datos del Representante Autorizado
                                      </mat-panel-title>
                                      <mat-panel-description style="color: white;">
                                        <mat-icon>account_circle</mat-icon>
                                      </mat-panel-description>
                                    </mat-expansion-panel-header>
  
                                    <form [formGroup]="formGroupra" fxLayout="column">
                                      <div formArrayName="arr">
                                        <ng-container>
                                          <div [formGroupName]="in" style="margin-bottom: 10px;">
                                            <!-- <label for="name">Name:</label>
                                            <input type="text" name="name" formControlName="name" />
                                            <button type="button" (click)="removeItem(in)">Remove</button> -->
  
  
                                            <div fxFlex="1 0 auto" fxLayout="column">
                                                      
                                              <mat-form-field appearance="outline" fxFlex>
                                                  <mat-label>
                                                      Determinación {{ txtDeterminacion.toLowerCase() }}
                                                  </mat-label>
                                                  <input matInput formControlName="tipo" required>
                                                  <mat-error>Debes seleccionar una determinación {{ txtDeterminacion.toLowerCase() }}</mat-error>
                                              </mat-form-field>
                                              <mat-form-field  appearance="outline" fxFlex="50">
                                                  <mat-label>
                                                     Articulo
                                                  </mat-label>
                                                  <mat-select formControlName="articu" (selectionChange)="onSelectedChangera($event.value,in)" required>
                                                      <mat-option>-- Selecciona uno --</mat-option>
                                                      <mat-option value="30">30.-Nulidad/Lesividad</mat-option>
                                                      <mat-option value="31">31.-Responsabilidad Administrativa</mat-option>
                                                  </mat-select>
                                                  <mat-error>Debes seleccionar un Articulo</mat-error>
                                              </mat-form-field>
                                              <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGroupra.value.arr[in]['articu']=='30'">
                                                  <mat-label>
                                                     Fracción
                                                  </mat-label>
                                                  <mat-select formControlName="fracc" (selectionChange)="onSelectedChangera($event.value,in)" required>
                                                      <mat-option>-- Selecciona uno --</mat-option>
                                                      <mat-option *ngFor="let dep of doctypes" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                  </mat-select>
                                                  <mat-error>Debes seleccionar una fracción</mat-error>
                                              </mat-form-field>
                                              <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGroupra.value.arr[in]['articu']=='31'">
                                                  <mat-label>
                                                     Fracción
                                                  </mat-label>
                                                  <mat-select formControlName="fracc" required>
                                                      <mat-option>-- Selecciona uno --</mat-option>
                                                      <mat-option *ngFor="let dep of doctypes2" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                  </mat-select>
                                                  <mat-error>Debes seleccionar una fracción</mat-error>
                                              </mat-form-field>
                                              <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGroupra.value.arr[in]['fracc']=='III' && formGroupra.value.arr[in]['articu']=='30'">
                                                  <mat-label>
                                                     Inciso
                                                  </mat-label>
                                                  <mat-select formControlName="inci" required>
                                                      <mat-option>-- Selecciona uno --</mat-option>
                                                      <mat-option *ngFor="let dep of incisos" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                  </mat-select>
                                                  <mat-error>Debes seleccionar un Inciso</mat-error>
                                              </mat-form-field>
                      
                                              <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                                                  <mat-label>Fecha en que fue dictado</mat-label>
                                                  <input matInput [matDatepicker]="startDatePicker" formControlName="fecd" (focus)="startDatePicker.open()" required (dateChange)="setMinDateNoti()">
                                                  <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                                  <mat-datepicker #startDatePicker></mat-datepicker>
                                                  <mat-error>Debes ingresar una fecha</mat-error>
                                              </mat-form-field>
                                              <div *ngIf="(isAcuerdoNotification || isFisicNotificationAcue) && agreementData">
                                                  <a mat-raised-button color="accent" href="{{docu}}" target="_blank">
                                                      Ver documento
                                                  </a>
                                                  
                                              </div>
                                          </div>
                                          </div>
                                        </ng-container>
                                      </div>
                                      <br />
                                      <!-- <button type="button" (click)="addItem(user.nume)">Add</button> -->
                                    </form>
                              <mat-action-row>
                                </mat-action-row>
                              </mat-expansion-panel>
                            
                              </mat-accordion>
                          </div>
                        </section>
                    </div>
                    <div appearance="outline" class="pr-4" fxFlex *ngIf="ra.length == 0">
                      <section>
                          <div class="py-24">
                            <mat-hint>
                                No se encontraron Representantes Autorizados suscritos a este expediente, verifique los datos
                            </mat-hint>
                        </div>
                      </section>
                    </div>
                </mat-tab>
                <mat-tab label="Representante(s) Legal(es)"> 
                    <div appearance="outline" class="pr-4" fxFlex *ngIf="rl.length > 0">
                        <section *ngFor="let user of rl ;let in=index;">
                            <mat-checkbox  [(ngModel)]="user.checked" ngInit="user.checked=true" style="margin-right: 15px;" (change)="setUser(user, $event,in,'rl')" >
                                {{user.nomb}} {{user.pate}} {{user.mate}} <span class="labels-gray" *ngIf="!isAcuerdoNotification">({{user.type}})</span>  
                                <br>
                                                             
                                <span class="labels-gray"> <mat-icon color="accent">email</mat-icon>   ({{user.mail}})</span>
                            </mat-checkbox>
                            <div class="py-24" *ngIf="rl.length ==null">
                              <mat-hint>
                                  No se encontraron representates legales suscritos a este expediente, verifique los datos
                              </mat-hint>
                          </div>
                          <div *ngIf="user.checked"> 
                              <mat-accordion class="example-headers-align">
                                  <mat-expansion-panel color="accent" [expanded]="step === 0"  hideToggle>
                                    <mat-expansion-panel-header style="background: #30bb87;">
                                      <mat-panel-title style="color: white;">
                                        Datos del Representate Legal
                                      </mat-panel-title>
                                      <mat-panel-description style="color: white;">
                                        <mat-icon>account_circle</mat-icon>
                                      </mat-panel-description>
                                    </mat-expansion-panel-header>
  
                                    <form [formGroup]="formGrouprl" fxLayout="column">
                                      <div formArrayName="arr">
                                        <ng-container>
                                          <div [formGroupName]="in" style="margin-bottom: 10px;">
                                            <!-- <label for="name">Name:</label>
                                            <input type="text" name="name" formControlName="name" />
                                            <button type="button" (click)="removeItem(in)">Remove</button> -->
  
  
                                            <div fxFlex="1 0 auto" fxLayout="column">
                                                      
                                              <mat-form-field appearance="outline" fxFlex>
                                                  <mat-label>
                                                      Determinación {{ txtDeterminacion.toLowerCase() }}
                                                  </mat-label>
                                                  <input matInput formControlName="tipo" required>
                                                  <mat-error>Debes seleccionar una determinación {{ txtDeterminacion.toLowerCase() }}</mat-error>
                                              </mat-form-field>
                                              <mat-form-field  appearance="outline" fxFlex="50">
                                                  <mat-label>
                                                     Articulo
                                                  </mat-label>
                                                  <mat-select formControlName="articu" (selectionChange)="onSelectedChangerl($event.value,in)" required>
                                                      <mat-option>-- Selecciona uno --</mat-option>
                                                      <mat-option value="30">30.-Nulidad/Lesividad</mat-option>
                                                      <mat-option value="31">31.-Responsabilidad Administrativa</mat-option>
                                                  </mat-select>
                                                  <mat-error>Debes seleccionar un Articulo</mat-error>
                                              </mat-form-field>
                                              <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGrouprl.value.arr[in]['articu']=='30'">
                                                  <mat-label>
                                                     Fracción
                                                  </mat-label>
                                                  <mat-select formControlName="fracc" (selectionChange)="onSelectedChangerl($event.value,in)" required>
                                                      <mat-option>-- Selecciona uno --</mat-option>
                                                      <mat-option *ngFor="let dep of doctypes" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                  </mat-select>
                                                  <mat-error>Debes seleccionar una fracción</mat-error>
                                              </mat-form-field>
                                              <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGrouprl.value.arr[in]['articu']=='31'">
                                                  <mat-label>
                                                     Fracción
                                                  </mat-label>
                                                  <mat-select formControlName="fracc"  required>
                                                      <mat-option>-- Selecciona uno --</mat-option>
                                                      <mat-option *ngFor="let dep of doctypes2" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                  </mat-select>
                                                  <mat-error>Debes seleccionar una fracción</mat-error>
                                              </mat-form-field>
                                              <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGrouprl.value.arr[in]['fracc']=='III' && formGrouprl.value.arr[in]['articu']=='30'">
                                                  <mat-label>
                                                     Inciso
                                                  </mat-label>
                                                  <mat-select formControlName="inci" required>
                                                      <mat-option>-- Selecciona uno --</mat-option>
                                                      <mat-option *ngFor="let dep of incisos" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                  </mat-select>
                                                  <mat-error>Debes seleccionar un Inciso</mat-error>
                                              </mat-form-field>
                      
                                              <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                                                  <mat-label>Fecha en que fue dictado</mat-label>
                                                  <input matInput [matDatepicker]="startDatePicker" formControlName="fecd" (focus)="startDatePicker.open()" required (dateChange)="setMinDateNoti()">
                                                  <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                                  <mat-datepicker #startDatePicker></mat-datepicker>
                                                  <mat-error>Debes ingresar una fecha</mat-error>
                                              </mat-form-field>
                                              <div *ngIf="(isAcuerdoNotification || isFisicNotificationAcue) && agreementData">
                                                  <a mat-raised-button color="accent" href="{{docu}}" target="_blank">
                                                      Ver documento
                                                  </a>
                                                  
                                              </div>
                                          </div>
                                          </div>
                                        </ng-container>
                                      </div>
                                      <br />
                                      <!-- <button type="button" (click)="addItem(user.nume)">Add</button> -->
                                    </form>
                              <mat-action-row>
                                </mat-action-row>
                              </mat-expansion-panel>
                            
                              </mat-accordion>
                          </div>
                        </section>
                    </div>    
                    <div appearance="outline" class="pr-4" fxFlex *ngIf="rl.length == 0">
                      <section>
                          <div class="py-24">
                            <mat-hint>
                                No se encontraron Representantes legales suscritos a este expediente, verifique los datos
                            </mat-hint>
                        </div>
                      </section>
                    </div>
                </mat-tab>
                <mat-tab label="Otro" *ngIf="_data.typeNotification === 'electronicaenlinea'"> 
                    <section style="margin-top: 30px;">
                        <form [formGroup]="empForm" (ngSubmit)="onSubmit1()">

                    <div formArrayName="employees">
                
                    <div *ngFor="let employee of employees1().controls; let empIndex=index">
                
                        <div [formGroupName]="empIndex">
                            <div>
                                <mat-form-field appearance="outline" fxFlex="50">
                                    <mat-label>Nombre</mat-label>
                                    <input matInput formControlName="firstName">
                                    <mat-error>Debes ingresar un nombre</mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="50">
                                    <mat-label>Email</mat-label>
                                    <input matInput formControlName="lastName">
                                    <mat-error>Debes ingresar un Correo</mat-error>
                                </mat-form-field>
                            </div>
                            <div>
                                <!-- <mat-form-field appearance="outline" fxFlex="50">
                                    <mat-label>Tipo de parte</mat-label>
                                    <input matInput formControlName="tipoparte">
                                    <mat-error>Debes ingresar un tipo de parte</mat-error>
                                </mat-form-field> -->

                                <!-- <mat-form-field appearance="outline" fxFlex="50" > 
                                    <mat-label>
                                        Tipo de parte
                                    </mat-label>
                                    <mat-select formControlName="tipoparte" required>
                                        <mat-option *ngFor="let item of TypeCaracter" value="{{item.value}}">{{item.label}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Debes ingresar un tipo de parte
                                    </mat-error>
                                </mat-form-field> -->


                                <mat-form-field appearance="outline" fxFlex="50" >
                                    <mat-label>Tipo de parte</mat-label>
                                    <input type="text"
                                           placeholder="Selecciona un tipo de parte"
                                           aria-label="Parte"
                                           matInput
                                           formControlName="tipoparte"
                                           [matAutocomplete]="tipoparte">
                                    <mat-autocomplete #tipoparte="matAutocomplete">
                                      <mat-option *ngFor="let item of TypeCaracter" value="{{item.value}}">{{item.label}}
                                      </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                                <div class="example-button-container">
                                    <button mat-mini-fab color="warn"
                                        aria-label="Example icon button with a filter list icon"
                                        (click)="removeEmployee(empIndex)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                
                    </div>
                    </div>
                
                
                        </form>
                    
            
                    
                        <!-- <div fxLayout="row" fxLayoutAlign="space-evenly center" >
                            <button mat-raised-button type="button" color="accent" class="mt-40"  (click)="addEmployee()"[disabled]=" validatorotro()" >Agregar Otro</button>
                        </div> -->
                        <div fxLayout="row" fxLayoutAlign="space-evenly center" >
                            <button mat-mini-fab color="accent"(click)="addEmployee()"[disabled]=" validatorotro()">
                              <mat-icon>plus_one</mat-icon>
                            </button>
                          </div>
                    </section>
                </mat-tab>

                </mat-tab-group>
                <ng-template matStepLabel>{{ txtTitleUser }}</ng-template>
                    <div fxLayout="column" fxLayoutAlign="start start" style="overflow-x: auto;" *ngIf="!isFisicNotification && !isFisicNotificationAcue && !isFisicNotificationEstrados && typeModal != 'lista'">
                        

                        <div appearance="outline" class="pr-4" fxFlex *ngIf="autorities.length > 0">
                            <h3>Autoridades</h3>
                            <section *ngFor="let user of autorities;let in=index;">
                            <mat-checkbox [(ngModel)]="user.checked" ngInit="user.checked=true" style="margin-right: 15px;" (change)="setUser(user, $event,in,'autoridad')" >
                                {{user.nomb}} {{user.pate}} {{user.mate}} <span class="labels-gray" *ngIf="!isAcuerdoNotification">({{user.type}})</span>
                                <br>

                                <span class="labels-gray"> <mat-icon color="accent">email</mat-icon>   ({{user.mail}})</span>
                            </mat-checkbox>
                            <div *ngIf="user.checked"> 
                                <mat-accordion class="example-headers-align">
                                    <mat-expansion-panel color="accent" [expanded]="step === 0"  hideToggle>
                                      <mat-expansion-panel-header style="background: #30bb87;">
                                        <mat-panel-title style="color: white;">
                                          Datos del Autoridad
                                        </mat-panel-title>
                                        <mat-panel-description style="color: white;">
                                          <mat-icon>account_circle</mat-icon>
                                        </mat-panel-description>
                                      </mat-expansion-panel-header>
    
                                      <form [formGroup]="formGroupautoridad" fxLayout="column">
                                        <div formArrayName="arr">
                                          <ng-container>
                                            <div [formGroupName]="in" style="margin-bottom: 10px;">
                                              <!-- <label for="name">Name:</label>
                                              <input type="text" name="name" formControlName="name" />
                                              <button type="button" (click)="removeItem(in)">Remove</button> -->
    
    
                                              <div fxFlex="1 0 auto" fxLayout="column">
                                                        
                                                <mat-form-field appearance="outline" fxFlex>
                                                    <mat-label>
                                                        Determinación {{ txtDeterminacion.toLowerCase() }}
                                                    </mat-label>
                                                    <input matInput formControlName="tipo" required>
                                                    <mat-error>Debes seleccionar una determinación {{ txtDeterminacion.toLowerCase() }}</mat-error>
                                                </mat-form-field>
                                                <mat-form-field  appearance="outline" fxFlex="50">
                                                    <mat-label>
                                                       Articulo
                                                    </mat-label>
                                                    <mat-select formControlName="articu" (selectionChange)="onSelectedChangeautoridad($event.value,in)" required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option value="30">30.-Nulidad/Lesividad</mat-option>
                                                        <mat-option value="31">31.-Responsabilidad Administrativa</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar un Articulo</mat-error>
                                                </mat-form-field>
                                                <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGroupautoridad.value.arr[in]['articu']=='30'">
                                                    <mat-label>
                                                       Fracción
                                                    </mat-label>
                                                    <mat-select formControlName="fracc" (selectionChange)="onSelectedChangeautoridad($event.value,in)" required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option *ngFor="let dep of doctypes" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar una fracción</mat-error>
                                                </mat-form-field>
                                                <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGroupautoridad.value.arr[in]['articu']=='31'">
                                                    <mat-label>
                                                       Fracción
                                                    </mat-label>
                                                    <mat-select formControlName="fracc" (selectionChange)="onSelectedChangeautoridad($event.value,in)" required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option *ngFor="let dep of doctypes2" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar una fracción</mat-error>
                                                </mat-form-field>
                                                <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGroupautoridad.value.arr[in]['fracc']=='III' && formGroupautoridad.value.arr[in]['articu']=='30'">
                                                    <mat-label>
                                                       Inciso
                                                    </mat-label>
                                                    <mat-select formControlName="inci" required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option *ngFor="let dep of incisos" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar un Inciso</mat-error>
                                                </mat-form-field>
                        
                                                <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                                                    <mat-label>Fecha en que fue dictado</mat-label>
                                                    <input matInput [matDatepicker]="startDatePicker" formControlName="fecd" (focus)="startDatePicker.open()" required (dateChange)="setMinDateNoti()">
                                                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                                    <mat-datepicker #startDatePicker></mat-datepicker>
                                                    <mat-error>Debes ingresar una fecha</mat-error>
                                                </mat-form-field>
                                                <div *ngIf="(isAcuerdoNotification || isFisicNotificationAcue) && agreementData">
                                                    <a mat-raised-button color="accent" href="{{docu}}" target="_blank">
                                                        Ver documento
                                                    </a>
                                                    
                                                </div>
                                            </div>
                                            </div>
                                          </ng-container>
                                        </div>
                                        <br />
                                        <!-- <button type="button" (click)="addItem(user.nume)">Add</button> -->
                                      </form>
                                <mat-action-row>
                                  </mat-action-row>
                                </mat-expansion-panel>
                              
                                </mat-accordion>
                            </div>
                        </section>

                        </div>

                        <div appearance="outline" class="pr-4" fxFlex *ngIf="employees.length > 0">
                            <h3>Empleados (Asesor Ciudadano)</h3>
                            <section *ngFor="let employee of employees;let in=index;">

                            <mat-checkbox [(ngModel)]="employee.checked" ngInit="employee.checked=true" style="margin-right: 15px;" (change)="setUser(employee, $event,in,'asesor')" >
                                {{employee.nomb}} {{employee.pate}} {{employee.mate}} <span class="labels-gray" *ngIf="!isAcuerdoNotification">({{employee.type}})</span>
                                <br>
                                <span class="labels-gray"> <mat-icon color="accent">email</mat-icon>   ({{employee.mail}})</span>

                            </mat-checkbox>
                            <div *ngIf="employee.checked"> 
                                <mat-accordion class="example-headers-align">
                                    <mat-expansion-panel color="accent" [expanded]="step === 0"  hideToggle>
                                      <mat-expansion-panel-header style="background: #30bb87;">
                                        <mat-panel-title style="color: white;">
                                          Datos del Asesor
                                        </mat-panel-title>
                                        <mat-panel-description style="color: white;">
                                          <mat-icon>account_circle</mat-icon>
                                        </mat-panel-description>
                                      </mat-expansion-panel-header>
    
                                      <form [formGroup]="formGroupasesor" fxLayout="column">
                                        <div formArrayName="arr">
                                          <ng-container>
                                            <div [formGroupName]="in" style="margin-bottom: 10px;">
                                              <!-- <label for="name">Name:</label>
                                              <input type="text" name="name" formControlName="name" />
                                              <button type="button" (click)="removeItem(in)">Remove</button> -->
    
    
                                              <div fxFlex="1 0 auto" fxLayout="column">
                                                        
                                                <mat-form-field appearance="outline" fxFlex>
                                                    <mat-label>
                                                        Determinación {{ txtDeterminacion.toLowerCase() }}
                                                    </mat-label>
                                                    <input matInput formControlName="tipo" required>
                                                    <mat-error>Debes seleccionar una determinación {{ txtDeterminacion.toLowerCase() }}</mat-error>
                                                </mat-form-field>
                                                <mat-form-field  appearance="outline" fxFlex="50">
                                                    <mat-label>
                                                       Articulo
                                                    </mat-label>
                                                    <mat-select formControlName="articu" (selectionChange)="onSelectedChangeasesor($event.value,in)" required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option value="30">30.-Nulidad/Lesividad</mat-option>
                                                        <mat-option value="31">31.-Responsabilidad Administrativa</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar un Articulo</mat-error>
                                                </mat-form-field>
                                                <!--articulo 30-->
                                                <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGroupasesor.value.arr[in]['articu']=='30'">
                                                    <mat-label>
                                                       Fracción
                                                    </mat-label>
                                                    <mat-select formControlName="fracc" (selectionChange)="onSelectedChangeasesor($event.value,in)" required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option *ngFor="let dep of doctypes" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar una fracción</mat-error>
                                                </mat-form-field>
                                                <!--articulo 31-->
                                                <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGroupasesor.value.arr[in]['articu']=='31'">
                                                    <mat-label>
                                                       Fracción
                                                    </mat-label>
                                                    <mat-select formControlName="fracc"  required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option *ngFor="let dep of doctypes2" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar una fracción</mat-error>
                                                </mat-form-field>
                                                <!--cierra articulo 31-->
                                                <mat-form-field  appearance="outline" fxFlex="50" *ngIf="formGroupasesor.value.arr[in]['fracc']=='III' && formGroupasesor.value.arr[in]['articu']=='30'">
                                                    <mat-label>
                                                       Inciso
                                                    </mat-label>
                                                    <mat-select formControlName="inci" required>
                                                        <mat-option>-- Selecciona uno --</mat-option>
                                                        <mat-option *ngFor="let dep of incisos" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                                                    </mat-select>
                                                    <mat-error>Debes seleccionar un Inciso</mat-error>
                                                </mat-form-field>
                        
                                                <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                                                    <mat-label>Fecha en que fue dictado</mat-label>
                                                    <input matInput [matDatepicker]="startDatePicker" formControlName="fecd" (focus)="startDatePicker.open()" required (dateChange)="setMinDateNoti()">
                                                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                                    <mat-datepicker #startDatePicker></mat-datepicker>
                                                    <mat-error>Debes ingresar una fecha</mat-error>
                                                </mat-form-field>
                                                <div *ngIf="(isAcuerdoNotification || isFisicNotificationAcue) && agreementData">
                                                    <a mat-raised-button color="accent" href="{{docu}}" target="_blank">
                                                        Ver documento
                                                    </a>
                                                    
                                                </div>
                                            </div>
                                            </div>
                                          </ng-container>
                                        </div>
                                        <br />
                                        <!-- <button type="button" (click)="addItem(user.nume)">Add</button> -->
                                      </form>
                                <mat-action-row>
                                  </mat-action-row>
                                </mat-expansion-panel>
                              
                                </mat-accordion>
                            </div>
                        </section>
                        </div>

                        <div class="py-24" *ngIf="users.length == 0 && autorities.length == 0 && employees.length == 0">
                            <mat-hint>
                                No se encontraron actores suscritos a este expediente, verifique los datos
                            </mat-hint>
                        </div>
                    </div>
                    <mat-form-field appearance="outline" class="w-100-p" *ngIf="isFisicNotification || isFisicNotificationAcue ">
                        <mat-label>
                            Usuarios notificados
                        </mat-label>
                        <mat-select multiple formControlName="usnotify" required>
                            <mat-option *ngFor="let item of usNotify" value="{{item.name}}">{{item.name}}</mat-option>
                        </mat-select>
                        <mat-error>
                            Selecciona una opción
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p" *ngIf="isFisicNotification || isFisicNotificationAcue || typeModal == 'lista'">

                        <mat-label>Usuarios</mat-label>

                        <mat-chip-list #categoryList name="usua" formControlName="usua">

                            <mat-chip *ngFor="let user of usersForm.value.usua"
                                        [removable]="true" (removed)="removeUser(user)">
                                {{user}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>

                            <input [matChipInputFor]="categoryList"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addUser($event)"/>

                        </mat-chip-list>
                        <mat-hint>Pulsa ENTER para agregar </mat-hint>
                    </mat-form-field>
                    <div fxFlex="1 0 auto" fxLayout="column" style="overflow-x: auto;" *ngIf="isFisicNotificationEstrados">
                        <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                            <mat-label>Fecha de publicación</mat-label>
                            <input matInput 
                                    [min]="minDate" [max]="maxDate"
                                    [matDatepicker]="startDatePickerP"
                                    formControlName="disp" 
                                    (focus)="startDatePickerP.open()" required>
                            <mat-datepicker-toggle matSuffix [for]="startDatePickerP"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePickerP></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                            <div *ngIf="(isAcuerdoNotification || isFisicNotificationAcue) && agreementData">
                                <a mat-raised-button color="accent" href="{{docu}}" target="_blank">
                                    Ver documento
                                </a>
                                
                            </div>
                        </mat-form-field>
                    </div>
                    
                <div fxLayout="row" fxLayoutAlign="space-evenly center" >
                    <button mat-raised-button matStepperNext type="button" color="accent" class="mt-40" [disabled]="validUsersForm() || validarformsdin() || validatorotro()">Continuar</button>
                </div>
            </mat-step>
            <mat-step [stepControl]="resolutionForm" >
                <form  fxLayout="column" [formGroup]="resolutionForm" >
                    <ng-template matStepLabel>ANEXOS</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">
                        
                        <!-- <mat-form-field appearance="outline" fxFlex>
                            
                            <mat-label>
                                Determinación {{ txtDeterminacion.toLowerCase() }}
                            </mat-label>
                            <input matInput formControlName="tipo" [matAutocomplete]="tipo" required>
                            <mat-error>Debes seleccionar una determinación {{ txtDeterminacion.toLowerCase() }}</mat-error>
                        </mat-form-field> -->
                        <!--<mat-select formControlName="tipo" required >
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option value="acuerdo">Acuerdo/auto</mat-option>
                                <mat-option value="resolución">Resolución interlocutoria</mat-option>
                                <mat-option value="sentencia">Sentencia</mat-option>
                            </mat-select>-->
                        <!--     <mat-select>
                                <mat-option value="acuerdo">Acuerdo</mat-option>
                                <mat-option value="interlocutoria">Sentencia interlocutoria</mat-option>
                                <mat-option value="definitiva">Sentencia definitiva</mat-option>
                            </mat-select> -->
                        <!-- <mat-form-field  appearance="outline" fxFlex="50">
                            <mat-label>
                               Fracción
                            </mat-label>
                            <mat-select formControlName="fracc" (selectionChange)="onSelectedChange($event.value)" required>
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let dep of doctypes" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                            </mat-select>
                            <mat-error>Debes seleccionar una fracción</mat-error>
                        </mat-form-field>
                        <mat-form-field  appearance="outline" fxFlex="50" *ngIf="isShown">
                            <mat-label>
                               Inciso
                            </mat-label>
                            <mat-select formControlName="inci" required>
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let dep of incisos" value="{{ dep.handle }}">{{ dep.title }}</mat-option>
                            </mat-select>
                            <mat-error>Debes seleccionar un Inciso</mat-error>
                        </mat-form-field> -->

                        <!-- <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                            <mat-label>Fecha en que fue dictado</mat-label>
                            <input matInput [matDatepicker]="startDatePicker" formControlName="fecd" (focus)="startDatePicker.open()" required (dateChange)="setMinDateNoti()">
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                        </mat-form-field> -->

                        <mat-form-field appearance="outline" class="mr-sm-12" fxFlex *ngIf="isFisicNotification || isFisicNotificationAcue">
                            <mat-label>Fecha de notificación</mat-label>
                            <input matInput [min]="minDateNoti" [matDatepicker]="startDatePicker2" formControlName="fecn" (focus)="startDatePicker2.open()" required>
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker2"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker2></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                        </mat-form-field>

                        <mat-label *ngIf="isFisicNotification">Hora de notificación</mat-label>
                        <mat-form-field appearance="outline" *ngIf="isFisicNotification || isFisicNotificationAcue">
                            <input matInput placeholder="Simple placeholder" style="display: none;">
                            <ngx-timepicker-field [controlOnly]="true" [format]="24" formControlName="time"></ngx-timepicker-field>
                        </mat-form-field>

                        <!-- <div *ngIf="(isAcuerdoNotification || isFisicNotificationAcue) && agreementData">
                            <a mat-raised-button color="accent" href="{{docu}}" target="_blank">
                                Ver documento
                            </a>
                            
                        </div> -->

                        <div class="file-uploader" fxFlex="1 0 auto" *ngIf="!isAcuerdoNotification || isFisicNotificationAcue" fxLayout="column" fxLayoutAlign="space-envenly start">
                            <mat-label>{{ txtTitleDocs }}</mat-label>
                            <input type="file" #adju id="adju" name="adju" style="display:none;" accept="application/pdf" required/>
                            <button mat-raised-button color="info" (click)="setFile('adju')">  
                                <mat-icon>backup</mat-icon>  
                                <span> Elegir archivo</span>
                            </button> 
                            <div class="attachment-list" *ngIf="resolutionForm.value.adju !== null && resolutionForm.value.adju.name !== undefined">
                                <div class="attachment" fxLayout="row" fxLayoutAlign="space-between center">
                                    <div>
                                        <span class="filename">{{ resolutionForm.value.adju.name }}</span>
                                        <!--span class="size ml-4">(12 Kb)</spandaut-->
                                    </div>
                                    <button mat-icon-button aria-label="Delete attachment">
                                        <mat-icon class="s-16" (click)="removeFile('adju')" tooltip="Quitar archivo">close</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <mat-error *ngIf="resolutionForm.get('adju').hasError('required')" class="error">{{ txtErrorFileadju }}</mat-error>
                        </div>

                        <mat-label class="mt-16 mb-2">ANEXOS </mat-label>
                        <!--ANEXO MINUTA NOTIFICACION-->
                        <div *ngIf="isAcuerdoNotification">
                            <!-- <p>Cargar minuta electrónica</p>
                            <div class="file-uploader my-12" >
                                <div class="columna1">
                                    <div class="file-uploader my-16">
                                        <div fxLayout="row" fxFlex="100">
                                            <mat-form-field  appearance="outline" fxFlex class="pr-4">
                                                <input matInput type="text" 
                                                    id="{{anexoMinutaNoti.name}}desc" 
                                                    name="{{anexoMinutaNoti.name}}desc" 
                                                    (keyup)="addMinutaNotiFile(anexoMinutaNoti.name)" 
                                                    maxlength="100"
                                                    required />
                                                <mat-label *ngIf="typeUser!='Actuario'">
                                                    Minuta electrónica
                                                </mat-label>
                                                <mat-hint align="start">Número de fojas: {{ anexoMinutaNoti.numPages }}</mat-hint>
                                                <mat-hint align="end">{{ anexoMinutaNoti.desc.length}} / 100</mat-hint>
                                            </mat-form-field> 
                                        </div>
                                        <div fxLayout="row" fxFlex="100">
                                            <mat-error *ngIf="anexoMinutaNoti.error" class="error">{{anexoMinutaNoti.txtError}}</mat-error>
                                        </div>
                                    </div>
                                </div>

                                <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                    <input type="file" id="{{anexoMinutaNoti.name}}" name="{{anexoMinutaNoti.name}}" style="display:none;" accept="application/pdf" required/>
                                    <button mat-raised-button color="info" (click)="setFilerMinutaNoti(anexoMinutaNoti.name)" [disabled]="!anexoMinutaNoti.upload">  
                                        <mat-icon>backup</mat-icon>
                                        <span> {{ anexoMinutaNoti.txtBtnUp }}</span>
                                    </button> 
                                </div>
                            </div> -->
                            <!--ANEXO MINUTA LISTA-->
                            <!-- <div class="file-uploader my-12"  *ngIf="anexoBoletin">
                                <div class="columna1">
                                    <div class="file-uploader my-16">
                                        <div fxLayout="row" fxFlex="100">
                                            <mat-form-field  appearance="outline" fxFlex class="pr-4">
                                                <input matInput type="text" 
                                                    id="{{anexoBoletin.name}}desc" 
                                                    name="{{anexoBoletin.name}}desc" 
                                                    (keyup)="addBoletinFile(anexoBoletin.name)" 
                                                    maxlength="100"
                                                    required />
                                                <mat-label>
                                                    Boletín
                                                </mat-label>
                                                <mat-hint align="start">Número de fojas: {{ anexoBoletin.numPages }}</mat-hint>
                                                <mat-hint align="end">{{ anexoBoletin.desc.length}} / 100</mat-hint>
                                            </mat-form-field> 
                                        </div>
                                        <div fxLayout="row" fxFlex="100">
                                            <mat-error *ngIf="anexoBoletin.error" class="error">{{anexoBoletin.txtError}}</mat-error>
                                        </div>
                                    </div>
                                </div>

                                <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                    <input type="file" id="{{anexoBoletin.name}}" name="{{anexoBoletin.name}}" style="display:none;" accept="application/pdf" required/>
                                    <button mat-raised-button color="info" (click)="setFilerBoletin(anexoBoletin.name)" [disabled]="!anexoBoletin.upload">  
                                        <mat-icon>backup</mat-icon>
                                        <span> {{ anexoBoletin.txtBtnUp }}</span>
                                    </button> 
                                </div>
                            </div> -->
                            <!--FIRMA ELECTRONICA-->
                            <!-- <div class="row" fxLayoutAlign="center center">
                                <div class="col-sm-3">
                                    Cargar archivo FIREL <i class="icono fas fa-upload"></i> 
                                    <input type="file" id="pfx" accept=".pfx"  class="upload-input"  (change)="SeleccionarPfx($event.target)" />
                                    <div id="divArchivo" class="textoArchivo"></div>
                                    <div id="divCargaFirel"  class="cargaFirel">                        
                                    </div>
                                    <br>
                                    <mat-form-field appearance="outline" fxFill>
                                        <mat-label>Escriba su contraseña:</mat-label>
                                        <input matInput type="password" [formControl]="password"/>
                        
                                    </mat-form-field>
                                </div>
                            </div> -->
                            <!--ANEXOS-->
                            <!-- <p>OTROS ANEXOS</p> -->
                            <div *ngIf="agreementData && agreementData.oficDocUrl">
                                <p>Oficio del acuerdo</p>
                                <mat-checkbox style="margin-right: 15px;" (change)="setDocuOfic(agreementData.oficDocUrl, $event)"> 
                                    <a mat-button color="accent" href="{{agreementData.oficDoc }}" target="_blank">Ver documento</a>
                                </mat-checkbox>
                            </div>
                            <p>Archivos de promoción</p>
                            <mat-checkbox *ngFor="let item of documentsPromotion" style="margin-right: 15px;" (change)="setDocuExpe(item, $event)" >
                                {{item.dscr}} <a mat-button color="accent" href="{{item.docu }}" target="_blank">Ver documento</a>
                            </mat-checkbox>
                        </div>
                        <div class="file-uploader my-16" *ngFor="let file of anexosArray; let in=index;">
                            <div class="columna1">
                                <div class="file-uploader my-16">
                                    <div fxLayout="row" fxFlex="100">
                                        <input type="file" id="{{file.name}}" name="{{file.name}}" style="display:none;" accept="application/pdf" required/>
                                <button mat-raised-button color="info" (click)="setFiler(file.name, in)">  
                                    <mat-icon>backup</mat-icon>
                                    <span> {{ file.txtBtnUp }}</span>
                                </button> 
                                    </div>
                                    <div fxLayout="row" fxFlex="100">
                                        <mat-error *ngIf="file.error" class="error">{{file.txtError}}</mat-error>
                                    </div>
                                </div>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                <button mat-mini-fab aria-label="Delete attachment" *ngIf="file.file">
                                    <mat-icon (click)="removeFiler(in)" tooltip="Quitar archivo">close</mat-icon>
                                    <!--span>Quitar archivo</span-->
                                </button>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex *ngIf="showMoreFileBtn && anexosArray.length < 5">
                            <button mat-raised-button color="accent" (click)="addOther()" [disabled]="anexosArray.length === 5">  
                                <mat-icon>add_circle_outline</mat-icon>  
                                <span> Subir otro archivo</span>
                            </button> 
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button matStepperNext type="button" color="accent" [disabled]="!resolutionForm.valid">
                            Continuar
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step *ngIf="boletaForm" [stepControl]="boletaForm">
                <ng-template matStepLabel>BOLETÍN ELECTRÓNICO</ng-template>
                <div fxFlex="1 0 auto" fxLayout="column">
                   <div *ngIf="typeModal == 'electronicaenlinea'" style="text-align: justify;">“PROGRAMA LA FECHA DE PUBLICACIÓN DE LA NOTIFICACIÓN POR BOLETÍN ELECTRÓNICO, PARA EL SUPUESTO CONTEMPLADO EN LA FRACCIÓN VI DE ARTÍCULO 33 DEL CÓDIGO DE PROCEDIMIENTOS DE JUSTICIA ADMINISTRATIVA DEL ESTADO DE GUERRERO NÚMERO 763”.</div>
                    <form fxLayout="column" [formGroup]="boletaForm">
                        <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                            <mat-label>Fecha de publicación</mat-label>
                            <input matInput 
                                    [min]="minDate"
                                    [matDatepicker]="startDatePickerP"
                                    formControlName="date_pub" 
                                    (focus)="startDatePickerP.open()" required>
                            <mat-datepicker-toggle matSuffix [for]="startDatePickerP"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePickerP></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                        </mat-form-field>

                       

                        <div fxLayout="row" fxLayoutAlign="space-evenly center" >
                            <button mat-raised-button matStepperNext type="button" color="accent" class="mt-40" [disabled]="!boletaForm.valid">Continuar</button>
                        </div>
                    </form>
                </div>
            </mat-step>
            <mat-step [stepControl]="firma">
                <ng-template matStepLabel>FIRMA</ng-template>
                <div fxFlex="1 0 auto" fxLayout="column">
                    <form  fxLayout="column" [formGroup]="resolutionForm" >
                        <div fxFlex="1 0 auto" fxLayout="column">
                        
                            <!--ANEXO MINUTA NOTIFICACION-->
                            
                            <div class="col-sm-3">
                                Cargar archivo FIREL <i class="icono fas fa-upload"></i> 
                                <input type="file" id="pfx" accept=".pfx"  class="upload-input"  (change)="SeleccionarPfx($event.target)" />
                                <div id="divArchivo" class="textoArchivo"></div>
                                <div id="divCargaFirel"  class="cargaFirel">                        
                                </div>
                                <br>
                                <mat-form-field appearance="outline" fxFill>
                                    <mat-label>Escriba su contraseña:</mat-label>
                                    <input matInput type="password" [formControl]="password"/>
                    
                                </mat-form-field>
                            </div>
    
                           
                        </div>
                       
                    </form>
                </div>
            </mat-step>
        </mat-vertical-stepper>
        <mat-vertical-stepper linear="false" #stepper id="stepper" name="stepper" *ngIf="isNotificationSala">
            <mat-step [stepControl]="transferForm">
                <form fxLayout="column" [formGroup]="transferForm" >
                    <ng-template matStepLabel>EXPEDIENTE</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">
                        <mat-form-field  appearance="outline" fxFlex="50">
                            <mat-select formControlName="depa" required>
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let dep of allDepartaments" value="{{ dep.nume }}">{{ dep.depa }}</mat-option>
                            </mat-select>
                            <mat-label>
                                Sala Superior o Sala Regional
                            </mat-label>
                            <mat-error>Debes seleccionar un sal regional o sala superior</mat-error>
                        </mat-form-field>

                        <mat-label class="mt-16 mb-2">Anexos </mat-label>
                        <div class="file-uploader my-16" *ngFor="let file of anexosArray; let in=index;">
                            <div class="columna1">
                                <div class="file-uploader my-16">
                                    <div fxLayout="row" fxFlex="100">
                                        <mat-form-field  appearance="outline" fxFlex class="pr-4">
                                            <input matInput type="text" 
                                                id="{{file.name}}desc" 
                                                name="{{file.name}}desc" 
                                                (keyup)="addDescFile(file.name, in)" 
                                                maxlength="100"
                                                required />
                                            <mat-label>
                                                Descripción del documento
                                            </mat-label>
                                            <mat-hint align="start">Número de fojas: {{ file.numPages }}</mat-hint>
                                            <mat-hint align="end">{{ file.desc.length}} / 100</mat-hint>
                                        </mat-form-field> 
                                    </div>
                                    <div fxLayout="row" fxFlex="100">
                                        <mat-error *ngIf="file.error" class="error">{{file.txtError}}</mat-error>
                                    </div>
                                </div>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                <input type="file" id="{{file.name}}" name="{{file.name}}" style="display:none;" accept="application/pdf" required/>
                                <button mat-raised-button color="info" (click)="setFiler(file.name, in)" [disabled]="!file.upload">  
                                    <mat-icon>backup</mat-icon>
                                    <span> {{ file.txtBtnUp }}</span>
                                </button> 
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                <button mat-mini-fab aria-label="Delete attachment" *ngIf="file.file">
                                    <mat-icon (click)="removeFile(in)" tooltip="Quitar archivo">close</mat-icon>
                                    <!--span>Quitar archivo</span-->
                                </button>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex *ngIf="showMoreFileBtn && anexosArray.length < 5">
                            <button mat-raised-button color="accent" (click)="addOther()" [disabled]="anexosArray.length === 5">  
                                <mat-icon>add_circle_outline</mat-icon>  
                                <span> Subir otro archivo</span>
                            </button> 
                        </div>
                        <div class="row" fxLayoutAlign="center center">
                            <div class="col-sm-3">
                                Cargar archivo FIREL <i class="icono fas fa-upload"></i> 
                                <input type="file" id="pfx" accept=".pfx"  class="upload-input"  (change)="SeleccionarPfx($event.target)" />
                                <div id="divArchivo" class="textoArchivo"></div>
                                <div id="divCargaFirel"  class="cargaFirel">                        
                                </div>
                                <br>
                                <mat-form-field appearance="outline" fxFill>
                                    <mat-label>Escriba su contraseña:</mat-label>
                                    <input matInput type="password" [formControl]="password"/>
                    
                                </mat-form-field>
                            </div>
                            
                        </div>
                    </div>
                </form>
            </mat-step>

        </mat-vertical-stepper>
        </div>
    </div>
    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <!--<button (click)="consoleForm()">
                ver formulario
            </button>-->
            <button mat-raised-button
                    color="accent"
                    (click)="firmar()"
                    class="save-button"
                    [disabled]="validInactiveSendBtn() || (password.value == '' && isAcuerdoNotification) || isLoading"
                    aria-label="SAVE">
                Enviar
                <div fxLayout="column" fxLayoutAlign="center center " *ngIf="isLoading">
                    <mat-progress-spinner
                        diameter="25"
                        mode="indeterminate"
                        [value]="valueLoading">
                    </mat-progress-spinner>
                </div>
            </button>
            <!--<button (click)="consoleForm()">
                ver formulario
            </button>-->
            <mat-error *ngIf="txtErrorNoti != ''">{{txtErrorNoti}}</mat-error>
        </div>

        <button mat-icon-button (click)="matDialogRef.close(['delete',[expedientForm, resolutionForm, usersForm]])"
                aria-label="Delete"
                matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
