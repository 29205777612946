interface AgreementFormOptions{
    AditionalOptions:any[],
    TipoSentido:any[],
    AcuerdoSentidoOptions:any[],
    SubsentidoOptions:any[],
}

export class SecretarioEjecucionOptions implements AgreementFormOptions{

    AditionalOptionsId={
        RECURSO:0,
        INCIDENTE:0,
        AMPARO_INDIRECTO:0,
        AMPARO_DIRECTO:0,
        ACUERDOS_FRECUENTES:0,
    }

AditionalOptions=[
    
    //Recurso Options
    { nume: 1,  acuerdo_id: 10, type:"nulidad", name: "Revisión" },
    { nume: 2,  acuerdo_id: 10, type:"nulidad grave", name: "Reclamación" },
    { nume: 3,  acuerdo_id: 10, type:"nulidad grave", name: "Queja" },
    { nume: 4,  acuerdo_id: 10, type:"grave", name: "Apelación" },
    { nume: 5,  acuerdo_id: 10, type:"grave", name: "Reclamación (R.Grave)" },
    { nume: 6,  acuerdo_id: 10, type:"nulidad grave", name: "Inconformidad" },
      //Incidente Options
    { nume: 7,  acuerdo_id: 11,type:"nulidad grave",  name:"Acumulación de autos"},
    { nume: 8,  acuerdo_id: 11,type:"nulidad grave",  name:"Nulidad de notificaciones"},
    { nume: 9,  acuerdo_id: 11,type:"nulidad grave",  name:"Interrupción del procedimiento por muerte o por disolución en el caso de las personas morales"},
    { nume: 10, acuerdo_id: 11,type:"nulidad grave",  name:"Incompetencia por razón de territorio"},
    { nume: 11, acuerdo_id: 11,type:"nulidad grave",  name:"Aclaración de sentencia"},
    { nume: 12, acuerdo_id: 11,type:"nulidad grave",  name:"Liquidación"},
    { nume: 13, acuerdo_id: 11,type:"nulidad grave",  name:"Cumplimiento sustituto"},
    { nume: 14, acuerdo_id: 11,type:"nulidad grave",  name:"Falsedad de documento"},
    { nume: 15, acuerdo_id: 11,type:"nulidad grave",  name:"Medidas cautelares"},
    //AMPARO INDIRECTO
    {nume: 16, acuerdo_id: 17,type:"nulidad grave", name:"Se rinde informe"}, 
    {nume: 17, acuerdo_id: 17,type:"nulidad grave", name:"Trámite de amparo"},
    {nume: 18, acuerdo_id: 17,type:"nulidad grave", name:"Sentido de ejecutoria"},
    {nume: 19, acuerdo_id: 17,type:"nulidad grave", name:"Cumplimiento de ejecutoria"},

    //AMPARO DIRECTO
    {nume: 23, acuerdo_id: 19,type:"nulidad grave", name:"Recibe demanda"}, 
    {nume: 24, acuerdo_id: 19,type:"nulidad grave", name:"Remite demanda"},
    {nume: 25, acuerdo_id: 19,type:"nulidad grave", name:"Se recibe de colegiado"},
    {nume: 26, acuerdo_id: 19,type:"nulidad grave", name:"Sentido de ejecutoria"},
    //Acuerdos frecuentes 
    {nume: 20, acuerdo_id: 18,type:"nulidad grave", name:"Domicilio procesal y autorizados"}, 
    {nume: 21, acuerdo_id: 18,type:"nulidad grave", name:"Representante común"},
    {nume: 22, acuerdo_id: 18,type:"nulidad grave", name:"Otros"},

   


  ];


TipoSentido = [
    
             
    {nume: 20, type: 'nulidad grave', name:'Recibe expediente'},
    {nume: 21, type: 'nulidad grave', name:'Procedimiento de cumplimiento de sentencia'},
    {nume: 22, type: 'nulidad grave', name:'Pruebas para mejor proveer'},
    {nume: 24, type: 'nulidad grave', name:'Apersonamiento a juicio de terceros'},
    {nume: 13, type: 'nulidad', name:'Medios alternos de solución de controversias'},
    {nume: 14, type: 'nulidad grave', name:'Convenios y comparecencias'},
    {nume: 3, type: 'nulidad', name:'Fianzas'},
    {nume: 23, type: 'nulidad grave', name:'Nueva autoridad y o administración'},
    {nume: 18, type: 'nulidad grave', name:'Acuerdos frecuentes'},

];


AcuerdoSentidoOptions=[
//RECIBE EXPEDIENTE
{nume: 291,  option_id:0,acuerdo_id:20, type:"nulidad grave", name:"Se recibe expediente debidamente integrado, se radica, se requiere cumplimiento de sentencia y se apercibe con imposición de multa"},
{nume: 292,  acuerdo_id:20, type:"nulidad grave", name:"Se devuelve a la Sala Regional para su debida integración"},
//PROCEDIMIENTO DE CUMPLIMIENTO DE SENTENCIA
{nume: 293,  acuerdo_id:21, type:"nulidad", name:"Se tiene por no cumplida la sentencia, se impone multa y se requiere cumplimiento"},
{nume: 294,  acuerdo_id:21, type:"nulidad", name:"Se tiene por no cumplida la sentencia y se impone multa"},
{nume: 295,  acuerdo_id:21, type:"        grave", name:"Se tiene por no cumplida la sentencia, se requiere cumplimiento y se apercibe por única vez con una multa del monto máximo de la sanción que impuso la Sala Regional"},
{nume: 296,  acuerdo_id:21, type:"        grave", name:"Se tiene por no cumplida la sentencia y se requiere cumplimiento"}, 
{nume: 297,  acuerdo_id:21, type:"nulidad grave", name:"Se tiene por cumplida parcialmente la sentencia y se impone multa"},
{nume: 298,  acuerdo_id:21, type:"nulidad grave", name:"Se tiene por cumplida parcialmente la sentencia y se requiere cumplimiento"},
{nume: 299,  acuerdo_id:21, type:"        grave", name:"Se tiene por no cumplida la sentencia y se solicita al superior jerárquico conmine al servidor público responsable de la ejecución de la sanción"},
{nume: 300, acuerdo_id:21, type:"        grave", name:"No se acredita conminación al servidor público responsable y se impone multa. Se previene por última ocasión a la autoridad responsable de la ejecución de la sanción y se apercibe con destitución"},
{nume: 301, acuerdo_id:21, type:"        grave", name:"Se tiene por no cumplida la sentencia, se decreta la destitución del servidor público responsable de la ejecución de la sanción"},
{nume: 302, acuerdo_id:21, type:"nulidad", name:"Se tiene por no cumplida la sentencia y se solicita al superior jerárquico conmine al servidor público responsable"},
{nume: 303, acuerdo_id:21, type:"nulidad", name:"No se acredita conminación al servidor público responsable y se impone multa"},
{nume: 304, acuerdo_id:21, type:"nulidad", name:"Se previene por última ocasión a la autoridad demandada para dar cumplimiento a la sentencia"},
{nume: 305, acuerdo_id:21, type:"nulidad", name:"Se tiene por no cumplida la sentencia, se ordena la destitución del servidor público responsable"},
{nume: 306, acuerdo_id:21, type:"nulidad grave", name:"Se ordena la integración del expediente, previo envío al Congreso del Estado"},
{nume: 307, acuerdo_id:21, type:"        grave", name:"Se tiene por no cumplida la sentencia, se formula denuncia de juicio político del servidor público con fuero constitucional responsable de la ejecución de la sanción y se envia al congreso"},
{nume: 308, acuerdo_id:21, type:"nulidad", name:"Se tiene por no cumplida la sentencia, se formula denuncia de juicio político al servidor público responsable con fuero constitucional y se envía al congreso"},
{nume: 309, acuerdo_id:21, type:"nulidad grave", name:"Se tiene por cumplida la sentencia y se devuelve a la sala de origen para su archivo"},

//PRUEBAS PARA MEJOR PROVEEr
{nume: 310, acuerdo_id:22, type:"nulidad", name:"Se requiere a la parte actora para que acredite lo conducente en caso de liquidación y se da vista a la autoridad demandada"},
{nume: 311, acuerdo_id:22, type:"nulidad", name:"Se desahoga vista de autoridad respecto de la liquidación"},
{nume: 313, acuerdo_id:22, type:"grave", name:"Se requiere informe de autoridad para mejor proveer respecto a la determinación de la liquidación y se da vista a las partes"},
{nume: 314, acuerdo_id:22, type:"grave", name:"Se desahoga vista respecto de la liquidación"},
{nume: 312, acuerdo_id:22, type:"nulidad grave", name:"Trámite de informe de autoridad"},
{nume: 315, acuerdo_id:22, type:"nulidad grave", name:"Trámite de la prueba pericial"},
{nume: 316, acuerdo_id:22, type:"nulidad grave", name:"Trámite de la inspección"},
{nume: 317, acuerdo_id:22, type:"nulidad grave", name:"Se desiste de las pruebas"},

//APERSONAMIENTO A JUICIO DE 
{nume: 318, acuerdo_id:24, type:"nulidad grave", name:"Se tiene por apersonado a juicio"},
{nume: 319, acuerdo_id:24, type:"nulidad grave", name:"Se previene respecto del apersonamiento a juicio"},
{nume: 3319, acuerdo_id:24, type:"nulidad grave", name:"Se desahoga prevención y se le tiene por apersonado a juicio"},

//MEDIOS ALTERNOS DE SOLUCIÓN DE CONTROVERSIAS
{nume: 320, acuerdo_id:13, type:"nulidad grave", name:"Se acuerda la solicitud de la aplicación de medios alternos de solución de controversias"},
{nume: 321, acuerdo_id:13, type:"nulidad", name:"Se aplica medio alterno de solución de controversias"},
{nume: 322, acuerdo_id:13, type:"grave", name:"Convenio con demanda (medios alternos de solución de controversias)"},
//CONVENIOS Y COMPARECENCIAS
{nume: 323, acuerdo_id:14, type:"nulidad grave", name:"Convenio de cumplimiento de sentencia"},
{nume: 324, acuerdo_id:14, type:"nulidad", name:"Convenio de cumplimiento sustituto de sentencia"},
{nume: 325, acuerdo_id:14, type:"nulidad grave", name:"Comparecencia de pago de cumplimiento de sentencia"},
{nume: 326, acuerdo_id:14, type:"nulidad grave", name:"Comparencia de pago parcial de cumplimiento de sentencia"},
//FIANZAS
//NUEVA AUTORIDAD Y/O ADMINISTRACIÓN
{nume: 327, acuerdo_id:23, type:"nulidad grave", name:"Se recibe expediente debidamente integrado, se radica el toca, se informa a autoridad demandada respecto del procedimiento de ejecución de sentencia, se requiere cumplimiento de sentencia y se apercibe"},
{nume: 328, acuerdo_id:23, type:"nulidad grave", name:"Se informa a autoridad demandada respecto del procedimiento de ejecución de sentencia"},
{nume: 329, acuerdo_id:23, type:"nulidad grave", name:"Se requiere a la autoridad cumplimiento de sentencia y se apercibe"},

//ACUERDOS FRECUENTES
{nume: 330, acuerdo_id:18, type:"nulidad grave", name:"Se tiene por realizada la objeción de documentos"},
{nume: 331, acuerdo_id:18, type:"nulidad grave", name:"Se concede prórroga a la autoridad para dar cumplimiento de sentencia"},
{nume: 332, acuerdo_id:18, type:"nulidad grave", name:"Se niega prórroga a la autoridad para dar cumplimiento de sentencia"},
{nume: 333, acuerdo_id:18, type:"nulidad grave", name:"Se ordena regularizar el procedimiento de ejecución de sentencia"},
{nume: 334, acuerdo_id:18, type:"nulidad grave", name:"Comparecencia para devolución de documentos originales"},
{nume: 335, acuerdo_id:18, type:"nulidad grave", name:"Agréguese a los autos"},
{nume: 336, acuerdo_id:18, type:"nulidad grave", name:"No ha lugar a acordar de conformidad lo solicitado"},
{nume: 337, acuerdo_id:18, type:"nulidad grave", name:"Téngase por hechas sus manifestaciones"},
{nume: 338, acuerdo_id:18, type:"nulidad grave", name:"Se acordará lo conducente en el momento procesal oportuno"},
{nume: 339, acuerdo_id:18, type:"nulidad grave", name:"Estése a lo acordado"},
{nume: 340, acuerdo_id:18, type:"nulidad grave", name:"Se previene respecto del procedimiento de ejecución de sentencia"},
{nume: 341, acuerdo_id:18, type:"nulidad grave", name:"Se tiene por desahogada la prevención"},
{nume: 342, acuerdo_id:18, type:"nulidad grave", name:"Se autoriza la expedición de copias"},
{nume: 343, acuerdo_id:18, type:"nulidad grave", name:"Comparecencia"},
{nume: 344, acuerdo_id:18, type:"nulidad grave", name:"Otro"},

];

SubsentidoOptions=[

{nume: 1,  sentido_id: 312,type:"nulidad grave", name:"Se requiere informe de autoridad para mejor proveer respecto a la determinación de la liquidación"},
{nume: 2,  sentido_id: 312,type:"nulidad grave", name:"Se tiene por rendido el informe de autoridad"},
{nume: 3,  sentido_id: 312,type:"nulidad grave", name:"Se tiene por rendido parcialmente el informe de autoridad y se impone multa"},
{nume: 4,  sentido_id: 312,type:"nulidad grave", name:"Se tiene por no rendido el informe de autoridad y se impone multa"},
{nume: 5,  sentido_id: 315,type:"nulidad grave", name:"Se ordena de oficio el trámite de la prueba pericial"},
{nume: 6,  sentido_id: 315,type:"nulidad grave", name:"Se tiene por designado al perito y se acuerda su preparación"},
{nume: 7,  sentido_id: 315,type:"nulidad grave", name:"Comparecencia de perito para protesta y aceptación de cargo"},
{nume: 8,  sentido_id: 315,type:"nulidad grave", name:"Se tiene por rendido el dictamen"},
{nume: 9,  sentido_id: 315,type:"nulidad grave", name:"Comparecencia de ratificación de dictamen"},
{nume: 10, sentido_id: 315,type:"nulidad grave", name:"Se tiene al perito por no ratificado el dictamen y se declara desierta la prueba"},
{nume: 11, sentido_id: 315,type:"nulidad grave", name:"Se tiene al perito por no ratificado el dictamen y se provee lo conducente"},
{nume: 12, sentido_id: 315,type:"nulidad grave", name:"Se sustituye perito"},
{nume: 13, sentido_id: 315,type:"nulidad grave", name:"Se acepta la excusa del perito y se designa nuevo"},
{nume: 14, sentido_id: 315,type:"nulidad grave", name:"No se acepta la excusa del perito"},
{nume: 15, sentido_id: 315,type:"nulidad grave", name:"Se impone multa a perito por incumplimiento"},
{nume: 16, sentido_id: 315,type:"nulidad grave", name:"Se concede prórroga al perito para rendir dictamen"},
{nume: 17, sentido_id: 315,type:"nulidad grave", name:"Se niega prórroga al perito para rendir dictamen"},
{nume: 18, sentido_id: 315,type:"nulidad grave", name:"Se precluye el derecho para rendir dictamen"},
{nume: 19, sentido_id: 315,type:"nulidad grave", name:"Se desahoga requerimiento adiciona cuestionario y se designa perito"},
{nume: 20, sentido_id: 315,type:"nulidad grave", name:"Se designa perito tercero en discordia"},
{nume: 21, sentido_id: 316,type:"nulidad grave", name:"Se señala fecha de inspección para verificar el cumplimiento de la sentencia"},
{nume: 22, sentido_id: 316,type:"nulidad grave", name:"Se difiere la diligencia de inspección para verificar el cumplimiento de la sentencia"},
{nume: 23, sentido_id: 316,type:"nulidad grave", name:"Acta de inspección de verificación de cumplimiento de la sentencia"},

    

];

}


