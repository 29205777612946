<!-- BOARDS -->
<div id="boards" fxLayout="column" fxLayoutAlign="start center" fxFlex fusePerfectScrollbar [ngClass]="{
    'texture-background-authority': currentUser.tipo === 'autoridad',
    'texture-background-citizen': currentUser.tipo === 'ciudadano',
    'texture-background-secretario-regional': typeUserName === 'Secretario de acuerdos sala regional',
    'texture-background-secretario-general': typeUserName === 'Secretario de acuerdos general',
    'texture-background-secretario-adjunto': typeUserName.includes('adjunto'),
    'texture-background-magistrado-regional': typeUserName === 'Magistrado',
    'texture-background-magistrado-pleno': typeUserName === 'Magistrado pleno',
    'texture-background-asesor': typeUserName === 'Asesor ciudadano',
    'texture-background-proyectista': typeUserName === 'Secretario de estudio y cuenta',
    'texture-background-actuario': typeUserName === 'Actuario',
    'texture-background-perito': typeUserName === 'perito',
    'texture-background-employee': typeUserName === 'Secretario de acuerdos general',
    'texture-background': typeUserName.includes('adjunto')
  }">

    <div class="header" fxLayout="row" fxLayoutAlign="center center" fxFlex="0 0 auto" [@animate]="{value:'*',params:{delay:'100ms'}}">
        <div class="logo-tri" [@animate]="{value:'*',params:{scale:'0.5'}}">
            <img width="130" src="assets/images/logos/logogro.png">
        </div>

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <h1><span class="name_title">e</span>.Justicia Administrativa Guerrero</h1>
        </div>
    </div>

    <!-- BOARD LIST 'texture-background-employee': currentUser.tipo === 'empleado', -->
    <div class="board-list " fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" [@animateStagger]="{value:'50'}">

        <!-- BOARD -->
        <div class="board-list-item {{board.id}}" *ngFor="let board of boards" (click)="navigateTo(board)" fxLayout="column" fxLayoutAlign="center center" [@animate]="{value:'*',params:{y:'100%'}}" [ngClass]="{
            'background-authority': currentUser.tipo === 'autoridad',
            'background-citizen': currentUser.tipo === 'ciudadano',
            'background-perito': currentUser.tipo === 'perito',
            'background-secretario-regional': typeUserName === 'Secretario de acuerdos sala regional',
            'background-secretario-general': typeUserName === 'Secretario de acuerdos general',
            'background-secretario-adjunto': typeUserName.includes('adjunto'),
            'background-magistrado-regional': typeUserName === 'Magistrado',
            'background-magistrado-pleno': typeUserName === 'Magistrado pleno',
            'background-proyectista': typeUserName === 'Secretario de estudio y cuenta',
            'background-asesor': typeUserName === 'Asesor ciudadano',
            'background-actuario': typeUserName === 'Actuario'
          }">
            <mat-icon class="s-56">{{board.icon}}</mat-icon>
            <div class="board-name">{{board.name}}</div>
        </div>
        <!-- / BOARD -->

    </div>
    <!-- / BOARD LIST -->
</div>
<!-- / BOARDS -->