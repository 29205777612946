import { Orientation } from 'ngx-guided-tour';

export const tourCitizen = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las operaciones que puedes realizar en el sistema e.Justicia Administrativa Guerrero.',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Registro de demanda / recurso / procedimiento inicial',
        content: 'Aquí puedes registrar distintas demandas de tipo inicial.',
        selector: '.create-demand',
        orientation: Orientation.Top
    },
    {
        title: 'Registro de promociones',
        content: 'Podrás continuar con el proceso jurisdiccional ingresando una promoción relativa a un asunto que ya se está atendiendo en el Tribunal de Justicia Administraiva del Estado de Guerrero.',
        selector: '.create-promotion',
        orientation: Orientation.Right
    },
    {
        title: 'Historial de promociones',
        content: 'Consulta la lista de promociones que has registrado, así como la información de cada una de ellas.',
        selector: '.list-promotion',
        orientation: Orientation.Top
	},
    {
        title: 'Buzón de notificaciones',
        content: 'Esta es la bandeja de entrada donde puedes ver las notificaciones electrónicas recibidas.',
        selector: '.admin-notication',
        orientation: Orientation.Left
    },
    {
        title: 'Crear solicitudes de notificación electrónica en juicio tradicional',
        content: 'Aquí puedes solicitar la recepción de notificaciones electrónicas.',
        selector: '.create-expediente',
        orientation: Orientation.Top,
        scrollAdjustment: 560
    },
    {
        title: 'Historial de solicitud de notificaciones electrónicas en juicio tradicional',
        content: 'Consulta la lista de todas tus solicitudes de notificación electrónica, así como el estatus de cada una de ellas.',
        selector: '.list-expediente',
        orientation: Orientation.Top
    },
	{
        title: 'Asesoría ciudadana',
        content: 'Consulta la lista de solicitudes de asesoría ciudadana.',
        selector: '.adviser-list',
        orientation: Orientation.Top
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
];
export const tourAuthority = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las operaciones que puedes realizar en el sistema e.Justicia Administrativa Guerrero.',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Registro de promociones',
        content: 'Podrás continuar con el proceso jurisdiccional ingresando una promoción relativa a un asunto que ya se está atendiendo en el Tribunal de Justicia Administraiva del Estado de Guerrero.',
        selector: '.create-promotion',
        orientation: Orientation.Right
    },
    {
        title: 'Historial de promociones',
        content: 'Consulta la lista de promociones que has registrado, así como la información de cada una de ellas.',
        selector: '.list-promotion',
        orientation: Orientation.Top
	},
    {
        title: 'Buzón de notificaciones',
        content: 'Esta es la bandeja de entrada donde puedes ver las notificaciones electrónicas recibidas.',
        selector: '.admin-notication',
        orientation: Orientation.Left
    },
    {
        title: 'Crear solicitudes de notificación electrónica en juicio tradicional',
        content: 'Aquí puedes solicitar la recepción de notificaciones electrónicas.',
        selector: '.create-expediente',
        orientation: Orientation.Top,
        scrollAdjustment: 560
    },
    {
        title: 'Historial de solicitud de notificaciones electrónicas en juicio tradicional',
        content: 'Consulta la lista de todas tus solicitudes de notificación electrónica, así como el estatus de cada una de ellas.',
        selector: '.list-expediente',
        orientation: Orientation.Top
    },
    {
        title: 'Registro de juicio de lesividad',
        content: 'Aquí puedes registrar un juicio de lesividad.',
        selector: '.create-demand',
        orientation: Orientation.Top
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
];

export const tourAsesor = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las operaciones que puedes realizar en el sistema e.Justicia Administrativa Guerrero.',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Buzón de asesoría ciudadana',
        content: 'Consulta la lista de solicitudes de asesoría asignadas.',
        selector: '.adviser-list',
        orientation: Orientation.Top
    },
    {
        title: 'Registro de demanda / recurso / procedimiento inicial',
        content: 'Aquí puedes registrar distintas demandas de tipo inicial.',
        selector: '.create-demand',
        orientation: Orientation.Left
    },
    {
        title: 'Registro de promociones',
        content: 'Podrás continuar con el proceso jurisdiccional ingresando una promoción relativa a un asunto que ya se está atendiendo en el Tribunal de Justicia Administraiva del Estado de Guerrero.',
        selector: '.create-promotion',
        orientation: Orientation.Right
    },
    {
        title: 'Historial de promociones',
        content: 'Consulta la lista de promociones que has registrado, así como la información de cada una de ellas.',
        selector: '.list-promotion',
        orientation: Orientation.Top
    },
    {
        title: 'Buzón de notificaciones',
        content: 'Esta es la bandeja de entrada donde puedes ver las notificaciones electrónicas recibidas.',
        selector: '.admin-notication',
        orientation: Orientation.Top
    },
    {
        title: 'Crear solicitudes de notificación electrónica en juicio tradicional',
        content: 'Aquí puedes solicitar la recepción de notificaciones electrónicas.',
        selector: '.create-expediente',
        orientation: [Orientation.Right, Orientation.Top],
        scrollAdjustment: 560
    },
    {
        title: 'Historial de solicitud de notificaciones electrónicas en juicio tradicional',
        content: 'Consulta la lista de todas tus solicitudes de notificación electrónica, así como el estatus de cada una de ellas.',
        selector: '.list-expediente',
        orientation: Orientation.Top
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
];

export const tourSecretary = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las operaciones que puedes realizar en e.Justicia Administrativa Guerrero.',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Solicitud de notificación electrónica en juicio tradicional',
        content: 'Consulta la lista de todas tus solicitudes de notificación electrónica, así como el estatus de cada una de ellas.',
        selector: '.manager-expediente',
        orientation: Orientation.Top
    },
    {
        title: 'Administrar promociones',
        content: 'Consulta la lista de promociones que has registrado, así como la información de cada una de ellas.',
        selector: '.manager-promotion',
        orientation: Orientation.Top
    },
    {
        title: 'Administrar acuerdos',
        content: 'Consulta la lista de acuerdos que has registrado, así como la información de cada uno de ellos.',
        selector: '.manager-agreement',
        orientation: Orientation.Top
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
]

export const tourActuary = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las operaciones que puedes realizar en e.Justicia Administrativa Guerrero.',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Gestor de notificaciones',
        content: 'Consulta la lista de acuerdos que has recibido, así como la información de cada uno de ellos.',
        selector: '.manager-agreement',
        orientation: Orientation.Top
    },
    {
        title: 'Registro de notificación electrónica',
        content: 'Crear una nueva notificación electrónica',
        selector: '.electronica',
        orientation: Orientation.Top
    },
    {
        title: 'Registro de notificación física',
        content: 'Registrar una notificación física',
        selector: '.fisica',
        orientation: Orientation.Top
    },
    {
        title: 'Historial de notificaciones',
        content: 'Esta es la bandeja de entrada donde puedes ver las notificaciones electrónicas emitidas.',
        selector: '.admin-notication',
        orientation: Orientation.Top
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
]

export const tourMagistrado = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las operaciones que puedes realizar en e.Justicia Administrativa Guerrero.',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Sentencias',
        content: 'Esta es la bandeja donde podrás firmar las setencias que has dictado.',
        selector: '.manager-sentences',
        orientation: Orientation.Right
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
]