import { FuseNavigation } from '@fuse/types';

const resetFlags = () => {
    console.log('reset flags');
    sessionStorage.setItem('promotions', 'false');
    sessionStorage.setItem('agreements', 'false');
    sessionStorage.setItem('notifications', 'false');
    sessionStorage.setItem('expedients', 'false');
    sessionStorage.setItem('users', 'false');
    //sessionStorage.setItem('selectedAsesory', undefined);
    sessionStorage.setItem('sentences', 'false');
    sessionStorage.setItem('electronic-expedient', 'false');


}


const estadistica: FuseNavigation = {
    id      : 'admin-notication',
    title   : 'Estadística',
    type    : 'item',
    icon    : 'bar_chart',
    url     : '/estadistic/main',
    function : resetFlags
}

const dashboardMenu: FuseNavigation = {
    id       : 'sample',
    title    : 'Inicio',
    type     : 'item',
    icon     : 'home',
    url      : '/app/inicio',
    function : resetFlags
    /*badge    : {
        title    : '25',
        translate: 'NAV.SAMPLE.BADGE',
        bg       : '#F44336',
        fg       : '#FFFFFF'
    }*/
};

const usersPromoventes: FuseNavigation = {
    id      : 'users-promoventes',
    title   : 'Usuarios Promoventes',
    type    : 'item',
    icon    : 'accessibility_new',
    url  : '/users/list/ciudadano',
    children: [
        {
            id   : 'create-promovente',
            title: 'Crear usuario',
            type : 'item',
            url  : '/users/promotors-create'
        },
        {
            id   : 'list-promovente',
            title: 'Listar usuario',
            type : 'item',
            url  : '/users/list/ciudadano'
        }
    ]
};

const userOfficers: FuseNavigation = {
    id      : 'officers',
    title   : 'Gestión de funcionarios',
    type    : 'item',
    icon    : 'supervisor_account',
    url  : '/users/list/empleado',
    children: [
        {
            id   : 'create-promovente',
            title: 'Crear usuario',
            type : 'item',
            url  : '/users/officers-create'
        },
        {
            id   : 'list-promovente',
            title: 'Listar usuario',
            type : 'item',
            url  : '/users/list/empleado'
        }
    ]
};


const usersMenu: FuseNavigation = {
    id      : 'users',
    title   : '',
    type    : 'group',
    icon    : 'emoji_people',
    children: [
        usersPromoventes,
        userOfficers
    ]
}

const authoritiesMenu: FuseNavigation = {
    id      : 'authorities',
    title   : '',
    type    : 'group',
    icon    : 'security',
    children: [
        {
            id      : 'admin-authorities',
            title   : 'Administración de autoridades',
            type    : 'collapsable',
            icon    : 'supervisor_account',
            children: [
                {
                    id   : 'create-authoritie',
                    title: 'Entidades',
                    type : 'item',
                    url  : '/users/list/entidad'
                },
                {
                    id   : 'list-authoritie',
                    title: 'Autoridades',
                    type : 'item',
                    url  : '/users/list/autoridad'
                }
            ]
        }
    ]
}

const expedientMenu: FuseNavigation = {
    id      : 'manager-expediente',
    title   : 'Notificaciones electrónicas',
    type    : 'collapsable',
    icon    : 'assignment',
    children: [
        {
            id   : 'create-expediente',
            title: 'Solicitud de notificación electrónica en juicio tradicional',
            type : 'item',
            icon : 'notifications_none',
            url  : '/management/expedient-create',
            function : resetFlags
        },
        {
            id   : 'list-expediente',
            title: 'Historial de solicitudes de notificación electrónica en juicio tradicional',
            type : 'item',
            icon : 'room_service',
            url  : '/management/expedients',
            function : resetFlags
        }
    ]
        
}

const expedientAdminMenu: FuseNavigation = {
    id      : 'manager-expediente',
    title   : 'Solicitud de notificación electrónica en juicio tradicional',
    type    : 'item',
    icon    : 'room_service',
    url  : '/management/expedients',
    function : resetFlags
    /*children: [
        {
            id   : 'list-expediente',
            title: 'Listar expedientes',
            type : 'item',
            url  : '/management/expedients'
        }
    ]*/
}

const expedientSecretaryMenu: FuseNavigation = {
    id      : 'manager-sentences',
    title   : 'Sentencias',
    type    : 'item',
    icon    : 'gavel',
	url  : '/sentences/all',
	function : resetFlags       
}
const plenoSecretaryMenu: FuseNavigation = {
    id      : 'manager-sentences',
    title   : 'Pleno',
    type    : 'item',
    icon    : 'insert_drive_file',
	url  : '/pleno/all',
	function : resetFlags       
}

const amparosMenu: FuseNavigation = {
    id      : 'manager-amparos',
    title   : 'Amparos',
    type    : 'item',
    icon    : 'move_to_inbox',
	url  : '/amparos/all',
	function : resetFlags       
}
const expedientMagistradoMenu: FuseNavigation = {
    id      : 'manager-sentences',
    title   : 'Historial de sentencias',
    type    : 'item',
    icon    : 'import_contacts',
	url  : '/sentences/proyect/all',
	function : resetFlags     
}

const expedientSecretaryStudyMenu: FuseNavigation = {
    id      : 'manager-sentences',
    title   : 'Historial de sentencias',
    type    : 'item',
    icon    : 'import_contacts',
	url  : '/sentences/proyect/all',
	function : resetFlags    
}

const dashboardEstadisticasMenu: FuseNavigation = {
    id      : 'manager-statistics',
    title   : 'Estadísticas',
    type    : 'item',
    icon    : 'dashboard',
	url  : '/statistics',
	function : resetFlags       
}

const promotionsMenu: FuseNavigation = {
    id      : 'manager-promotion',
    title   : 'Promociones',
    type    : 'collapsable',
    icon    : 'chat',
    children: [ 
        // {
        //     id   : 'create-demand',
        //     title: 'Registro de demanda / recurso / procedimiento inicial',
        //     type : 'item',
        //     icon : 'edit',
        //     url  : '/promotion/promotion-demand',
        //     function : resetFlags
        // },
        // {
        //     id   : 'create-promotion',
        //     title: 'Registro de promociones',
        //     type : 'item',
        //     icon : 'edit',
        //     url  : '/promotion/promotion-create',
        //     function : resetFlags
        // },
        {
            id   : 'create-demand',
            title: 'Juicio de Nulidad',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-nulidad',
            function : resetFlags
        },
        {
            id   : 'create-promotion',
            title: 'Juicio de Lesividad',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-lesividad',
            function : resetFlags
        },
        {
            id   : 'create-promotion',
            title: 'Juicio de Responsabilidad Administrativa',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-responsabilidad-administrativa',
            function : resetFlags
        },
        {
            id   : 'list-promotion',
            title: 'Historial de promociones',
            type : 'item',
            icon : 'description',
            url  : '/promotion/all',
            function : resetFlags
        }
    ]
}
const promotionsCitizenMenu: FuseNavigation = {
    id      : 'manager-promotion',
    title   : 'Promociones',
    type    : 'collapsable',
    icon    : 'chat',
    children: [ 
        {
            id   : 'create-demand',
            title: 'Juicio de Nulidad / Lesividad',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-nulidad',
            function : resetFlags
        },
        {
            id   : 'create-promotion',
            title: 'Juicio de Responsabilidad Administrativa',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-responsabilidad-administrativa',
            function : resetFlags
        },
        {
            id   : 'list-promotion',
            title: 'Historial de promociones',
            type : 'item',
            icon : 'description',
            url  : '/promotion/all',
            function : resetFlags
        }
    ]
}


const promotionsAutorityMenu: FuseNavigation = {
    id      : 'manager-promotion',
    title   : 'Promociones',
    type    : 'collapsable',
    icon    : 'chat',
    children: [ 
        {
            id   : 'create-promotion',
            // title: 'Registro de demanda / recurso / procedimiento inicial',
            title: 'Registro de promociones',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-create',
            function : resetFlags
        },
        {
            id   : 'list-promotion',
            title: 'Historial de promociones',
            type : 'item',
            icon : 'description',
            url  : '/promotion/all',
            function : resetFlags
        },
        
        
    ]
}

const promotionsOfficersMenu: FuseNavigation = {
    id      : 'manager-promotion',
    title   : 'Administrar promociones',
    type    : 'item',
    icon  : 'description',
    url   : '/promotion/all',
    function : resetFlags
}

const agreementsOfficersMenu: FuseNavigation = {
    id      : 'manager-agreement',
    title   : 'Administrar acuerdos',
    type    : 'item',
    icon    : 'import_contacts',
    url  : '/agreements/all',
    function : resetFlags
}

const agreementsActuaryMenu: FuseNavigation = {
    id      : 'manager-agreement',
    title   : 'Acuerdos',
    type    : 'item',
    icon    : 'import_contacts',
    url  : '/agreements/all',
    function : resetFlags
}
const SentenceActuaryMenu: FuseNavigation = {
    id      : 'manager-agreement',
    title   : 'Sentencias',
    type    : 'item',
    icon    : 'gavel',
    url  : '/sentences/all',
    function : resetFlags
}
const agreementsMagistradoMenu: FuseNavigation = {
    id      : 'manager-agreement',
    title   : 'Administrar acuerdos',
    type    : 'item',
    icon    : 'import_contacts',
    url  : '/agreements/all',
    function : resetFlags
}

const proyectSentenceMenu: FuseNavigation = {
    id      : 'manager-proyect-sentence',
    title   : 'Sentencias',
    type    : 'item',
    icon    : 'gavel',
    url  : '/sentences/all',
    function : resetFlags
}

const sentencesProyectMenu: FuseNavigation = {
    id      : 'manager-agreement',
    title   : 'Proyectos de sentencia',
    type    : 'item',
    icon    : 'gavel',
    url  : '/sentences/all',
    function : resetFlags
}

const notificationMenu: FuseNavigation = {
    id      : 'admin-notication',
    title   : 'Buzón de notificaciones',
    type    : 'item',
    icon    : 'mail',
    url     : '/notifications/mail',
    function : resetFlags
}
const HisorialMenu: FuseNavigation = {
    id   : 'list-promotion',
    title: 'Historial de promociones',
    type : 'item',
    icon : 'description',
    url  : '/promotion/all',
    function : resetFlags
}

const notificationActuaryMenu: FuseNavigation = {
    id      : 'admin-notication',
    title   : 'Historial de notificaciones',
    type    : 'item',
    icon    : 'mail',
    url     : '/notifications/mail',
    function : resetFlags
}

const actionsActuaryMenu: FuseNavigation[] = [
    //@ALAN SE OCULTA LOS BOTONES YA QUE NO JALA EL MODULO DE AUTOCOMPLETAR
    {
        id      : 'juicio',
        title   : 'Notificación juicio en línea',
        type    : 'item',
        function : resetFlags,
        icon    : 'mail',
    },
    {
        id      : 'fisica',
        title   : 'Notificación juicio tradicional',
        type    : 'item',
        function : resetFlags,
        icon    : 'mail',
    }
]
const actionsPeritoMenu: FuseNavigation[] = [
    {
        id      : 'promociones',
        title   : 'Registro de promociones',
        type    : 'item',
        function : resetFlags,
        icon    : 'edit',
    },

]

const asesorAction: FuseNavigation[] = [
    {
        id      : 'adviser-list',
        title   : 'Buzón de asesoría ciudadana',
        type    : 'item',
        function : resetFlags,
        url     : '/administrator/advisers',
        icon    : 'question_answer',
    }
]

const citizenAction: FuseNavigation[] = [
    {
        id      : 'adviser-list',
        title   : 'Asesoría ciudadana',
        type    : 'item',
        function : resetFlags,
        url     : '/administrator/advisers',
        icon    : 'question_answer',
    }
]

const expedientJudgment: FuseNavigation = 
{
    id: 'expedient-judgment',
    title: 'Asuntos resueltos sin previo juicio',
    type: 'item',
    url: '/topics/topic-create',
    icon: 'question_answer',
}

const expedientTopic: FuseNavigation = {
    id: 'expedient-topic',
    title: 'Asuntos resueltos sin previo juicio',
    type: 'item',
    url: '/topics/all',
    icon: 'question_answer',
}


const electronicExpedientTray: FuseNavigation = {
    id: 'electronic-expedient-tray',
    title: 'Expediente electrónico',
    type: 'item',
    url: '/electronic-expedient/all',
    icon: 'question_answer',
}



const electronicExpedientCitizen: FuseNavigation[] = [{
    id      : 'manager-promotion',
    title   : 'Expediente electrónico',
    type    : 'collapsable',
    icon    : 'chat',
    children: [ 
        {
            id: 'electronic-expedient-request',
            title: 'Solicitar acceso',
            type: 'item',
            icon: 'question_answer',
            url: '/topics/all',
        },
        {
            id: 'electronic-expedient-history',
            title: 'Historial de solicitudes',
            type: 'item',
            icon: 'question_answer',
            url: '/electronic-expedient/history',
            function : resetFlags
        },
        
        
    ]
}];

const electronicExpedientSecretary: FuseNavigation = {
    id: 'electronic-expedient-tray',
    title: 'Expediente electrónico',
    type: 'item',
    url: '/electronic-expedient/all',
    icon: 'question_answer',
}


const electronicExpedientFuntionary: FuseNavigation = {
    id: 'electronic-expedient-functionary',
    title: 'Expediente electrónico',
    type: 'item',
    url: '/electronic-expedient/all',
    icon: 'question_answer',
}



export const adminMenu: FuseNavigation[] = [
    dashboardMenu,
    expedientAdminMenu,
    notificationMenu,
    usersMenu,
    authoritiesMenu,
];

export const actuaryMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        agreementsActuaryMenu,
        SentenceActuaryMenu,
        //...actionsActuaryMenu,
        notificationActuaryMenu,
        
    ]
}];

export const actuaryMenuDashboard: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        agreementsActuaryMenu,
        SentenceActuaryMenu,
        //...actionsActuaryMenu,
        notificationActuaryMenu,
    ]
}];

export const secretaryMenu2: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        promotionsOfficersMenu,
        agreementsOfficersMenu,
        expedientSecretaryMenu,
        dashboardEstadisticasMenu,
        amparosMenu,
        plenoSecretaryMenu,
        expedientAdminMenu,
        //expedientJudgment,
        expedientTopic,
        electronicExpedientSecretary,
       // estadistica,
    ]
}];

export const secretaryMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        promotionsOfficersMenu,
        amparosMenu,
        agreementsOfficersMenu,
        expedientSecretaryMenu,
        dashboardEstadisticasMenu,
        expedientAdminMenu,
        //expedientJudgment,
        expedientTopic,
        electronicExpedientSecretary,
       // estadistica,
    ]
}];
export const secretaryAjuntoMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        promotionsOfficersMenu,
        agreementsOfficersMenu,
      
    ]
}];

export const streamMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        promotionsMenu,
        notificationMenu,
        expedientMenu,
        electronicExpedientTray,
        // ...citizenAction
    ]
}];

export const citizenMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        promotionsCitizenMenu,
        notificationMenu,
        expedientMenu,
        electronicExpedientTray,
        // ...citizenAction
    ]
}];

export const peritoMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        ...actionsPeritoMenu,
        HisorialMenu,
        notificationMenu,
       
    ]
}];
export const peritoMenuDashboard: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        ...actionsPeritoMenu,
        HisorialMenu,
        notificationMenu,
       
    ]
}];

export const asesorMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        ...asesorAction,
        promotionsCitizenMenu,
        notificationMenu,
        expedientMenu,
        electronicExpedientTray,
    ]
}];

export const authorityMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        promotionsMenu,
        notificationMenu,
        expedientMenu,

        // {
        //     id   : 'create-demand',
        //     title: 'Registro juicio de lesividad',
        //     type : 'item',
        //     icon : 'edit',
        //     url  : '/promotion/promotion-demand',
        //     function : resetFlags
        // }
        
        
    ]
}];

export const magistradoMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        amparosMenu,
        agreementsMagistradoMenu,
        proyectSentenceMenu,
		expedientMagistradoMenu,
		dashboardEstadisticasMenu,
        electronicExpedientTray
    ]
}];

export const proyectistaMenu: FuseNavigation[] = [{
    id: 'applications',
    title: '',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    children: [
        dashboardMenu,
        sentencesProyectMenu,
        expedientSecretaryStudyMenu
    ]
}];




export const magistradoSalaSuperiorMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        amparosMenu,
        agreementsMagistradoMenu,
        proyectSentenceMenu,
        plenoSecretaryMenu,
		expedientMagistradoMenu,
		dashboardEstadisticasMenu,
        electronicExpedientTray
    ]
}];

