import { debug } from 'console';
import { SecretarioRegionalStatus,MagistradoSalaRegionalStatus,MagistradoSalaSuperiorStatus,ActuarioStatus ,SecretarioGeneralStatus} from '../statusInterface';

export class Todo
{
    id: string;
    crtd: string; // "2020-06-06 17:14:20"
    date: string; // "2020-06-01 00:00:00"
    detd: string; //null
    docu: string; //"https://https://enlinea.trijaem.ml/notificaciones/acuerdos/U75ZQUdN8e3JFHNOcaqengYImDJfZH3P60nmazO7.pdf"
    docusign: string;
    empl: string; //52
    empldata: Object; //{data: {nume: 52, conf: "", depa: 1, trab: 20, usua: 103, crtd: "2020-06-03 16:12:29",…}}
    expe: string; //"2/2020"
    sinte: string; //"2/2020"

    nume: string; //1
    ref: string; //"CIJ-20200606-VBMF0"
    tipo: string;
    une: string; //""
    uptd: string; //"2020-06-06 17:14:20"

    actr: string;//2
    cons: string;//"Acuerdo Oficioso"
    depa: string;//6
    mate: string;//""
    nomb: string;//"qeq"
    noti: any;//[{nume: 145, tipo: "acuerdo", fisi: 0, estr: 1, crtd: "2020-08-22 19:22:44"}]
    pate: string;//""
    publ: string; //"2020-08-24T05:00:00.000000Z"
    stat: any; //"notificado"
    signdoc:any;
    tpac: string; //19
    tpju: string; //8
    tppa: string; //16
    tpse: string; //"16,104"
    ag_nume: string;
    sentido_acuerdo: number;
    subsentido_acuerdo: number;
    sentido_otro: string;
    secretario_adjunto:string;
    razon_correcion:any;
    toca:string;
    secretario_adjunto_atendido:boolean;
    expeu: string;
    actor: string;
    oficDoc: string;
    oficDocUrl: string;
    actaPleno:string;
    conActaPleno:boolean
    sinFirmaSecretarioGeneral:string;
    statusPleno:any;
    turnoFirmar:boolean;
    desact:string;
    sisale:string;
    aler:string;
    docu_original: string;
    /**
     * Constructor
     *
     * @param todo
     */
    constructor(todo)
    {
        {   
            console.log("todo selected api", todo);
            this.id   = todo.nume;
            this.sisale=todo.sisale;
            this.crtd = todo.crtd;
            this.date = todo.date;
            this.detd = todo.detd;
            this.docu = todo.docu;
            this.docusign = todo.docusign;
            this.docu_original = todo.docu_original;
            this.empl = todo.empl;
            this.empldata = todo.empldata;
            this.expe = todo.expe;
            this.sinte=todo.sinte||'';
            this.nume = todo.nume;
            this.ref = todo.ref;
            this.tipo = todo.tipo;
            this.une = todo.une;
            this.uptd = todo.uptd;
            this.actr = todo.actr;
            this.cons = todo.cons;
            this.depa = todo.depa;
            this.mate = todo.mate;
            this.nomb = todo.nomb;
            this.noti = todo.noti;
            this.pate = todo.pate;
            this.publ = todo.publ;
            this.stat = todo.stat;
            this.signdoc=todo.signdoc;
            this.tpac = todo.tpac;
            this.tpju = todo.tpju;
            this.tppa = todo.tppa;
            this.tpse = todo.tpse;
            this.ag_nume = todo.ag_nume;
            this.sentido_acuerdo = todo.sentido_acuerdo;
            this.subsentido_acuerdo = todo.subsentido_acuerdo;
            this.sentido_otro = todo.sentido_otro;
            this.secretario_adjunto = todo.secretario_adjunto;
            this.toca = todo.toca;
            this.razon_correcion = todo.razon_correcion;
            this.secretario_adjunto_atendido = todo.tpse==='N/A'?false:true;
            this.expeu = todo.promos || null; 
            this.actor = todo.citizen;
            this.oficDoc = todo.oficDoc;
            this.oficDocUrl = todo.oficDocUrl;
            this.actaPleno = todo.actaPleno;
            this.conActaPleno = todo.conActaPleno;
            this.sinFirmaSecretarioGeneral = todo.sinFirmaSecretarioGeneral;
            this.desact=todo.desact;
            this.aler=todo.aler;
           
            const user = JSON.parse(sessionStorage.getItem('usr'));
            const empleado = JSON.parse(sessionStorage.getItem('em'));
            console.log('debug user todo model',user,sessionStorage);

            const filterSignDoc = todo.signdoc && todo.signdoc.length > 0 ? todo.signdoc.filter(item=>item.signable_id == todo.nume && item.employee_id == user.nume)[0]:{enviado:null,firmado:null};
            const firmoPleno=todo.signdoc? todo.signdoc.filter(item=>item.firmado === null && item.employee_id!==8).length===0:false;
            /* console.log("debug estatus pleno sign",filterSignDoc , firmoPleno);
            console.log("debug estatus enviado",filterSignDoc.enviado );
            console.log("debug estatus firmado",filterSignDoc.firmado );
            console.log("debug user",sessionStorage,user ); */


        
                this.statusPleno = {name:null,icon:null};
                
               this.turnoFirmar=false;

               if(sessionStorage.getItem('tyem').includes('Secretario de acuerdos sala regional')){
                    this.stat=new SecretarioRegionalStatus().setStatus(todo.stat);
               } 

               if(sessionStorage.getItem('tyem').includes('Actuario')){

                //se pone para Sistema de alertas
                this.stat=new ActuarioStatus().setStatus(this.sisale);
             } 

               if(sessionStorage.getItem('tyem').includes('general') ){


                console.log('debug filterSignDoc',filterSignDoc);


                    if ( filterSignDoc?.enviado && filterSignDoc?.firmado===null) {
                        this.turnoFirmar=true;
                    }
                    this.stat=new SecretarioGeneralStatus().setStatus(todo.stat,todo);


                 /*    if (todo.stat=='firmado' || todo.stat=='firmado_magistrado'  ) 
                    {
                        const firmasCompletas=todo.signdoc.filter(item=>item.firmado !== null).length===6;
                        const turnoFirmarSecretario=todo.signdoc.filter(item=>item.firmado !== null && item.employee_id!==8 ).length===5;
                        
                        if(turnoFirmarSecretario){
                            this.stat={name:'Pendiente por firmar',color:'purple-fg',icon:'history',handle:'firmado_magistrado'};
                        }else{
                            this.stat={name:'En firma del pleno',color:'blue-fg',icon:'history',handle:'firma_pleno'};
                        }

                        if (firmasCompletas) {
                            this.stat={name:'Firmado/acordado',color:'green-fg',icon:'history',handle:'firmado_secretario'};
                        }

                    
                    }  */


                } 


                if(sessionStorage.getItem('tyem') == 'Magistrado'){

                    console.log('debug turno magistrado presidente',filterSignDoc, todo.signdoc);

                    if ( filterSignDoc?.enviado && filterSignDoc.firmado===null) {
                        this.turnoFirmar=true;
                    }

                    if (empleado.depa=='Sala Superior') {
                        this.stat=new MagistradoSalaSuperiorStatus().setStatus(todo.stat,todo,filterSignDoc,this.turnoFirmar);
                    }
                    
                    if (empleado.depa!=='Sala Superior') {
                        this.stat=new MagistradoSalaRegionalStatus().setStatus(todo.stat);
                    }


                        /*  if (todo.stat=='corregido' &&  this.turnoFirmar) 
                         {

                            this.statusPleno = {name:"Corregido",icon:"orange-fg"}

                        } 

                        if (todo.stat=='corregido' &&  !this.turnoFirmar) 
                        {

                            this.statusPleno = {name:"En firma con otro magistrado",icon:"orange-fg"}

                       } 


                         if (todo.stat=='firmado' || todo.stat=='pendiente' || todo.stat=='firmado_magistrado' || todo.stat=='firmado_secretario' ) 
                         {
                            if(filterSignDoc.firmado===null && filterSignDoc.enviado===null ){
                                 this.statusPleno = {name:"En firma con otro magistrado",icon:"orange-fg"}
                            }
                            else if( filterSignDoc.enviado && filterSignDoc.firmado===null ){
                                this.statusPleno = {name:"Pendiente",icon:"orange-fg"}
                            }
                            else if( filterSignDoc.enviado && filterSignDoc.firmado && !firmoPleno){
                                this.statusPleno = {name:"En proceso de firma",icon:"green-fg"} 
                            }
                            else if( firmoPleno ){
                                this.statusPleno = {name:"Firmado",icon:"green-fg"}
                            } 
                        } 

                        if (todo.stat=='corregir' ) {
                   
                            this.statusPleno = {name:"En correción",icon:"red-fg"}

                    
                        }  */
                    
                } 

              
                
                console.log("debug estatus pleno 2", this.statusPleno);

                    
                

        }
    }

}