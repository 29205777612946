interface IStatus {
    name: string;
    color: string;
    icon: string;
    handle: string;
}
class Status {


    name: string;
    color: string;
    icon: string;
    handle: string;

    constructor(obj?: IStatus) {
        console.log('debug status handle',obj);
        this.name = obj?.name ?? ''
        this.color = obj?.color ?? ''
        this.icon = obj?.icon ?? ''
        this.handle = obj?.handle ?? '';
    }


}

interface StatusMethod {
    setStatus(statusHandle: string, agreement?: any, user?: any, filterSignDoc?: any, turnoFirmar?: boolean): Status;
}

export class SecretarioRegionalStatus implements StatusMethod {
    status: Status;

    constructor() {
        this.status = new Status;
    }

    setStatus(statusHandle): Status {

    
        if (statusHandle==undefined) {
            return this.status;
        }

        if (statusHandle.hasOwnProperty('name')) {
            return statusHandle;
        }

        switch (statusHandle) {
            case 'pendiente':
                this.status = new Status({ name: 'En revisión', color: 'orange-fg', icon: 'history', handle: 'pendiente' });
                break;
            case 'corregido':
                this.status = new Status({ name: 'En revisión', color: 'orange-fg', icon: 'history', handle: 'pendiente' });
                break;
            case 'corregir':
                this.status = new Status({ name: 'En correción', color: 'red-fg', icon: 'history', handle: 'corregir' });
                break;
            case 'firmado_magistrado':
                this.status = new Status({ name: 'Pendiente por firmar', color: 'purple-fg', icon: 'history', handle: 'firmado_magistrado' });
                break;
            case 'firmado_secretario':
                this.status = new Status({ name: 'Firmado/acordado', color: 'green-fg', icon: 'history', handle: 'firmado_secretario' });
                break;
            case 'notificado':
                this.status = new Status({ name: 'Notificado', color: 'blue-fg', icon: 'check_circle', handle: 'notificado' });
                break;
            case 'boletin':
                    this.status = new Status({ name: 'Para Boletín', color: 'green-fg', icon: 'check_circle', handle: 'boletin' });
                    break;
        }


        return this.status;
    }


}

export class SecretarioGeneralStatus implements StatusMethod {
    status: Status;

    constructor() {
        this.status = new Status;
    }

    setStatus(statusHandle, agreement): Status {
        
        if (statusHandle==undefined) {
            return this.status;
        }

        if (statusHandle.hasOwnProperty('name')) {
            return statusHandle;
        }

        console.log('debug signdoc', agreement.signdo);


        const firmasCompletas = agreement.signdoc.filter(item => item.firmado !== null).length === 6;
        const turnoFirmarSecretario = agreement.signdoc.filter(item => item.firmado !== null && item.employee_id !== 8).length === 5;


        switch (statusHandle) {
            case 'notificado':
                this.status = new Status({ name: 'Notificado', color: 'blue-fg', icon: 'check_circle', handle: 'notificado' });
                break;
            case 'boletin':
                    this.status = new Status({ name: 'Para Boletín', color: 'green-fg', icon: 'check_circle', handle: 'boletin' });
                    break;
            case 'pendiente':
                this.status = new Status({ name: 'En revisión', color: 'orange-fg', icon: 'history', handle: statusHandle });
                break;
            case 'corregido':
                this.status = new Status({ name: 'En revisión', color: 'orange-fg', icon: 'history', handle: statusHandle });
                break;
            case 'firma_pleno':
                this.status = new Status({ name: 'En firma del pleno', color: 'blue-fg', icon: 'history', handle: statusHandle });
                break;
            case 'corregir':
                this.status = new Status({ name: 'En correción', color: 'red-fg', icon: 'history', handle: statusHandle });
                break;
            case 'corregir_adjunto':
                this.status = new Status({ name: 'Pendiente de corregir', color: 'red-fg', icon: 'history', handle: statusHandle });
                break;
            case 'firmado_magistrado':
                this.status = new Status({ name: 'Pendiente por firmar', color: 'teal-fg', icon: 'history', handle: 'firmado_magistrado' });

              /*   if (turnoFirmarSecretario) {
                    this.status = new Status({ name: 'Pendiente por firmar', color: 'purple-fg', icon: 'history', handle: 'firmado_magistrado' });
                } else {
                    this.status = new Status({ name: 'En firma del pleno', color: 'blue-fg', icon: 'history', handle: 'firma_pleno' });
                } */

                if (firmasCompletas) {
                    this.status = new Status({ name: 'Firmado/acordado', color: 'green-fg', icon: 'history', handle: 'firmado_secretario' });
                }
                break;
            case 'firmado':
                if (turnoFirmarSecretario) {
                    this.status = new Status({ name: 'Pendiente por firmar', color: 'purple-fg', icon: 'history', handle: 'firmado_magistrado' });
                } else {
                    this.status = new Status({ name: 'En firma del pleno', color: 'blue-fg', icon: 'history', handle: 'firma_pleno' });
                }

                if (firmasCompletas) {
                    this.status = new Status({ name: 'Firmado/acordado', color: 'green-fg', icon: 'history', handle: 'firmado_secretario' });
                }
                break;
            case 'firmado_secretario':
                this.status = new Status({ name: 'Firmado/acordado', color: 'green-fg', icon: 'history', handle: statusHandle });
                break;
            case 'atendido_adjunto':
                this.status = new Status({ name: 'Pendiente de revision', color: 'purple-fg', icon: 'history', handle: statusHandle });
                break;
        }
        return this.status;
    }
    //ngIf="todo.stat == 'atendido_adjunto' &&  todo.secretario_adjunto_atendido && todo.secretario_adjunto!=='' "


}

export class SecretarioAdjunto implements StatusMethod {
    status: Status;

    constructor() {
        this.status = new Status;
    }

    setStatus(statusHandle): Status {

        if (statusHandle.hasOwnProperty('name')) {
            return statusHandle;
        }

        switch (statusHandle) {
            case 'pendiente':
                this.status = new Status({ name: 'Atendido', color: 'orange-fg', icon: 'history', handle: statusHandle });
                break;
            case 'atendido_adjunto':
                this.status = new Status({ name: 'Atendido', color: 'orange-fg', icon: 'history', handle: statusHandle });
                break;
            case 'corregir_adjunto':
                this.status = new Status({ name: 'En corrección', color: 'red-fg', icon: 'history', handle: statusHandle });
                break;
        }
        return this.status;
    }

}


export class MagistradoSalaRegionalStatus implements StatusMethod {
    status: Status;

    constructor() {
        this.status = new Status;
    }

    setStatus(statusHandle): Status {

        if (statusHandle.hasOwnProperty('name')) {
            return statusHandle;
        }

        switch (statusHandle) {
            case 'pendiente':
                this.status = new Status({ name: 'Pendiente', color: 'orange-fg', icon: 'history', handle: 'pendiente' });
                break;
            case 'corregir':
                this.status = new Status({ name: 'En correción', color: 'red-fg', icon: 'history', handle: 'corregir' });
                break;
            case 'corregido':
                this.status = new Status({ name: 'Corregido', color: 'purple-fg', icon: 'history', handle: 'corregido' });
                break;
            case 'firmado_magistrado':
                this.status = new Status({ name: 'Firmado', color: 'green-fg', icon: 'history', handle: 'firmado_magistrado' });
                break;
            case 'firmado_secretario':
                this.status = new Status({ name: 'Firmado', color: 'green-fg', icon: 'history', handle: 'firmado_magistrado' });
                break;
        }
        return this.status;
    }


}


export class MagistradoSalaSuperiorStatus implements StatusMethod {
    status: Status;

    constructor() {
        this.status = new Status;
    }

    setStatus(statusHandle, agreement, filterSignDoc, turnoFirmar): Status {

        if (statusHandle.hasOwnProperty('name')) {
            return statusHandle;
        }

        const firmoPleno = agreement.signdoc ? agreement.signdoc.filter(item => item.firmado === null && item.employee_id !== 8).length === 0 : false;

        switch (statusHandle) {
            case 'pendiente':
                this.status = new Status({ name: 'Pendiente', color: 'orange-fg', icon: 'history', handle: 'pendiente' });
                break;
            case 'corregir':
                this.status = new Status({ name: 'En correción', color: 'red-fg', icon: 'history', handle: 'corregir' });
                break;
            case 'corregido':

                if (turnoFirmar) {
                    this.status = new Status({ name: 'Corregido', color: 'yellow-fg', icon: 'history', handle: 'corregido' });
                }
                if (turnoFirmar) {
                    this.status = new Status({ name: 'En firma con otro magistrado', color: 'teal-fg', icon: 'history', handle: 'firmado_magistrado_otro' });
                }
                break;
            /*   case 'firmado_magistrado_otro':
                  this.status=new Status({name:'En firma con otro magistrado',color:'purple-fg',icon:'history',handle:statusHandle});
                  break;  
              case 'firmado_magistrado_proceso':
                  this.status=new Status({name:'En proceso de firma',color:'purple-fg',icon:'history',handle:statusHandle});
                  break;   */
            case 'firmado_magistrado':
                this.status = new Status({ name: 'Firmado', color: 'green-fg', icon: 'history', handle: 'firmado_magistrado' });

                if (filterSignDoc.firmado === null && filterSignDoc.enviado === null) {
                    this.status = new Status({ name: 'En firma con otro magistrado', color: 'teal-fg', icon: 'history', handle: statusHandle });
                }
                else if (filterSignDoc.enviado && filterSignDoc.firmado === null) {
                    this.status = new Status({ name: 'Pendiente', color: 'orange-fg', icon: 'history', handle: 'pendiente' });
                }
                else if (filterSignDoc.enviado && filterSignDoc.firmado && !firmoPleno) {
                    this.status = new Status({ name: 'En proceso de firma', color: 'blue-fg', icon: 'history', handle: 'firmado_magistrado' });
                }
                else if (firmoPleno) {
                    this.status = new Status({ name: 'Firmado', color: 'green-fg', icon: 'history', handle: 'firmado_magistrado' });
                }
                break;

            case 'firmado_secretario':
                this.status = new Status({ name: 'Firmado', color: 'green-fg', icon: 'history', handle: 'firmado_magistrado' });
                break;

        }
        return this.status;
    }


}


export class ActuarioStatus implements StatusMethod {
    status: Status;

    constructor() {
        this.status = new Status;
    }

    setStatus(statusHandle): Status {

        switch (statusHandle) {
            // case 'firmado_secretario':
            //     this.status = new Status({ name: 'Pendiente por notificar', color: 'orange-fg', icon: 'history', handle: 'firmado_secretario' });
            //     break;
            // case 'notificado':
            //     this.status = new Status({ name: 'Notificado', color: 'green-fg', icon: 'history', handle: 'notificado' });
            //     break;


            case 'tiempo':
                this.status = new Status({ name: 'En tiempo', color: 'green-fg', icon: 'access_time', handle: 'firmado_secretario' });
                break;
            case 'rezago':
                this.status = new Status({ name: 'Rezago', color: 'orange-fg', icon: 'access_time', handle: 'firmado_secretario' });
                break;
            case 'fuera':
                this.status = new Status({ name: 'Fuera de término', color: 'red-fg', icon: 'access_time', handle: 'firmado_secretario' });
                break;
        }
        return this.status;
    }


}
