import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './data/app-config.service';
import { TypeFile } from "../main/promotions/demanda-form/options.data.form";
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class DocumentProvider {
    private urlPromotions : string;
    private urlAgreements : string;
    private urlPublicFiles: string;
    private urlAcceptAgreement: string;
    private urlAcceptAgreementSecretarioGeneral: string;
    private urlAgreementCorrection: string;
    private urlTocaNumber: string;
    private urlAdjuntoReject: string;



    constructor(private http: HttpClient, private settings: AppConfigService) {
        this.urlPromotions  = this.settings.getUrlApi() + 'promotions';
        this.urlAgreements  = this.settings.getUrlApi() + 'agreements';
        this.urlPublicFiles = this.settings.getUrlApi() + 'files_sign';
        this.urlAcceptAgreement = this.settings.getUrlApi() + 'agreement-accept';
        this.urlAcceptAgreementSecretarioGeneral = this.settings.getUrlApi() + 'agreement-accept-secretario-general';
        this.urlAgreementCorrection = this.settings.getUrlApi() + 'agreement-file';
        this.urlTocaNumber = this.settings.getUrlApi() + 'agreement-toca';
        this.urlAdjuntoReject = this.settings.getUrlApi() + 'adjunto-agreement-reject';



    }
    
    registers(credentials) {
        const  haciendoalgo = (resolve, reject) => {
            if (credentials.une !== '') {
                setTimeout(() =>{
                    resolve("Stuff worked!");
                    console.log('terminado!!')
                }, 5000);
            }
            else {
                reject(Error("It broke"));
            }
        }
        return new Promise( haciendoalgo );
    }

    registerDocument(dataDocument) {
        console.log('datos del form', dataDocument);
        let optionHeaders   = this.settings.getHeadersMultipart();
        return this.http.post(this.urlPromotions , dataDocument, optionHeaders);
    }
    
    getDocumentsUploaded(promotion_id) {
        const optionHeaders   = this.settings.getHeadersJson();
        const documentsUrl = this.urlPromotions + `/${ promotion_id }/promotion-documents`;

        return this.http.get(documentsUrl , optionHeaders);
    }

    setAgreements(dataPromotion) {
        let optionHeaders   = this.settings.getHeadersMultipart();
        const url = this.settings.getUrlApi() + 'agreements';
        return this.http.post(this.urlAgreements , dataPromotion, optionHeaders);
    }

    getPromotionsByAgreement(agreement_id) {
        const optionHeaders   = this.settings.getHeadersJson();
        const documentsUrl = this.urlAgreements + `/${ agreement_id }/promotions`;

        return this.http.get(documentsUrl , optionHeaders);
    }
    getPromotionsBySentence(promotion_id) {
        const optionHeaders   = this.settings.getHeadersJson();
        const documentsUrl = this.urlAgreements + `/${ promotion_id }/promotions`;

        return this.http.get(documentsUrl , optionHeaders);
    }
    getTypeDocument(uid_doc: string) {
        const tipo = TypeFile.find(type => type.uid === uid_doc);
        if(tipo !== undefined) {
            return tipo.name;
        } else {
            return uid_doc;
        }
    }

    getDocumentBlob(link): Observable<any> {
        let headers = new HttpHeaders()
        headers = headers.set("Accept", "application/pdf")
                        .set('Authorization', 'Bearer ' + sessionStorage.getItem('tk'))
        return this.http.get(link, { headers: headers, responseType: "blob" })
    }

    getPublicFiles(folder, file){
        let optionHeaders = this.settings.getHeadersBlob();
        return this.http.get(this.urlPublicFiles+'/'+folder+'/'+file, {responseType: 'arraybuffer'});
    }

    acceptAgreement(data){
        let optionHeaders = this.settings.getHeadersMultipart();
        return this.http.post(this.urlAcceptAgreement, data, optionHeaders);
    }

    acceptAgreementSecretarioGeneral(data){
        let optionHeaders = this.settings.getHeadersMultipart();
        return this.http.post(this.urlAcceptAgreementSecretarioGeneral, data, optionHeaders);
    }

    sendAgreementCorrection(data){
        let optionHeaders = this.settings.getHeadersMultipart();
        return this.http.post(this.urlAgreementCorrection, data, optionHeaders)
    }

    getAgreementToca(secretaria_adjunta,juicio,sentido){

        let optionHeaders   = this.settings.getHeadersMultipart();
        const data=`secretario_adjunto=${secretaria_adjunta}&juicio=${juicio}&sentido=${sentido}`;
        return this.http.get(this.urlTocaNumber+'?'+data, optionHeaders);


    }

    adjuntoRejectAgreement(data){
        let optionHeaders   = this.settings.getHeadersMultipart();
        return this.http.post(this.urlAdjuntoReject,data, optionHeaders);


    }
    SignBoletin(data){
        const optionHeaders = this.settings.getHeadersMultipart(); 
        return this.http.post(this.settings.getUrlApi() + 'signboletin', data, optionHeaders);
    }
    Signacatasecre(data){
        const optionHeaders = this.settings.getHeadersMultipart(); 
        return this.http.post(this.settings.getUrlApi() + 'signactacons', data, optionHeaders);
    }
    EnviarBoletinapi(data){
        let optionHeaders = this.settings.getHeadersMultipart();
        return this.http.post(this.settings.getUrlApi() +'boletinacue',data, optionHeaders);
      }
      EnviarSecretarioapi(data){
        let optionHeaders = this.settings.getHeadersMultipart();
        return this.http.post(this.settings.getUrlApi() +'actuariosecre',data, optionHeaders);
      }
      EnviarSecretarioapis(data){
        let optionHeaders = this.settings.getHeadersMultipart();
        return this.http.post(this.settings.getUrlApi() +'actuariosecres',data, optionHeaders);
      }
    getFirmantePlenoStatus(id){
        const currentUser = this.settings.getCurrentUser();    
        const typeUrl = `employees/${ currentUser.empe }`;
        const url= this.settings.getUrlApi() + `${ typeUrl }/agreements/firmante-pleno-status/${id}`;
        let optionHeaders   = this.settings.getHeadersMultipart();
        return this.http.get(url, optionHeaders);


    }

}