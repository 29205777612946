import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MailComposeDialogComponent } from 'app/main/notifications/mail/dialogs/compose/compose.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector   : 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls  : ['./item.component.scss']
})
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy
{
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: FuseNavigationItem;
    dialogRef: any;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _router: Router,
        private _matSnackBar: MatSnackBar,
        public _matDialog: MatDialog,
        public dialog: MatDialog,
        private router: Router
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     *  add for custom
     * @param board 
     */

    navigateTo(board){
        console.log("other navigate");
        if(board.id === 'juicio') {
            //this.composeDialog(board.id);
            this.composeDialogType();
        }else if ( board.id === 'fisica' ){
            this.composeDialogType('fisico');
            //this.composeDialog(board.id);
            
        }else if ( board.id === 'promociones' ){
            //this.composeDialogType('promociones');
            this._router.navigate(['/promotion/promotion-nulidad']);
            //this.composeDialog(board.id);
        }else { 
           // this._router.navigate([board.url])
        }
    }

    composeDialogType(e?){
        if(e== 'promociones'){
            let data = e == 'promociones' ? e : null;
         
    
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent,{
            width: '300px',
            data: data
        });

        dialogRef.afterClosed().subscribe(result=>{
            if(result != undefined){
               
                this.composeDialog(result);
            }
        });
        }else{
        let data = e == 'fisico' ? e : null;
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent,{
            width: '300px',
            data: data
        });

        dialogRef.afterClosed().subscribe(result=>{
            if(result != undefined){
                this.composeDialog(result);
            }
        });}
    }

    composeDialog(typeNotification): void
    {
        if(typeNotification=="lesi"||typeNotification=="p2"){
            
            if(typeNotification=="lesi"){
                this.router.navigate(['/promotion/promotion-nulidad']);
            }
            else{
                this.router.navigate(['/promotion/promotion-responsabilidad-administrativa']);
            }
        }else{

        this.dialogRef = this._matDialog.open(MailComposeDialogComponent, {
            panelClass: 'mail-compose-dialog',
            data      : {
                typeNotification: typeNotification
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if ( !response )
                {
                    return;
                }
                const actionType: string = response[0];
                const messageApi: string = response[1];
                switch ( actionType )
                {
                    /**
                     * Send
                     */
                    case 'send':
                        // Show the success message
                        this._matSnackBar.open(messageApi, 'OK', {
                            verticalPosition: 'top',
                            horizontalPosition: 'right',
                            duration        : 3000
                        });
                        break;
                    /**
                     * Delete
                     */
                    case 'delete':
                        break;
                }
            });}
    }
}
